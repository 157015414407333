import { Link } from "react-router-dom";
import img5_49 from "../../assets/BearishSimplify/Screenshot 2024-01-24 at 5.49.04 PM@2x.png";

const FirstSectionOfSimplify = () => {
  return (
    <div className="sim_main">
      <div className="sim_cont">
        <div className="sim_m_heading w_600">
          Streamline your team’s workflow and eliminate 
          app-hopping confusion. Our software combines
        essential features in a user-friendly system, saving
        you both time and money without the clutter
        </div>
        <div className="sim_m_content">
          Discover the essence of simplicity with Bearish. Our streamlined
          platform merges essential tools into one intuitive interface, reducing
          complexity and cost. Enhance productivity and focus on growth with a
          solution where s <br /> implicity meets sophistication
        </div>
        <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
        </button>
      </div>
      <div className="sim_img cm_box">
        <img alt="img" className="sim_img_w" src={img5_49} />
      </div>
    </div>
  );
};
export default FirstSectionOfSimplify;
