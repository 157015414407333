import grp4945 from "../../assets/virtualmeetingicon/Group 4945.png";
import grp4946 from "../../assets/virtualmeetingicon/Group 4946@2x.png";
import grp4947 from "../../assets/virtualmeetingicon/Group 4947@2x.png";
import grp4948 from "../../assets/virtualmeetingicon/Group 4948@2x.png";
import grp4949 from "../../assets/virtualmeetingicon/Group 4949@2x.png";
import grp4950 from "../../assets/virtualmeetingicon/Group 4950@2x.png";
import Img4_18 from "../../assets/Bearishrelationshipmanagerimg/Screenshot 2024-01-23 at 3.41.33 PM.png";
import Img_4_17 from "../../assets/Bearishrelationshipmanagerimg/Image 3@2x.png";
import Img_4_19 from "../../assets/Bearishrelationshipmanagerimg/Image 2@2x.png";
import Img_4_21 from "../../assets/Bearishrelationshipmanagerimg/Image 4@2x.png";
import Img_4_21_53 from "../../assets/Bearishrelationshipmanagerimg/Group 4981@2x.png";
import Img_4_23 from "../../assets/Bearishrelationshipmanagerimg/Image 5@2x.png";

const RelationManagerToolsData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4_18,
    heading: "Create Assets and Content",
    content:
      "Managing relationships requires building trust by creating amazing assets and content all from within Bearish",
  },
  {
    backgroundImg: grp4949,
    frontImg: Img_4_17,
    heading: "Organize with Deals & Lists",
    content:
      "Visualize your deals and organize your contacts to manage customers, guests, team- members and more",
  },
  {
    backgroundImg: grp4950,
    frontImg: Img_4_19,
    heading: "Automate Client Nurturing",
    content:
      "Nurture every contact with automated actions and ensure that every contact gets emails that are personal and customized",
  },
  {
    backgroundImg: grp4945,
    frontImg: Img_4_21,
    heading: "Say the right thing every time",
    content:
      "Avoid typos and ensure your message is perfectly conveyed with our built in Open Source AI",
  },
  {
    backgroundImg: grp4948,
    frontImg: Img_4_21_53,
    heading: "Get the answers you need",
    content:
      "With built in forms you’ll always know what your customers, teams, and guests are thinking and what they want",
  },
  {
    backgroundImg: grp4946,
    frontImg: Img_4_23,
    heading: "Personalized Touch-Points",
    content:
      "With AI and analytics built into every contact you’ll make every customer or team member feel taken care of",
  },
];
export default RelationManagerToolsData;
