import lineblue from "../../assets/virtualmeetingicon/Line 526@2x.png";
import lineyell from "../../assets/virtualmeetingicon/Line 527@2x.png";
import linegreen from "../../assets/virtualmeetingicon/Line 528@2x.png";
import linegrey from "../../assets/virtualmeetingicon/Line 529@2x.png";
import back4955 from "../../assets/virtualmeetingicon/Group 4955@2x.png";
import back4959 from "../../assets/virtualmeetingicon/Group 4959@2x.png";
import back4960 from "../../assets/virtualmeetingicon/Group 4960@2x.png";
import back4961 from "../../assets/virtualmeetingicon/Group 4961@2x.png";

const RatingData = [
  {
    percent: "90%",
    linecolor: lineblue,
    content: "Reported an increase in call productivity",
    backgroundimg: back4955,
  },
  {
    percent: "88%",
    linecolor: lineyell,
    content: "Reported an increase in user engagement",
    backgroundimg: back4959,
  },
  {
    percent: "85%",
    linecolor: linegreen,
    content: "Reported a decrease in total meeting minutes",
    backgroundimg: back4960,
  },
  {
    percent: "80%",
    linecolor: linegrey,
    content: "Reported an increase in post call follow-ups",
    backgroundimg: back4961,
  },
];
export default RatingData;
