import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4970 from "../../assets/Bearishrelationshipmanagerimg/Group 4970@2x.png";
import grp4969 from "../../assets/Bearishrelationshipmanagerimg/Group 4969@2x.png";
import Img4 from "../../assets/BearishOSTranquilAI/7f197d37d1d3894388d1f47ae9034ffe@2x.png";
import Img4_29 from "../../assets/BearishOSTranquilAI/6494bde6f1e97090d9d82aa5ea826875@2x.png";
import Img5_51 from "../../assets/BearishOSTranquilAI/54d78adde791dfc5290c0182ceecbb2a@2x.png";

const TranquilAICardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4,
    heading: "Customized Wellness",
    content:
      "Provides advice tailored to your personal and professional life to ensure your team isn’t burning out",
  },
  {
    backgroundImg: grp4969,
    frontImg: Img4_29,
    heading: "Mental Health Support",
    content:
      "Tranquil AI Offers resources and strategies for maintaining mental well-being with articles, advice and tips",
  },
  {
    backgroundImg: grp4970,
    frontImg: Img5_51,
    heading: "24/7 Availability",
    content:
      "Tranquil AI is always on and always there when you need guidance or a moment of relaxation with videos and guides",
  },
];
export default TranquilAICardData;
