import lineorange from "../../assets/Bearishrelationshipmanagerimg/Line 530@2x.png";
import lineblu from "../../assets/Bearishrelationshipmanagerimg/Line 527@2x.png";
import linepurple from "../../assets/Bearishrelationshipmanagerimg/Line 528@2x.png";
import linegreen from "../../assets/Bearishrelationshipmanagerimg/Line 529@2x.png";
import back4955 from "../../assets/virtualmeetingicon/Group 4955@2x.png";
import back4959 from "../../assets/virtualmeetingicon/Group 4959@2x.png";
import back4960 from "../../assets/virtualmeetingicon/Group 4960@2x.png";
import back4961 from "../../assets/virtualmeetingicon/Group 4961@2x.png";
const RelationRatingCardData = [
  {
    percent: "95%",
    linecolor: lineorange,
    content: "Reported 2 hours in weekly time saved",
    backgroundimg: back4955,
  },
  {
    percent: "90%",
    linecolor: lineblu,
    content: "Reported 9% or more in increased deals closed",
    backgroundimg: back4959,
  },
  {
    percent: "87%",
    linecolor: linepurple,
    content: "Reported triple digits in SaaS savings when switching",
    backgroundimg: back4960,
  },
  {
    percent: "82%",
    linecolor: linegreen,
    content: "Reported a decrease in meet to close times",
    backgroundimg: back4961,
  },
];
export default RelationRatingCardData;
