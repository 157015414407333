import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const OperationsCardData = [
  {
    heading: "Customer Data Integration",
    content:
      "Access comprehensive customer profiles within operational workflows and understand every touchpoint",
    background: img4947,
  },
  {
    heading: "Feedback and Support",
    content:
      "Manage customer feedback and support tickets directly within the platform for easily handling customers",
    background: img4969,
  },
  {
    heading: "Sales and Service Alignment",
    content:
      "Ensure sales and operational teams are aligned on customer expectations and experiences",
    background: img4970,
  },
  {
    heading: "Lifecycle Management",
    content:
      "rack and manage the entire customer lifecycle from initial contact to post-sale support, ensuring a consistent customer journey",
    background: img5000,
  },
  {
    heading: "Operational Analytics",
    content:
      "Leverage integrated analytics to gain a deep understanding of customer behaviors and preferences",
    background: img5002,
  },
  {
    heading: "Proactive Service Alerts",
    content:
      "Utilize Bearish to set up proactive service alerts based on customer activity or inactivity, to handle all opportunities in real-time",
    background: img5001,
  },
];
export default OperationsCardData;
