import backsingicon from "../../assets/virtualmeetingicon/Group 4954@2x.png";
import meetImage from "../../assets/virtualmeetingicon/Group 4886@2x.png";
import MeetData from "./meetData";
const MeetSection = () => {
  return (
    <div className="meet_vt">
      <div className="meet_content">
        <div className="meet_heading">
          Meet anywhere, from everywhere, all with Bearish OS
        </div>
        {MeetData?.map((data, i) => (
          <div className="meet_sm_content" key={i}>
            <img alt="icon" src={backsingicon} className="meet_icon" />
            <p className="meet_p">{data?.content}</p>
          </div>
        ))}
      </div>
      <div className="meet_img_vt">
        <img className="img_meet" src={meetImage} alt="img" />
      </div>
    </div>
  );
};
export default MeetSection;
