
import connectionSmIcon from "../../assets/smallicons/Connections.png" 
import activitySmIcon from "../../assets/smallicons/Light 1px-activity.png"
import messageSmIcon from "../../assets/smallicons/Light 1px-message.png"
import personSmIcon from "../../assets/smallicons/Light 1px-person.png"
import documentSmIcon from "../../assets/smallicons/Light 1px-document.png"
import donutSmIcon from "../../assets/smallicons/Light 1px-donut_chart.png"
import updateSmIcon from "../../assets/smallicons/Light 1px-update.png"
import dbSmIcon from "../../assets/smallicons/Light 1px-database.png"
import checkedSmIcon from "../../assets/smallicons/Light 1px-check_circled.png"
import scrennshotfirst from "../../assets/screenshotb/Screenshot 2023-12-27 at 4.49.08 PM@2x.png"
import scrennshotsecond from "../../assets/screenshotb/Screenshot 2023-12-27 at 4.51.18 PM@2x.png"
import scrennshotthird from "../../assets/screenshotb/Screenshot 2023-12-27 at 4.51.51 PM@2x.png"
const PlatformData = [
  {
    heading: "Bearish Connect",
    mainHeading: "Centralize Communications",
    content:
      "Bearish Connect connects email, messaging, calls, and business social media channels, simplifying your discussions in one efficient platform. Streamline client interactions and media engagement for a more organized, secure collaboration experience.",
    backgroundIcon: "",

    icon1: connectionSmIcon,
    iconLable1: "Intelligently Connected Channels",
    icon2: activitySmIcon,
    iconLable2: "Interactions powered by AI",

    icon3: messageSmIcon,
    iconLable3: "Robust Social Media Conversations",
borderColor:"0.5px solid #2700FF",
    screenShot: scrennshotfirst,
  },
  {
    heading: "Bearish CRM & Task Managent",
    mainHeading: "Client Flow Dynamics",
    content:
      "ClientFlow Dynamics integrates advanced CRM tools with task management, document creation, and collaborative whiteboards. Streamline client relations and project execution in one powerful, efficient platform",
    backgroundIcon: "",

    icon1: personSmIcon,
    iconLable1: "Auto-Updating CRM",
    icon2: documentSmIcon,
    iconLable2: "Manage Tasks, Create Docs & More",

    icon3: donutSmIcon,
    iconLable3: "Dynamic & Robust Analytics",
    borderColor : "0.5px solid #00712B",
    screenShot:scrennshotsecond,
  },
  {
    heading: "Bearish Workforce",
    mainHeading: "Manage your Workforce",
    content:
      "Efficiently manage your team and customer interactions. Our OS combines essential HR tools, customizable forms for surveys, and robust time management features. Perfect for both internal team oversight and external customer engagement.",
    backgroundIcon: "",

    icon1:updateSmIcon,
    iconLable1: "Precise Time Tracking",
    icon2: dbSmIcon,
    iconLable2: "Powerful HR & Team Databases",

    icon3:checkedSmIcon,
    iconLable3: "Surveys, Forms, PopUps & More",
    borderColor : "0.5px solid #BC7C17",

    screenShot: scrennshotthird,
  },
];
export default PlatformData;
