import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4970 from "../../assets/Bearishrelationshipmanagerimg/Group 4970@2x.png";
import grp4969 from "../../assets/Bearishrelationshipmanagerimg/Group 4969@2x.png";
import Img4 from "../../assets/BearishOSBEBAAI/Image 4@2x.png";
import Img4_29 from "../../assets/BearishOSBEBAAI/Screenshot 2024-01-21 at 4.29.16 PM@2x.png";
import Img5_51 from "../../assets/BearishOSBEBAAI/Screenshot 2023-09-28 at 5.51.50 PM@2x.png";

const BebaAICardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4,
    heading: "Language Processing",
    content:
      "Experience the power of nuanced language understanding and generation, elevating your messages and documents to new levels",
  },
  {
    backgroundImg: grp4969,
    frontImg: Img4_29,
    heading: "Predictive Analytic",
    content:
      "Dive into deep analytics, uncover trends, and get actionable insights that keep you a step ahead in your industry",
  },
  {
    backgroundImg: grp4970,
    frontImg: Img5_51,
    heading: "Customized Solutions",
    content:
      "Optimizing workflows, it learns and evolves with your business, providing support that’s as unique as your operations",
  },
];
export default BebaAICardData;
