import grp4973 from "../../assets/BearishPrivacyandSecurity/Group 4973@2x.png";
import grp4987 from "../../assets/BearishPrivacyandSecurity/Group 4987@2x.png";
import grp4975 from "../../assets/BearishPrivacyandSecurity/Group 4975@2x.png";
const PrAndSeLastContent = [
  {
    icon: grp4973,
    content:
      "Every bit of data is shielded with state-of-the -art encryption, keeping your sensitive information secure",
    boldContent: "Cutting-Edge Encryption: ",
  },
  {
    icon: grp4987,
    content:
      "Proactively identifying and neutralizing threats to maintain a fortress-like security posture",
    boldContent: "Continuous Monitoring: ",
  },
  {
    icon: grp4975,
    content:
      "Stay ahead of the curve with regular updates ensuring our security practices exceed standards",
    boldContent: "Regular Compliance Updates: ",
  },
];

export default PrAndSeLastContent;
