import BebaAIContentIcon from "./bebaAIIconContent"
import icon4972 from "../../assets/BearishOSBEBAAI/Group 4972@2x.png";

const BebaAIThirdSection = ()=>{
    return(
        <div className="beba_3rd_section">
        <div className="beba_3rd_sect_img">
          <img alt="img" className="fo_img" src={icon4972} />
        </div>
        <div className="sm_4th_sec beba_4rd_section">
          <div className="heading_30_41_left_blk sm_4th_header w_600 beba_4rd_sect">
            Communication Refined: Elevate Your Interactions with BEBA AI’s
            Summarization, Transcription, and Smart Responses
          </div>
          <div className="fs_18_h_25_left_blk sm_4th_cont">
            BEBA AI transforms the way you communicate, bringing efficiency and
            clarity to every interaction. From generating concise summaries of
            lengthy documents to providing real-time transcription and
            intelligent automated responses, it ensures you stay on top of your
            communications effortlessly. Enhance your writing with advanced
            grammar correction, making every message clear and professional
          </div>
          <div className="sim_4th_ico_cont">
            {BebaAIContentIcon?.map((data, i) => (
              <div className="rs_last_icon_" key={i}>
                <div className="con_icon">
                  <img className="img_w" alt="icon" src={data?.icon} />
                </div>
                <div
                  className={`fs_18_h_25_left_blk beba_4rd_section_ico_content`}
                >
                  {" "}
                  <span className={`w_600`}>{data?.boldContent}</span>
                  {data?.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
}
export default BebaAIThirdSection;