import React from "react";
import "./footer.css";
import footerLogo from "../../assets/Group 3624@2x.png";
import LinkIcons from "./linkIcons";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="footer">
        <div className="footer_1st">
          <div className="footer_content font_20">
            <div className="foter_logo">
              <img className="fo_img" src={footerLogo} alt="" />
            </div>
            <div className="w_600 m_7">Why Bearish OS?</div>
            <div className="m_7  " onClick={() => navigate("/simplify")}>
              <span className="ho cursor_p">Simplify</span>
            </div>
            <div className="m_7  " onClick={() => navigate("/replace")}>
              <span className="ho cursor_p">Replace & Save</span>
            </div>
            <div className="m_7  " onClick={() => navigate("/privacy")}>
              <span className="ho cursor_p">Privacy & Security</span>
            </div>
            <div className="m_7  " onClick={() => navigate("/bebaai")}>
              <span className="ho cursor_p">BEBA AI</span>
            </div>
            <div className="m_7  " onClick={() => navigate("")}>
              <span className="ho cursor_p">ROI Calculator</span>
            </div>
          </div>
        </div>
        <div className="footer_2nd">
          <div className="foter_sec">
            <div className="fo_heading">
              <span className="fo_w_600"> Supercharge your teams!</span>
              <span className="fo_italic"> Start free today</span>
            </div>
            <div className="md_fo_content">
              <div className="new_ml">
                <div className="w_600 font_20">Work OS</div>
                <div
                  className="fo_bea font_18  "
                  onClick={() => navigate("/intelligent")}
                >
                  <span className="ho cursor_p">Intelligent Email Client</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/communicate")}
                >
                  <span className="ho cursor_p">Centralize Communications</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/relationmanager")}
                >
                  <span className="ho cursor_p">Relationship Manager</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/virtual")}
                >
                  <span className="ho cursor_p">Virtual Meeting Hub</span>
                </div>
                <div
                  className="mt_15 font_18 "
                  onClick={() => navigate("/projectplans")}
                >
                  <span className="ho cursor_p">Projects: Plan & Execute</span>
                </div>
              </div>
              <div className="new_ml">
                <div className="w_600 font_20">Solutions</div>
                <div
                  className="fo_bea font_18 "
                  onClick={() => navigate("/sales")}
                >
                  <span className="ho cursor_p">Sales</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/it")}
                >
                  <span className="ho cursor_p">IT</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/marketing")}
                >
                  <span className="ho cursor_p">Marketing</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/productdevelop")}
                >
                  <span className="ho cursor_p">Product Management</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/operations")}
                >
                  <span className="ho cursor_p">Operations</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/hr")}
                >
                  <span className="ho cursor_p">HR</span>
                </div>
                <div
                  className="mt_15 font_18  "
                  onClick={() => navigate("/customersuccess")}
                >
                  <span className="ho cursor_p">Customer Success</span>
                </div>
              </div>
              <div className="ml_25">
                {" "}
                <div className="w_600 font_20 ">Support</div>
                <div className="fo_bea font_18 ">
                  {" "}
                  <Link
                    to="https://bearishos.freshdesk.com/support/home"
                    className=" footer_nav ho cursor_p"
                    target="_blank"
                  >
                    User Support
                  </Link>{" "}
                </div>
                <div className="fo_bea font_18 ">
                  {" "}
                  <span className="ho cursor_p"> Help Center</span>
                </div>
                <div
                  className="fo_bea font_18 "
                  onClick={() => navigate("/pricing")}
                >
                  <span className="ho cursor_p">Pricing</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_3rd">
          <div className="w_600 font_20">Company</div>
          <div className="fo_bea font_18 " onClick={() => navigate("/about")}>
            <span className="ho cursor_p">About Bearish OS</span>
          </div>
          <div
            className="fo_bea font_18 "
            onClick={() => navigate("/termofservice")}
          >
            <span className="ho cursor_p">Terms and Services</span>
          </div>
          <div
            className="fo_bea font_18 "
            onClick={() => navigate("/responsibleai")}
          >
            <span className="ho cursor_p">Responsible AI</span>
          </div>
          <div
            className="fo_bea font_18 "
            onClick={() => navigate("/MicrosoftVerification")}
          >
            <span className="ho cursor_p">Microsoft Verification</span>
            {/* <span className="ho cursor_p"> Contact Sales</span> */}
          </div>
          <div
            className="fo_bea font_18 "
            onClick={() => navigate("/investors")}
          >
            <span className="ho cursor_p">Investor Relations</span>
          </div>
          <div
            className="fo_bea font_18 "
            onClick={() => navigate("/bsprivacy")}
          >
            <span className="ho cursor_p">Bearish OS Privacy Promise</span>
          </div>
          <div className="m_icons li_ca ico_left">
            {LinkIcons?.map((icon, i) => (
              <div className="m_link_icons" key={i}>
                <img src={icon?.icon} alt="icon" className="fo_img cursor_p" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="fo_decri">
        <div className="fo_content_">
          All third-party product, company names and logos are trademarks™ or
          registered® trademarks and remain the property of their respective
          holders. Use of them does not imply any affiliation with or
          endorsement by them.
        </div>
        <span>
          {" "}
          Bearish OS calculates average saving based on premium plans paid
          monthly of each replacement
        </span>
        <div className="fo_holo">Made with amazing Icons by icons8.com</div>
      </div>
    </div>
  );
};

export default Footer;
