const Pricing1Card = [
  {
    backGround_color: "#134E9D",
    border_d: "3px solid #134E9D",
    top_heading: "Bearish Cub 365",
    price_a: "$21.99",
    top_content:
      "Offer comprehensive functionality with smart usage and storage limits for lighter needs",
    new_contents: [
      "Supercharged Email Client",
      "Crystal Clear Video Calls",
      "Project Management",
      "Docs, Decks, Grids, & more",
      "Relationship Manager",
      "Unlimited Guests Free",
      "20+ Intelligently Connections",
    ],
  },
  {
    backGround_color: "#1C5F45",
    border_d: "3px solid #1C5F45",
    top_heading: "Bearish OS 365",
    price_a: "$69.99",
    new_contents: [
      "Everything in Cub 365",
      "BEBA AI, Generate & Analyze",
      "Intelligent Connections+",
      "Granular Time Tracking",
      "Unlimited CRM Groups",
      "Screen Recording",
      "Document Analytics",
      "Attachment Analytics",
      "Call Recordings",
      "Schedule Links",
      "Real Time Editing",
      "Template Snippets",
    ],
    top_content:
      "Unlock Bearish OS: Fewer limits, Every Feature, Weekly Updates, BEBA AI — peak performance",
  },
  {
    backGround_color: "#EA5C49",
    border_d: "3px solid #EA5C49",
    top_heading: "Relationships 365",
    price_a: "$14.00",
    new_contents: [
      "Relationship Manager",
      "Unlimited Contacts",
      "Unlimited Groups",
      "Unlimited Deals",
      "Unlimited Guests",
      "20+ Intelligently Connections",
      "Contact Enrichment",
      "Limited BEBA AI",
      "20 GB Cloud Storage",
      "Document Open Rates",
      "Basic Analytics",
      "Basic Email Client",
    ],
    top_content:
      "Streamline your connections a full CRM unlimited contacts, and calendars",
  },
  {
    backGround_color: "#80337D",
    border_d: "3px solid #80337D",
    top_heading: "Bearish HR 365",
    price_a: "$22.00",
    new_contents: [
      "HR 365",
      "Company Directory",
      "Team Knowledge Hub",
      "Team Time Tracking",
      "Document Management",
      "Document Analytics",
      "Document Version Control",
      "Team Engagement Surveys",
      "Organizational Surveys",
      "Time Logging",
      "Leave Management",
      "20 GB Cloud Storage",
    ],
    top_content:
      "Enhance HR: Timesheets, surveys, tracking, and directories for streamlined management",
  },
  {
    backGround_color: "#FFBA00",
    border_d: "3px solid #FFBA00",
    top_heading: "Communicate 365",
    price_a: "$19.00",
    new_contents: [
      "Communicate 365",
      "Crystal Clear Video Calls",
      "AI Call Transcriptions",
      "Integrated Call Notes",
      "Intelligent Email Client",
      "Email Read Receipts",
      "Email Analytics",
      "Team Chat",
      "Rich Text & Audio Chats",
      "Screen Recording",
      "20+ Intelligent Connections",
      "20 GB Cloud Storage",
    ],
    top_content:
      "Communicate like its 2024 with Crystal Clear Audio, Video, Chats and Social Media",
  },
];

export default Pricing1Card;
