import img4886 from "../../assets/RelationshipManager1/Group 4886@2x.png";
import icon4887 from "../../assets/Bearishrelationshipmanagerimg/Group 4887@2x.png";
const SecondSctionforRelsShipManager = () => {
  return (
    <div className="rs_sec_section">
      <div className="rs_new_s">
        <div className="rs_sec_heading w_600">
          Collaborate effortless. Together, let’s build something extraordinary
        </div>
        <div className="rs_sec_content">
          Bearish redefines teamwork with real-time collaboration at its core.
          Our platform offers advanced permissions, welcoming both team
          members and
          guests to contribute seamlessly. Enhance every project with
          diverse inputs,
           ensuring smooth progress and impactful outcomes. With Bearish,
          every
          collaborative effort is streamlined for success and precision
        </div>
        <div className="rs_icon_link">
          <div className="rs_sec_lnk">
            <a href="#" className="a_link_md w_600">
              Let’s get to work now
            </a>{" "}
          </div>
          <div className="con_icon">
            <img alt="icon" className="fo_img" src={icon4887} />
          </div>
        </div>
      </div>
      <div className="rs_sec_img">
        <img alt="img" className="fo_img" src={img4886} />
      </div>
    </div>
  );
};
export default SecondSctionforRelsShipManager;
