import { Link } from "react-router-dom";
import mthIcon from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.24.28 PM@2x.png";
const FirstSection = () => {
  return (
    <div className="vt_first_section">
      <div className="vt_rt_sec">
        <div className="vt_rt_heading">
          Revolutionize Your Conferencing with Video Calls, AI-Powered
          Transcripts, and Crystal-Clear Audio Quality
        </div>
        <div className="vt_rt_content">
          Experience Exceptional Video Calls: Dive into meetings with
          unparalleled video quality. AI-Powered Transcripts for Accuracy and
          Ease: Say goodbye to the hassle of note-taking. Our AI-driven system
          provides real-time transcripts of your meetings, capturing every word
          with accuracy. 
          Easily reference key points, decisions, and action items,
         streamlining workflows and enhancing productivity.
        </div>
        <div className="">
          <button className="re_btn">
            <Link
              to="https://app.bearishos.com/signup"
              className="re_btn footer_nav"
              target="_blank"
            >
              Start Free Now
            </Link>
          </button>
        </div>
      </div>
      <div className="vt_rt_img">
        <img className="vt_rt_image" alt="img" src={mthIcon} />
      </div>
    </div>
  );
};
export default FirstSection;
