import img8 from "../../assets/BearishSimplify/Image 8@2x.png";
import img4 from "../../assets/BearishSimplify/Image 4@2x.png";
import img12 from "../../assets/BearishSimplify/Image 12@2x.png";
import img6_10_13 from "../../assets/BearishSimplify/Screenshot 2024-01-23 at 6.10.13 PM@2x.png";
import icon4982 from "../../assets/BearishSimplify/Group 4982@2x.png";
import icon4975 from "../../assets/BearishSimplify/Group 4975@2x.png";
import icon4975_1 from "../../assets/BearishSimplify/Group 4975-1@2x.png";
const ThirdSectionOfSimplify = () => {
  return (
    <div className="thrd_sec_sim">
      <div className="thrd_simp_imgs postion_rela">
        <img
          alt="img"
          src={img8}
          className="thrd_sec_1st_img postion_abs bd_r_3_box_sh"
        />
        <img
          alt="img"
          src={img4}
          className="thrd_sec_2nd_img postion_abs bd_r_3_box_sh"
        />
        <img
          alt="img"
          src={img12}
          className="thrd_sec_3rd_img postion_abs bd_r_3_box_sh"
        />
        <img
          alt="img"
          src={img6_10_13}
          className="thrd_sec_4th_img postion_abs"
        />
      </div>
      <div className="sm_4th_sec">
        <div className="heading_30_41_left_blk sm_4th_header w_600">
          Unify work under one platform to end the app jumping and save 2+ hours
          a day
        </div>
        <div className="fs_18_h_25_left_blk sm_4th_cont">
          Streamline your workday with Bearish OS, the one-stop platform that
          eliminates the need for multiple apps. By centralizing your essential
          business tools, we help you reclaim over 2 hours daily, boosting
          productivity and focus. Bearish OS simplifies your workflow, so you
          can concentrate on what matters most – growing your business
        </div>
        <div className="sim_4th_ico_cont">
          <div className="rs_last_icon_">
            <div className="con_icon">
              <img className="img_w" alt="icon" src={icon4982} />
            </div>
            <div className={`fs_18_h_25_left_blk sm_4th_lnk`}>
              {" "}
              <span className={`w_600`}>Enhanced Productivity: </span>Reduces
              app clutter, allowing teams to work more cohesively and
              effectively.
            </div>
          </div>
          <div className="rs_last_icon_">
            <div className="con_icon">
              <img className="img_w" alt="icon" src={icon4975} />
            </div>
            <div className={`fs_18_h_25_left_blk sm_th_lnk`}>
              {" "}
              <span className={`w_600`}>Centralized Efficiency: </span> Combines
              email, calendar, project management, and more in one accessible
              location
            </div>
          </div>
          <div className="rs_last_icon_">
            <div className="con_icon">
              <img className="img_w" alt="icon" src={icon4975_1} />
            </div>
            <div className={`fs_18_h_25_left_blk sm_h_lnk`}>
              {" "}
              <span className={`w_600`}>Time-Saving Interface: </span>
              Intuitive design and AI-assisted features that streamline daily
              tasks, saving hours.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSectionOfSimplify;
