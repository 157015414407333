import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4970 from "../../assets/Bearishrelationshipmanagerimg/Group 4970@2x.png";
import grp4969 from "../../assets/Bearishrelationshipmanagerimg/Group 4969@2x.png";
import Img42916 from "../../assets/BearishSimplify/Screenshot 2024-01-21 at 4.29.16 PM@2x.png";
import Img42822 from "../../assets/BearishSimplify/Screenshot 2024-01-21 at 4.28.22 PM@2x.png";
import Img6_d1 from "../../assets/BearishSimplify/a926ce57c8d80d04f6f06d27e763b6d1@2x.png";

const SimplifyCardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img42916,
    heading: "Generate easily",
    content:
      "Whether its emails, documents or slides using our Open Source AI allows you to generate quality content with ease",
  },
  {
    backgroundImg: grp4969,
    frontImg: Img42822,
    heading: "Analyze and Accelerate",
    content:
      "Our AI doesn’t just generate but works in the background to optimize your day, improve your sales and more",
  },
  {
    backgroundImg: grp4970,
    frontImg: Img6_d1,
    heading: "Never Trained on your Data",
    content:
      "Our AI started as Open Source and will stay Open Source, you see and know exactly what we use to train our AI",
  },
];
export default SimplifyCardData;
