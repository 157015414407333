import "./replaceAndSave.css";

import RatingSection from "../../components/virtualMeetingHub/RatingSection";
import ReplaceRatingCardData from "../../components/replaceAndSave/replaceRatingCard";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import ReplaceCardData from "../../components/replaceAndSave/replaceCardData";
import img4992 from "../../assets/BearishReplacesave/Group 4992@2x.png";
import img4977 from "../../assets/BearishReplacesave/Group 4977@2x.png";
import ReplaceIconData from "../../components/replaceAndSave/replaceIconData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import FirstSectionOfReplace from "../../components/replaceAndSave/firstSection";
import SecondSectionOfReplace from "../../components/replaceAndSave/middleSection";
const ReplaceAndSave = () => {
  const [rtCards, setRtCards] = useState("rt");
  const [rtNmCards, setRtNmCards] = useState("nmrt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
     <FirstSectionOfReplace/>
      <SecondSectionOfReplace/>
      <div className="rep_3rd_sec">
        <div className="rep_3rd_headi color_wh w_600">
          Empower Your Business with Proven Savings: Join the Ranks of Clients
          Who Cut Costs and Boost Efficiency with Bearish
        </div>
        <div className="rating_card_main rt_crd_main_m">
          {ReplaceRatingCardData?.map((data, i) => (
            <RatingSection data={data} i={i} />
          ))}
        </div>
      </div>
      <div className="heading_30_41_left_blk w_600 rep_4th_heading">
        Switch from Single-Use Apps and Enjoy Substantial Savings
      </div>
      <div className="rep_crd_data">
        {ReplaceCardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtNmCards} />
        ))}
      </div>
      <div className="rep_5th_sec">
        <div className="rep_5th_img">
          <img alt="img" className="fo_img" src={img4992} />
        </div>
        <div className="first_div">
          <div className="heading_30_41_left_blk rep_5th_heading w_600">
            More Than Savings: Gain Time at Every Step with Bearish OS
          </div>
          <div className="fs_18_h_25_left_blk rep_5th_content">
            Bearish isn’t just about saving money—it’s about smart efficiency.
            Our users report significant time savings across all operations,
            from daily tasks to complex projects. With Bearish, watch
            productivity soar as time-intensive processes become faster and more
            streamlined
          </div>
          <div className="rep_5th_cont">
            <div className="rep_5th_sign">
              “[Bearish OS] has helped us cut costs and reduce overhead
              <br /> SaaS fees significantly...”
            </div>
            <div className="rep_5th_s">
              {" "}
              -Brianna H. Co-Founder, Stealth FinTech Startup
            </div>
          </div>
        </div>
      </div>
      <div className=" heading_30_41_left_blk rep_6th_sec w_600">
        Unmatched Value, Transparent Pricing: Get All Essential Features Without
        Hidden Costs with Bearish
      </div>
      <div className="rep_7th_sec">
        <div className="rep_7th_ico">
          {ReplaceIconData?.map((data, i) => (
            <div className="rep_7th_icon" key={i}>
              <div className="con_icon">
                <img className="fo_img" alt="icon" src={data?.icon} />
              </div>
              <div className="fs_18_h_25_left_blk rep_7th_icon_cont">
                <span className="w_600">{data?.boldContent}</span>
                {data?.content}
              </div>
            </div>
          ))}
        </div>
        <div className="rep_7th_img">
          <img alt="img" className="fo_img" src={img4977} />
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default ReplaceAndSave;
