import icon4973_2 from "../../assets/BearishSimplify/Group 4973@2x.png";
import icon4987_2 from "../../assets/BearishSimplify/Group 4987@2x.png";
import icon4975_2 from "../../assets/BearishSimplify/Group 4975-2@2x.png";
import icon4986 from "../../assets/BearishSimplify/Group 4986@2x.png";
import icon4990 from "../../assets/BearishSimplify/Group 4990@2x.png";
import icon4991 from "../../assets/BearishSimplify/Group 4975-3@2x.png";
import React from "react";
const SimplifyIconContent = [
  {
    icon: icon4973_2,
    content:
      "Robust encryption and security protocols to keep your information safe",
    boldContent: "Data Protection Guaranteed: ",
  },
  {
    icon: icon4987_2,
    content:
      "Bearish OS prioritizes your confidentiality, ensuring data is never misused",
    boldContent: "Privacy-First Approach: ",
  },
  {
    icon: icon4975_2,
    content:
      "Share and collaborate with peace of mind, thanks to advanced privacy settings",
    boldContent: "Secure Collaboration: ",
  },
  {
    icon: icon4986,
    content:
      "Regular security updates and monitoring to defend against emerging threats",
    boldContent: "Constant Vigilance: ",
  },
  {
    icon: icon4990,
    content:
      "Adheres to global data protection regulations, maintaining high standards of compliance",
    boldContent: "Comprehensive Compliance: ",
  },
  {
    icon: icon4991,
    content:
      "Empower users with control over their data, offering customizable permission settings",
    boldContent: "User-Controlled Access: ",
  },
];
export default SimplifyIconContent;
