import listIcon from "../../assets/listicons/DALL·E 2023-12-28 14.47.32 - Create an icon representing 'Lists'. The icon should be simple and clear, featuring a design that symbolizes lists or bul@2x.png";
import checkListIcon from "../../assets/listicons/DALL·E 2023-12-28 14.48.00 - Create an icon representing 'Checklist'. The icon should feature a design that symbolizes a checklist, such as a list wit@2x.png";
import surveysIcon from "../../assets/listicons/DALL·E 2023-12-28 14.51.18 - Create an icon representing a 'Board'. The icon should symbolize a general board, which can be used for displaying inform@2x.png";
import documentsIcon from "../../assets/listicons/DALL·E 2023-12-28 14.58.05 - Create an icon representing 'Document'. The design should symbolize a typical document or paper, commonly used to represe@2x.png";
import wikisIcon from "../../assets/listicons/Group 4904@2x.png";
import whiteboadIcon from "../../assets/listicons/DALL·E 2023-12-28 14.50.40 - Create an icon representing 'Whiteboard'. The icon should depict a design that symbolizes a whiteboard, often associated@2x.png";
import boardsIcon from "../../assets/listicons/DALL·E 2023-12-28 14.54.32 - Create an icon for 'Kanban Boards'. The design should symbolize the structure of a Kanban board, typically featuring rows@2x.png";
import screenRecordIcon from "../../assets/listicons/DALL·E 2023-12-28 15.01.58 - Create an icon representing 'Screen Record'. The design should symbolize the concept of screen recording, often used for@2x.png";

const ToolFeaturesData = [
  {
    id: 1,
    icon: listIcon,
    name: "Lists",
    description:
      "Delve into our expertly crafted lists, where team collaboration and customer satisfaction converge. Witness the power of unified teamwork in action, leading to exceptional client experiences",
  },
  {
    id: 2,
    icon: checkListIcon,
    name: "CheckLists",
    description:
      "Our checklists: Simple, effective, and streamlined. Designed to guide you effortlessly through tasks, ensuring nothing is overlooked and every detail is accounted for.",
  },
  {
    id: 3,
    icon: surveysIcon,
    name: "Surveys",
    description:
      "Discover our forms and surveys, where rich design meets versatility. With a multitude of templates and options, we provide the tools to create engaging, effective ways to gather information.",
  },
  {
    id: 4,
    icon: documentsIcon,
    name: "Documents",
    description:
      "Dive into documents, boasting exceptional features like rich commenting and real-time editing. Experience seamless collaboration and enhanced productivity with our advanced, user-friendly tools",
  },
  {
    id: 4,
    icon: wikisIcon,
    name: "Wikis",
    description:
      "Discover our Wikis, a comprehensive knowledge base tailored for everyone. Ideal for compiling company information, policies, and more, our Wikis offer an accessible platform for storing and sharing collective wisdom",
  },
  {
    id: 5,
    icon: whiteboadIcon,
    name: "WhiteBoards",
    description:
      "Experience the freedom of creativity with our digital whiteboards. Designed for brainstorming, planning, and visualizing ideas, they provide a flexible canvas for teams to collaborate and bring their visions to life",
  },
  {
    id: 6,
    icon: boardsIcon,
    name: "Boards",
    description:
      "Maximize productivity with our Kanban boards. These visually intuitive tools are perfect for organizing tasks, tracking progress, and managing workflows, helping teams stay aligned and focused on their goals.",
  },
  {
    icon: screenRecordIcon,
    name: "ScreenRecord",
    description:
      "Capture and share your ideas effortlessly with our screen recording software. Ideal for tutorials, presentations, or team updates, it offers a simple way to record, edit, and distribute your content",
  },
];
export default ToolFeaturesData;
