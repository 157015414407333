import { useEffect } from "react";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import "./resposibleAi.css";
const ResponsibleAI = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className=" re_ai_main">
        <div className="abt_heading re_ai_heading">
          Responsible AI, Our Promise to You and the World
        </div>
        <div className="re_ai_content">
          <div className="avt_w ">
            Shaping the Future with Responsible AI: Your Data, Your Control
          </div>
          <div className="mt_b_30">
            At Bearish OS, we are deeply committed to the responsible and
            ethical use of AI. Our AI is not just Open Source; it’s designed
            with a privacy-first approach that puts the power in your hands, no
            matter the size of your business.
          </div>
          <div>Privacy for All Businesses </div>
          <div className="mt_b_30">
            Small businesses, mid-sized enterprises, and large corporations – no
            matter your scale, Bearish OS ensures that your user data and input
            remain strictly your own. We never, ever train on your individual
            data. Your information is sacred, and we treat it as such.
          </div>
          <div>Collaborative Learning </div>
          <div className="mt_b_30">
            But here’s where it gets exciting. For those who choose to
            collaborate with us, it’s an opportunity for your business to be a
            part of something bigger. Through partnerships with small,
            mid-sized, and large businesses, we’re building a collaborative,
            responsible AI network that harnesses the collective wisdom and
            experiences of the business world.
          </div>
          <div>Empowering Your Business</div>
          <div className="mt_b_30">
            When businesses, regardless of size, choose to collaborate and share
            their data, they contribute to a collective learning experience.
            This enables us to develop AI that becomes increasingly intelligent
            and intuitive, benefiting businesses across the board.
          </div>
          <div>A Future of Boundless Possibilities</div>
          <div className="mt_b_30">
            With Bearish OS, you’re not just embracing a new way of working,
            you’re joining a community that believes in the power of ethical AI.
            The future is bright, and we invite businesses of all sizes to join
            us on this journey.
          </div>
          <div className="mt_b_30">
            Your data, your control, and a future filled with boundless
            possibilities. Together, we’re shaping the future of business in the
            digital age. Learn more about Bearish OS at www.bearishos.com and be
            a part of the AI revolution.
          </div>
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default ResponsibleAI;
