import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const HRCardData = [
  {
    heading: "Seamless Payroll Integration",
    content:
      "Prepare for an integrated solution that connects time tracking to payroll, streamlining payments",
    background: img4947,
  },
  {
    heading: "Enhanced Security Features",
    content:
      "Trust in a system designed with the highest security standards to protect financial data and a platform built on privacy first",
    background: img4969,
  },
  {
    heading: "Flexible Payment Options",
    content:
      "Look forward to offering employees convenient payment options, catering to diverse needs and preferences",
    background: img4970,
  },
  {
    heading: "Direct Deposit Automation",
    content:
      "Anticipate the automation of direct deposits, allowing for effortless distribution of salaries directly to employee bank accounts",
    background: img5000,
  },
  {
    heading: "Tax Compliance and Reporting",
    content:
      "Prepare for comprehensive tax compliance tools that automate the calculation, deduction, and reporting of taxes based on jurisdiction",
    background: img5002,
  },
  {
    heading: "Employee Self-Service Portal",
    content:
      "Look forward to an employee SSP where individuals can view pay stubs, track earnings, manage banking, and understand deductions",
    background: img5001,
  },
];
export default HRCardData;
