import "./productManagement.css";
import img4_11 from "../../assets/BearishOSProductManagement/Screenshot 2024-02-01 at 4.11.02 PM@2x.png";
import img4886 from "../../assets/BearishOSProductManagement/Group 4886@2x.png";
import img4975_1 from "../../assets/BearishOSProductManagement/Group 4975-1@2x.png";
import img5014 from "../../assets/BearishOSProductManagement/Group 5014@2x.png";
import img4975 from "../../assets/BearishOSProductManagement/Group 4975@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import img5020 from "../../assets/BearishOSProductManagement/Group 5020@2x.png";
import img5012 from "../../assets/BearishOSProductManagement/Group 5012@2x.png";
import img5016 from "../../assets/BearishOSProductManagement/Group 5016@2x.png";
import img5018 from "../../assets/BearishOSProductManagement/Group 5018@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import ProductMangementCardData from "../../components/productManagement/productMangementCrdData";
import RatingCardSection from "../../components/common/ratingCardSection";
import ProductManagementRatingData from "../../components/productManagement/productManagementRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const ProductManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main pr_mn_main">
        <div className="sale_1_sec">
          <div className="pr_mn_heading top_heading w_600">
            Seamless Execution, Stellar Results: Project Management Redefined
            with Bearish OS
          </div>
          <div className="top_content pr_mn_content ">
            Dive into the future of project management with Bearish OS, where
            every tool and feature is engineered for peak efficiency. Our
            platform simplifies task delegation, tracking, and collaboration,
            making it easier than ever to keep projects on schedule
           and under budget. From intuitive planning tools to real-time
            communication and comprehensive analytics, Bearish OS empowers teams
            to achieve their best work. Harness the power of AI-driven insights
            to anticipate challenges, allocate resources effectively, and drive
            projects to successful completion
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="sale_1_sec_img pr_mn_img cm_box">
          <img className="pr_img" alt="img" src={img4_11} />
        </div>{" "}
      </div>

      <div className="sale_2_sec pr_mn_1_mrgin_bt">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={img4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="pr_mn_1_heading letter_sp heading_30_41_left_blk w_600">
            Master Your Tasks: Organize and Schedule with Precision{" "}
          </div>
          <div className="pr_mn_1_content fs_18_h_25_left_blk letter_sp">
            Effortlessly organize tasks, set priorities, and define deadlines
            with Bearish OS. Our task management and scheduling tools bring
            clarity and control to your projects, enabling seamless planning and
            execution
          </div>
          <div className="sal_2_sec">
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975_1} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont letter_sp">
                <span className="w_600">Drag-and-Drop Scheduling: </span>{" "}
                Quickly rearrange tasks and adjust timelines with an intuitive
                interface
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img5014} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont letter_sp">
                <span className="w_600">Deadline Reminders: </span>Keep your
                team on track with automated reminders for upcoming deadlines
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk mark_2_cont letter_sp">
                <span className="w_600">Progress Tracking: </span>
                Monitor task completion rates to ensure projects stay on
                schedule{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec pr_mn_gap">
        <div className="pas_5th_sec_cont">
          <div className="pr_mn_3_heading heading_30_41_left_blk w_600 letter_sp">
            Collaborate Without Borders: Connect Your Team in Real-Time{" "}
          </div>
          <div className="pr_mn_3_content fs_18_h_25_left_blk letter_sp">
            Facilitate real-time collaboration and communication across your
            team with Bearish OS. Our platform’s integrated chat, file sharing,
            and video conferencing tools keep everyone aligned and engaged
          </div>
          <div className="_mark_27 fs_18_h_25_left_blk">
            <span className="w_600">Integrated Messaging & Chat: </span>
            Communicate instantly within project teams for quick decision-making
          </div>
          <div className="_mark_27 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">File Sharing & Version Control: </span>Share
            documents securely, ensuring the team always works on the latest
            version
          </div>
          <div className="_mark_27 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Video Calls & Meetings: </span>Schedule and
            conduct video meetings directly within the platform for face-to-face
            collaboration
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Understand all our Plan & Track Capabilities{" "}
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pr_mn_3_img">
          <img alt="img" className="fo_img" src={img5020} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={img5016} />
        </div>
        <div className="sal_2_sec mark_2_sec_new">
          <div className="pr_mn_4_heading  heading_30_41_left_blk w_600">
            Track Time, Enhance Productivity: Precision Time Management Tools{" "}
          </div>
          <div className="pr_mn_4_content fs_18_h_25_left_blk ">
            Maximize productivity and ensure project efficiency with Bearish
            OS’s advanced time tracking solution. Our system provides detailed
            insights into how time is spent on each task and project, enabling
            managers and teams to optimize workflows, meet deadlines,
            <br /> and improve time allocation. From individual tasks to
            comprehensive project timelines, gain the clarity and control you
            need to drive organizational productivity
          </div>
          <div className="pr_mn_4_cont sal_2_sec ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Individual and Team Time Tracking: </span>
              Monitor time spent on tasks by individuals or teams for accurate
              project billing and performance assessment
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Efficiency Analytics: </span>Analyze time
              data to identify areas for workflow improvement and better
              resource distribution
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600"> Automated Time Entry: </span>Simplify
              time recording with automated tracking features, reducing manual
              entry errors and saving time
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Integrated Reporting: </span>Access
              comprehensive time reports to support project analysis, client
              billing, and strategic planning
            </div>
          </div>
        </div>
      </div>

      <div className="pr_mn_5_sec heading_30_41_left_blk w_600 letter_sp">
        Your Project’s Second Brain: Centralize Knowledge for Smart Decision
        Making
      </div>

      <div className="sale_5_sec pr_mn_marg_bt">
        {ProductMangementCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={img5018} className="fo_img" />
        </div>
        <div className="sale_6_content pr_new_w">
          <div className="pr_mn_7_heading heading_30_41_left_blk w_600 letter_sp">
            Optimize Your Resources: Allocate Effectively, Manage Budgets Wisely{" "}
          </div>
          <div className="pr_mn_7_content fs_18_h_25_left_blk letter_sp">
            With Bearish OS, gain insights into resource allocation and budget
            management to maximize efficiency and minimize overspend. Our tools
            help you balance team workloads and track project finances in
            real-time
          </div>
          <div className="mark_6_cont">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Resource Visualization: </span>See your
              team’s capacity and allocate resources to tasks smartly
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Budget Tracking Tools: </span>
              Monitor project expenses against budgets to avoid financial
              overruns
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Cost Forecasting: </span>Predict future
              costs with data-driven insights to make informed financial
              decisions
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec pr_mn_rating_bg">
        <div className="mrk_7_heding sale_7_heading w_600 letter_sp">
          Proven Impact: Quantifying Success with Bearish OS Marketing Insights
          delivered to every user every time
        </div>

        <div className="rating_card_main sale_rating_crd">
          {ProductManagementRatingData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={img5012} />
        </div>
        <div className="pas_5th_sec_cont pr_mn_7_content_new">
          <div className="pr_mn_8_heading heading_30_41_left_blk w_600 letter_sp">
            Leverage AI for Unmatched Project Insights and overall team
            management
          </div>
          <div className="pr_mn_8_content fs_18_h_25_left_blk mrk_8_content_he">
            Empower your project management with AI-driven insights from Bearish
            OS. From predicting project outcomes to offering optimization
            suggestions, our AI tools provide the intelligence you need to lead
            projects to success
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
              Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};
export default ProductManagement;
