import { useEffect } from "react";
import img941 from "../../assets/BearishOSIT/60379004e8674ea64c52995623c9f941@2x.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import "./tands.css";
const TermAndService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main bs_pr_main">
        <div className="sale_1_sec t_s">
          <div className="tandS_main top_heading w_600">Terms and Services</div>
          <div className="new_tand_c">
            <div>
              {" "}
              📝 Agree to Terms: By using Bearish’s platform, including websites
              and apps, you accept these terms. Make sure you’re okay with them
              because they’re a package deal with using our services.
            </div>
            <div>
              🔄 Updates & Changes: We might tweak or update these terms now and
              then. We’ll let you know, but keep an eye out and make sure you’re
              still cool with the changes.
            </div>
            <div>
              🤝 Dispute Resolution: If we ever have a disagreement, we’ll try
              to sort it out informally first. If that doesn’t work, we’re
              agreeing to settle it through arbitration, not in court, which
              also means waving goodbye to any class-action lawsuits.
            </div>
            <div>
              💼 License to Use: We’re giving you a personal, non-transferable
              license to use our platform for your own or your business’s
              internal use, but don’t try to resell or redistribute our services
              without our permission.
            </div>
          </div>
        </div>
        <div className="cs_1_img">
          <img className="cs_1_img_" alt="img" src={img941} />
        </div>{" "}
      </div>

      <div className="t_s_1_heading heading_30_41_left_blk">
        Terms and Services
        <br /> Last Updated February 7th 2024
      </div>

      <div className="t_s_2_div">
        <div className="mt_b_22">Terms of Use Agreement</div>
        <div className="mt_b_22">
          (OPEN BETA RELEASE) <br />
          Last Updated Date: February 7th 2024
        </div>
        <div className="mt_b_22">
          Welcome and thank you for your interest in Bearish FH, Inc.
          (“Bearish”, “we”, “us” or “our”). This Terms of Use Agreement (“Terms
          of Use”, and together with any applicable Supplemental Terms (as
          defined in Section 1.2 (Supplemental Terms)), the “Agreement”)
          describes the terms and conditions that apply to your use of (i) the
          website located at https://www.bearishos.com/ and its subdomains
          (collectively, the “Website”), (ii) any mobile application(s) that we
          offer subject to these Terms of Use (each, an “Application”), and
          (iii) the services, content, and other resources available on or
          enabled via our Website or any Application, including the platform to
          connect multiple applications into a single location with built-in
          features to streamline work and improve data protection and security
          (collectively, with our Applications and Website, the “Bearish
          Platform”).
        </div>

        <div className="mt_b_22">
          PLEASE READ THIS AGREEMENT CAREFULLY. THIS AGREEMENT GOVERNS THE USE
          OF THE BEARISH PLATFORM AND APPLIES TO ALL USERS VISITING OR ACCESSING
          THE BEARISH PLATFORM. BY ACCESSING OR USING THE BEARISH PLATFORM IN
          ANY WAY, ACCEPTING THIS AGREEMENT BY CLICKING ON THE “I ACCEPT”
          BUTTON, COMPLETING THE ACCOUNT REGISTRATION PROCESS, BROWSING THE
          WEBSITE OR DOWNLOADING THE APPLICATION, YOU REPRESENT THAT: (1) YOU
          HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THIS AGREEMENT, (2)
          YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH BEARISH, (3) YOU
          ARE NOT BARRED FROM USING THE BEARISH PLATFORM UNDER THE LAWS OF THE
          UNITED STATES, YOUR PLACE OF RESIDENCE OR ANY OTHER APPLICABLE
          JURISDICTION; AND (4) YOU HAVE THE AUTHORITY TO ENTER INTO THIS
          AGREEMENT PERSONALLY OR, IF YOU ARE ACCESSING OR USING THE BEARISH
          PLATFORM ON BEHALF OF AN ENTITY, ON BEHALF OF THE ENTITY IDENTIFIED IN
          THE ACCOUNT REGISTRATION PROCESS. IF THE INDIVIDUAL ENTERING INTO THIS
          AGREEMENT IS DOING SO ON BEHALF OF AN ENTITY, ALL REFERENCES TO “YOU”
          OR “YOUR” IN THIS AGREEMENT WILL ALSO BE DEEMED TO REFER TO SUCH
          ENTITY. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY
          NOT ACCESS OR USE THE BEARISH PLATFORM.
        </div>

        <div className="mt_b_22">
          IF YOU SUBSCRIBE TO ANY FEATURE OR FUNCTIONALITY OF THE BEARISH
          PLATFORM FOR A TERM (THE “INITIAL TERM”), THEN YOUR SUBSCRIPTION WILL
          BE AUTOMATICALLY RENEWED FOR ADDITIONAL PERIODS OF THE SAME DURATION
          AS THE INITIAL TERM AT BEARISH’S THEN-CURRENT FEE FOR SUCH FEATURES
          AND FUNCTIONALITY UNLESS YOU OPT OUT OF THE AUTOMATIC RENEWAL OF
          BEARISH PLATFORM TERM IN ACCORDANCE WITH SECTION 9.3(a) (AUTOMATIC
          RENEWAL)BELOW.
          <br />
          SECTION 16 (ARBITRATION AGREEMENT) CONTAINS PROVISIONS THAT GOVERN HOW
          TO RESOLVE DISPUTES BETWEEN YOU AND BEARISH. AMONG OTHER THINGS,
          SECTION 16 (ARBITRATION AGREEMENT) INCLUDES AN AGREEMENT TO ARBITRATE
          WHICH REQUIRES, WITH LIMITED EXCEPTIONS, THAT ALL DISPUTES BETWEEN YOU
          AND US SHALL BE RESOLVED BY BINDING AND FINAL ARBITRATION. SECTION 16
          ALSO CONTAINS A CLASS ACTION AND JURY TRIAL WAIVER. PLEASE READ
          SECTION 16 (ARBITRATION AGREEMENT) CAREFULLY. ANY DISPUTE, CLAIM OR
          REQUEST FOR RELIEF RELATING IN ANY WAY TO YOUR USE OF THE BEARISH
          PLATFORM WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE
          STATE OF CALIFORNIA, CONSISTENT WITH THE FEDERAL ARBITRATION ACT,
          WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE
          APPLICATION OF THE LAW OF ANY OTHER JURISDICTION. THE UNITED NATIONS
          CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS IS
          EXPRESSLY EXCLUDED FROM THIS AGREEMENT.
        </div>

        <div className="mt_b_22">
          {" "}
          PLEASE NOTE THAT IF YOU OPT-IN TO OBTAIN TEXT MESSAGES FROM BEARISH,
          SECTION 1.4 (TEXT MESSAGE SERVICES) OF THIS AGREEMENT BELOW CONTAINS
          TERMS RELATED TO OUR TEXT MESSAGE SERVICES. THE AGREEMENT IS SUBJECT
          TO CHANGE BY BEARISH IN ITS SOLE DISCRETION AT ANY TIME AS SET FORTH
          IN SECTION 17.6 (AGREEMENT UPDATES).
        </div>
        <div className="mt_b_22">
          USE OF THE SERVICE. The Bearish Platform and the information and
          content available on the Bearish Platform are protected by applicable
          intellectual property (including copyright) laws. Unless subject to a
          separate license agreement between you and Bearish, your right to
          access and use the Bearish Platform, in whole or in part, is subject
          to this Agreement.
        </div>
        <div className="mt_b_22">
          Bearish Platform License. Subject to your compliance with this
          Agreement, Bearish grants you a limited non-exclusive,
          non-transferable, non-sublicensable, revocable license to download,
          install and use a copy of the Bearish Platform on one or more Device
          (as defined below) that you own or control and to run such copy of the
          Bearish Platform solely for your own personal or internal business
          purposes. Subject to the terms and conditions of this Agreement,
          Bearish grants you a limited, royalty-free, nonexclusive,
          nontransferable, non-assignable, internal use only license during the
          Term, (without the right to sublicense) to use and reproduce any
          application programming interfaces, executable software, applications,
          instructions, documentation, and other materials (collectively, as
          part of the “Bearish Platform”) provided by Bearish, including as may
          be integrated with any of your applications.
        </div>
        <div className="mt_b_22">
          Supplemental Terms. Your use of, and participation in, certain
          features and functionality of the Bearish Platform may be subject to
          additional terms (“Supplemental Terms”). Such Supplemental Terms will
          either be set forth in the applicable supplemental Bearish Platform or
          will be presented to you for your acceptance when you sign up to use
          the supplemental Bearish Platform. If these Terms of Use are
          inconsistent with the Supplemental Terms, then the Supplemental Terms
          control with respect to such supplemental Bearish Platform.
        </div>
        <div className="mt_b_22">
          Updates. You understand that the Bearish Platform is evolving. As a
          result, Bearish may require you to install updates to the Applications
          that you have installed on the devices through which you access or use
          the Bearish Platform (“Device”). You acknowledge and agree that
          Bearish may update the Bearish Platform with or without notifying you.
          You may need to update third-party software from time to time in order
          to continue to use the Bearish Platform. Any future release, update or
          other addition to the Bearish Platform shall be subject to this
          Agreement.
        </div>
        <div className="mt_b_22">
          Text Message Services. Bearish may offer one or more mobile message
          programs (collectively, the “Message Service”) that allows users to
          receive SMS/MMS mobile messages by opting-in such as through online or
          application-based enrollment forms. Regardless of the opt-in method
          you use to enroll, you agree that your use of the Message Service is
          governed by this Agreement. We do not charge for the Message Service,
          but you are responsible for all charges and fees associated with
          mobile messaging imposed by your wireless carrier and you acknowledge
          that your carrier may charge you or deduct usage credit from your
          account when you text us or we send messages to you. Message and data
          rates may apply. By enrolling a telephone number in the Message
          Service, you authorize us to send recurring SMS and MMS mobile
          messages to the number you specify, and you represent that you are
          authorized to receive mobile messages at such number. The messages
          sent through the Message Service may include Account verification
          codes. You agree that these messages may be transmitted using an
          automatic telephone dialing system (“ATDS”), other automated systems
          for the selection or dialing of telephone numbers, or different
          technology. Your consent to receive mobile messages via an ATDS or
          other automated system for the selection or dialing of numbers is not
          required (directly or indirectly) as a condition of purchasing any
          property, goods or services. While you consent to receive messages
          sent using an ATDS, the foregoing shall not be interpreted to suggest
          or imply that any or all of our messages are sent using such ah
          system. Message frequency varies. If you do not wish to continue
          participating in a Message Service program we offer, you agree to
          reply STOP, END, CANCEL, UNSUBSCRIBE, or QUIT to any mobile message
          you receive from that program to opt out. You may receive an
          additional mobile message confirming your decision to opt out. You
          understand and agree that the foregoing options are the only
          reasonable methods of opting out. You acknowledge that our text
          message platform may not recognize and respond to unsubscribe requests
          that alter, change, or modify the STOP, END, CANCEL, UNSUBSCRIBE or
          QUIT keyword commands, such as the use of different spellings or the
          addition of other words or phrases to the command, and agree that that
          we and our service providers will have no liability for failing to
          honor such requests. You also understand and agree that any other
          method of opting out, including, but not limited to, texting words
          other than those keyword commands set forth above or verbally
          requesting one of our employees to remove you from our list, is not a
          reasonable means of opting out. To the extent you subscribe to more
          than one Message Service program that we operate, you must unsubscribe
          from each program separately. For Message Service support or
          assistance, text the HELP keyword in response to any message you
          receive through the Message Service or email us at
          support@bearishfh.com. Please note that the use of this email address
          is not an acceptable method of opting out of Message Service. Opt outs
          must be submitted in accordance with the procedures set forth above.
          We may change any short code or telephone number we use to operate the
          Message Service at any time with notice to you. You acknowledge that
          any messages, including any STOP or HELP requests, you send to a short
          code or telephone number we have changed may not be received and we
          are not responsible for honoring requests made in such messages. The
          Message Service may not be available in all areas or supported by all
          carriers or all devices. Check with your carrier for details. Delivery
          of mobile messages is subject to effective transmission from your
          wireless carrier/network operator and is outside of our control. We
          and the wireless carriers supported by the Message Service are not
          liable for any failed, delayed or undelivered messages. If you decide
          to change your mobile phone number, you agree to first opt out of each
          Message Service program in which your number is enrolled. For clarity,
          you acknowledge and agree that any disputes between you and us related
          to the Message Service will be governed by Section 16 (Arbitration
          Agreement).
        </div>
        <div>REGISTRATION.</div>
        <div className="mt_b_22">
          Registering Your Account. In order to access certain features of the
          Bearish Platform, you may be required to register an account on the
          Bearish Platform (“Account”) through which you can connect to the
          Bearish Platform, as permitted by the Bearish Platform (each such
          account, a “Third-Party Account”), or have an account with the app
          store from which you downloaded the Application. Registration Data. In
          registering an account on the Bearish Platform, you shall (i) provide
          true, accurate, current, and complete information about yourself as
          prompted by the registration form (the “Registration Data”), and (ii)
          maintain and promptly update the Registration Data to keep it true,
          accurate, current, and complete. If you provide any information that
          is untrue, inaccurate, incomplete or not current, or Bearish has
          reasonable grounds to suspect that any information you provide is
          untrue, inaccurate, incomplete or not current, Bearish has the right
          to suspend or terminate your Account and refuse any and all current or
          future use of the Bearish Platform (or any portion thereof). Your
          Account. Notwithstanding anything to the contrary herein, you
          acknowledge and agree that you have no ownership or other property
          interest in your Account, and you further acknowledge and agree that
          all rights in and to your Account are and will forever be owned by and
          inure to the benefit of Bearish. Furthermore, you are responsible for
          all activities that occur under your Account. You shall monitor your
          Account to restrict use by minors, and you will accept full
          responsibility for any unauthorized use of the Bearish Platform by
          minors. You may not share your Account or password with anyone, and
          you agree to notify Bearish immediately of any unauthorized use of
          your password or any other breach of security. You agree not to create
          an Account using a false identity or information, or on behalf of
          someone other than yourself. You shall not have more than one Account
          at any given time. You agree not to create an Account or use the
          Bearish Platform if you have been previously removed by Bearish, or if
          you have been previously banned from any of the Bearish Platform.
        </div>

        <div>RESPONSIBILITY FOR CONTENT.</div>
        <div className="mt_b_22">
          Types of Content. You acknowledge that any information, data, text,
          software, music, sound, photographs, graphics, video, messages, tags
          and/or other materials accessible through the Bearish Platform
          (collectively, “Content”), is the sole responsibility of the party
          from whom such Content originated. This means that you, and not
          Bearish, are entirely responsible for all Content that you upload,
          post, email, transmit or otherwise make available (“Make Available”)
          through the Bearish Platform (“Your Content”), and that other users of
          the Bearish Platform, and not Bearish, are similarly responsible for
          all Content that they Make Available through the Bearish Platform
          (“User Content”). Storage. Unless expressly agreed to by Bearish in
          writing elsewhere, Bearish has no obligation to store any of Your
          Content. Bearish has no responsibility or liability for the deletion
          or accuracy of any Content, including Your Content; the failure to
          store, transmit, or receive transmission of Content; or the security,
          privacy, storage, or transmission of other communications originating
          with or involving use of the Bearish Platform. Certain features and
          functionality of the Bearish Platform may enable you to specify the
          level at which the Bearish Platform restricts access to Your Content.
          In such cases, you are solely responsible for applying the appropriate
          level of access to Your Content. If you do not choose a level of
          access, the system may default to its most permissive setting.
        </div>

        <div>OWNERSHIP. </div>
        <div className="mt_b_22">
          The Bearish Platform. Except with respect to Your Content, you agree
          that Bearish and its suppliers or licensors own all rights, title and
          interest in the Bearish Platform (including but not limited to, any
          computer code, themes, objects, characters, character names, stories,
          dialogue, concepts, artwork, animations, sounds, musical compositions,
          audiovisual effects, methods of operation, moral rights,
          documentation, and Bearish software). You shall not remove, alter or
          obscure any copyright, trademark, service mark or other proprietary
          rights notices incorporated in or accompanying any the Bearish
          Platform.
        </div>

        <div className="mt_b_22">
          Trademarks. “Bearish FH, Inc.” and all related stylizations, graphics,
          logos, service marks and trade names used on or with the Bearish
          Platform are the trademarks of Bearish and may not be used without
          permission in connection with your, or any third-party’s, products or
          services. Other trademarks, service marks and trade names that may
          appear on or in the Bearish Platform are the property of their
          respective owners. Your Content. Bearish does not claim ownership of
          Your Content. However, when you Make Available any Content on or to
          the Bearish Platform, you represent that you own and/or have
          sufficient rights to Your Content to grant the license set forth in
          Section 4.4 (License to Your Content). License to Your Content.
          Subject to any applicable Account settings that you select, you grant
          Bearish a non-exclusive, transferable, perpetual, irrevocable,
          worldwide, fully-paid, royalty-free, sublicensable (through multiple
          tiers of sublicensees) right (including any moral rights) and license
          to use, copy, reproduce, modify, adapt, prepare derivative works from,
          translate, distribute, publicly perform, publicly display and derive
          revenue or other remuneration from Your Content (in whole or in part)
          for the purposes of operating and providing the Bearish Platform to
          you and to our other users. Please remember that other users may be
          able to search for, see, use, modify and/or reproduce any of Your
          Content that you submit to any area of the Bearish Platform that is
          accessible by other users. Feedback. You agree that submission of any
          ideas, suggestions, documents, and/or proposals to Bearish through its
          suggestion, feedback, forum, or similar pages (“Feedback”) is at your
          own risk and that Bearish has no obligations (including without
          limitation obligations of confidentiality) with respect to such
          Feedback. You represent and warrant that you have all rights necessary
          to submit the Feedback. You hereby grant to Bearish a fully paid,
          royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and
          fully sublicensable right and license to use, reproduce, perform,
          display, distribute, adapt, modify, re-format, create derivative works
          of, and otherwise commercially or non-commercially exploit in any
          manner, any and all Feedback, and to sublicense the foregoing rights,
          in connection with the operation and maintenance of the Bearish
          Platform and/or Bearish’s business.
        </div>

        <div className="mt_b_22">
          USER CONDUCT AND CERTAIN RESTRICTIONS. As a condition of use, you
          agree not to use the Bearish Platform for any purpose that is
          prohibited by this Agreement or by applicable law. You shall not (and
          shall not permit any third party) to: (i) license, sell, rent, lease,
          transfer, assign, reproduce, distribute, host or otherwise
          commercially exploit the Bearish Platform or any portion of the
          Bearish Platform; (ii) frame or utilize framing techniques to enclose
          any trademark or logo located on the Bearish Platform or any other
          portion of the Bearish Platform (including images, text, page layout
          or form); (iii) use any metatags or other “hidden text” using
          Bearish’s name or trademarks; (iv) modify, translate, adapt, merge,
          make derivative works of, disassemble, decompile, reverse compile or
          reverse engineer any part of the Bearish Platform except to the extent
          the foregoing restrictions are expressly prohibited by applicable law;
          (v) use any manual or automated software, devices or other processes
          (including but not limited to spiders, robots, scrapers, crawlers,
          avatars, data mining tools, or the like) to “scrape” or download data
          from any web pages contained in the Bearish Platform (except that we
          grant the operators of public search engines revocable permission to
          use spiders to copy materials from the Bearish Platform for the sole
          purpose of and solely to the extent necessary for creating publicly
          available searchable indices of the materials, but not caches or
          archives of such materials); (vi) remove or destroy any copyright
          notices or other proprietary markings contained on or in the Bearish
          Platform; (vii) impersonate any person or entity, including any
          employee or representative of Bearish; (viii) interfere with or
          attempts to interfere with the proper functioning of the Bearish
          Platform or use the Bearish Platform in any way not expressly
          permitted by this Agreement, including but not limited to violating or
          attempting to violate any security features of the Bearish Platform,
          introducing viruses, worms, or similar harmful code into the Bearish
          Platform, or interfering or attempting to interfere with use of the
          Bearish Platform by any other user, host, or network, including by
          means of overloading, “flooding,” “spamming,” “mail bombing,” or
          “crashing” the Bearish Platform; or (ix) take any action or Make
          Available any Content on or through the Bearish Platform that: (A) is
          unlawful, threatening, abusive, harassing, defamatory, libelous,
          deceptive, fraudulent, invasive of another’s privacy, tortious,
          obscene, offensive, or profane; (B) constitutes unauthorized or
          unsolicited advertising, junk or bulk email; or (C) involves
          commercial activities and/or sales, such as contests, sweepstakes,
          barter, advertising, or pyramid schemes without Bearish’s prior
          written consent. You may not post or Make Available a photograph of
          another person without that person’s permission. The rights granted to
          you in this Agreement are subject to your compliance with the
          restrictions set forth in this section. Any unauthorized use of the
          Bearish Platform terminates the licenses granted by Bearish pursuant
          to this Agreement. INVESTIGATIONS, MONITORING, & NO OBLIGATION TO
          PRE-SCREEN CONTENT. Bearish may, but is not obligated to, investigate,
          monitor, pre-screen, remove, refuse, or review the Bearish Platform
          and/or Content, including Your Content and User Content, at any time.
          You hereby provide your irrevocable consent to such monitoring. You
          acknowledge and agree that you have no expectation of privacy
          concerning the transmission of Your Content, including without
          limitation chat, text, or voice communications.
        </div>

        <div className="mt_b_22">
          Without limiting the foregoing, Bearish reserves the right to: (a)
          remove or refuse to post any of Your Content for any or no reason in
          our sole discretion; (b) take any action with respect to any of Your
          Content that we deem necessary or appropriate in our sole discretion,
          including if we believe that such Content violates this Agreement,
          infringes any intellectual property right or other right of any person
          or entity, threatens the personal safety of users of the Bearish
          Platform or the public, or could create liability for Bearish; (c)
          disclose your identity or other information about you to any third
          party who claims that material posted by you violates their rights,
          including their intellectual property rights or their right to
          privacy; (d) take appropriate legal action, including without
          limitation, referral to and cooperation with law enforcement and/or
          other applicable legal authorities, for any illegal or unauthorized
          use of the Bearish Platform or if Bearish otherwise believes that
          criminal activity has occurred; and/or (e) terminate or suspend your
          access to all or part of the Bearish Platform for any or no reason,
          including without limitation, any violation of this Agreement. Upon
          determination of any possible violations by you of any provision of
          this Agreement, Bearish, may, at its sole discretion immediately
          terminate your license to use the Bearish Platform, or change, alter
          or remove Your Content, in whole or in part, without prior notice to
          you.
        </div>

        <div className="mt_b_22">
          If Bearish believes that criminal activity has occurred, Bearish
          reserves the right to, except to the extent prohibited by applicable
          law, disclose any information or materials on or in the Bearish
          Platform, including Your Content, in Bearish’s possession in
          connection with your use of the Bearish Platform, to (i) comply with
          applicable laws, legal process or governmental request, (ii) enforce
          this Agreement, (iii) respond to any claims that Your Content violates
          the rights of third parties, (iv) respond to your requests for
          customer service, or (v) protect the rights, property, or personal
          safety of Bearish, its users or the public, and all enforcement or
          other government officials, as Bearish in its sole discretion believes
          to be necessary or appropriate.
        </div>

        <div>INTERACTIONS WITH OTHER USERS.</div>
        <div className="mt_b_22">
          User Responsibility. You are solely responsible for your interactions
          with other users of the Bearish Platform and any other parties with
          whom you interact through the Bearish Platform; provided, however,
          that Bearish reserves the right, but has no obligation, to intercede
          in any disputes between you and any other users. You agree that
          Bearish will not be responsible for any liability incurred as the
          result of your interactions with other users. Content Provided by
          Other Users. The Bearish Platform may contain User Content provided by
          other users. Bearish is not responsible for and does not control User
          Content. Bearish does not approve or endorse, or make any
          representations or warranties with respect to, User Content. You use
          all User Content and interact with other users at your own risk.
        </div>

        <div>THIRD-PARTY SERVICE.</div>
        <div className="mt_b_22">
          Third-Party Websites and Applications. The Bearish Platform may
          contain links to third-party websites (“Third-Party Websites”) and
          applications (“Third-Party Applications”) (collectively, the
          “Third-Party Services”). When you click on a link to a Third-Party
          Service, we will not warn you that you have left the Bearish Platform
          and you become subject to the terms and conditions (including privacy
          policies) of another website or destination. Such Third-Party Services
          are not under the control of Bearish. Bearish is not responsible for
          any Third-Party Services. Bearish provides these Third-Party Services
          only as a convenience and does not review, approve, monitor, endorse,
          warrant, or make any representations with respect to Third-Party
          Services, or any product or service provided in connection therewith.
          You use all links in Third-Party Services at your own risk. When you
          leave our Bearish Platform, this Agreement and our policies no longer
          govern. You should review applicable terms and policies, including
          privacy and data gathering practices, of any Third-Party Services, and
          make whatever investigation you feel necessary or appropriate before
          proceeding with any transaction with any third party. Sharing Your
          Content and Information Through Third-Party Services. Bearish may
          provide tools through the Bearish Platform that enable you to export
          information, including Your Content, to Third-Party Services ,
          including through features that allow you to link your Account with an
          SNS account. By using one of these tools, you agree that Bearish may
          transfer that information to the applicable Third-Party Service.
          Bearish is not responsible for any Third-Party Service’s use of your
          exported information. Third-Party Application Access. With respect to
          any Application accessed through or downloaded from the Apple App
          Store (an “App Store Sourced Application”), you shall only use the App
          Store Sourced Application (i) on an Apple-branded product that runs
          the iOS (Apple’s proprietary operating system) and (ii) as permitted
          by the “Usage Rules” set forth in the Apple Media Terms of Service,
          except that such App Store Sourced Application may be accessed,
          acquired, and used by other accounts associated with the purchaser via
          Apple’s Family Sharing function, volume purchasing, or Legacy Contacts
          function. Notwithstanding the first sentence in this section, with
          respect to any Application accessed through or downloaded from the
          Google Play store (a “Google Play Sourced Application”), you may have
          additional license rights with respect to use of the Application on a
          shared basis within your designated family group. Accessing and
          Downloading the Application from the Apple App Store. The following
          applies to any App Store Sourced Application accessed through or
          downloaded from the Apple App Store: You acknowledge and agree that
          (i) this Agreement is concluded between you and Bearish only, and not
          Apple, and (ii) Bearish, not Apple, is solely responsible for the App
          Store Sourced Application and content thereof. Your use of the App
          Store Sourced Application must comply with the App Store Terms of
          Service. You acknowledge that Apple has no obligation whatsoever to
          furnish any maintenance and support services with respect to the App
          Store Sourced Application. In the event of any failure of the App
          Store Sourced Application to conform to any applicable warranty, you
          may notify Apple, and Apple will refund the purchase price for the App
          Store Sourced Application to you and to the maximum extent permitted
          by applicable law, Apple will have no other warranty obligation
          whatsoever with respect to the App Store Sourced Application. As
          between Bearish and Apple, any other claims, losses, liabilities,
          damages, costs or expenses attributable to any failure to conform to
          any warranty will be the sole responsibility of Bearish. You and
          Bearish acknowledge that, as between Bearish and Apple, Apple is not
          responsible for addressing any claims you have or of any third party
          relating to the App Store Sourced Application or your possession and
          use of the App Store Sourced Application, including, but not limited
          to: (i) product liability claims; (ii) any claim that the App Store
          Sourced Application fails to conform to any applicable legal or
          regulatory requirement; and (iii) claims arising under consumer
          protection or similar legislation. You and Bearish acknowledge that,
          in the event of any third-party claim that the App Store Sourced
          Application or your possession and use of that App Store Sourced
          Application infringes that third party’s intellectual property rights,
          as between Bearish and Apple, Bearish, not Apple, will be solely
          responsible for the investigation, defense, settlement and discharge
          of any such intellectual property infringement claim to the extent
          required by this Agreement. You and Bearish acknowledge and agree that
          Apple, and Apple’s subsidiaries, are third-party beneficiaries of this
          Agreement as related to your license of the App Store Sourced
          Application, and that, upon your acceptance of the terms and
          conditions of this Agreement, Apple will have the right (and will be
          deemed to have accepted the right) to enforce this Agreement as
          related to your license of the App Store Sourced Application against
          you as a third-party beneficiary thereof. Without limiting any other
          terms of this Agreement, you must comply with all applicable
          third-party terms of agreement when using the App Store Sourced
          Application.
        </div>

        <div>FEES AND PURCHASE TERMS.</div>
        <div className="mt_b_22">
          Third-Party Service Provider. The Bearish uses Stripe, Inc. and its
          affiliates as its third-party service provider for payment services
          (e.g., card acceptance, merchant settlement, and related services)
          (“Third-Party Service Provider”). If you make a purchase on the
          Bearish Platform, you will be required to provide your payment details
          and any additional information required to complete your order
          directly to our Third-Party Service Provider. You agree to be bound by
          Stripe’s Privacy Policy (currently accessible at
          https://stripe.com/us/privacy) and its Terms of Service (currently
          accessible at https://stripe.com/ssa) and hereby consent and authorize
          the Bearish and Stripe to share any information and payment
          instructions you provide with one or more Third-Party Service
          Provider(s) to the minimum extent required to complete your
          transactions. Please note that online payment transactions may be
          subject to validation checks by our Third-Party Service Provider and
          your card issuer, and we are not responsible if your card issuer
          declines to authorize payment for any reason. For your protection, our
          Third-Party Service Provider uses various fraud prevention protocols
          and industry standard verification systems to reduce fraud and you
          authorize it to verify and authenticate your payment information. Your
          card issuer may charge you an online handling fee or processing fee.
          We are not responsible for this. In some jurisdictions, our
          Third-Party Service Provider may use third parties under strict
          confidentiality and data protection requirements for the purposes of
          payment processing services.
        </div>
        <div className="mt_b_22">
          Payment. You shall pay all fees or charges (“Fees”) to your Account in
          accordance with the fees, charges and billing terms in effect at the
          time a Fee is due and payable. By providing Bearish and/or our
          Third-Party Service Provider with your payment information, you agree
          that Bearish and/or our Third-Party Service Provider is authorized to
          immediately invoice your Account for all Fees due and payable to
          Bearish hereunder and that no additional notice or consent is
          required. You shall immediately notify Bearish of any change in your
          payment information to maintain its completeness and accuracy. Bearish
          reserves the right at any time to change its prices and billing
          methods in its sole discretion. You agree to have sufficient funds or
          credit available upon placement of any order to ensure that the
          purchase price is collectible by us. Your failure to provide accurate
          payment information to Bearish and/or our Third-Party Service Provider
          or our inability to collect payment constitutes your material breach
          of this Agreement. Except as set forth in this Agreement, all Fees for
          the Bearish Platform are non-refundable. Subscriptions. If you
          purchase access to certain features and functionality of the Bearish
          Platform on a time-limited basis (a “Subscription”), the Fee for such
          Subscription (“Bearish Platform Subscription Fee”) will be billed at
          the start of the Subscription (“Subscription Bearish Platform
          Commencement Date”) and at regular intervals in accordance with your
          elections at the time of purchase. Bearish reserves the right to
          change the timing of our billing. Bearish reserves the right to change
          the Subscription pricing at any time in accordance with Section 17.6
          (Agreement Updates). If changes to the Subscription price occur that
          impact your Subscription, Bearish will use commercially reasonable
          efforts to notify you, such as by sending an email to the email
          address associated with your Account. If you do not agree with such
          changes, you may cancel your Subscription as set forth in Section
          9.3(a)(i) (Cancelling Subscriptions Purchased via Bearish) or
          9.3(a)(ii) (Cancelling Subscriptions Purchased via a Third-Party
          Application Store). Bearish is not obligated to provide the Bearish
          Platform to you until Bearish accepts your order by a confirmatory
          email or other appropriate means of communication.
        </div>
        <div className="mt_b_22">
          Automatic Renewal. If you elect to purchase a Subscription, your
          Subscription will continue and automatically renew at Bearish’s
          then-current price for such Subscription until terminated in
          accordance with this Agreement. The frequency at which your
          Subscription renews (i.e., weekly, monthly, annually, etc.) will be
          designated at the time at you sign up for the Subscription and may be
          modified by you via your Account settings. By subscribing, you
          authorize Bearish to charge the payment method designated in your
          Account now, and again at the beginning of any subsequent Subscription
          period. Upon renewal of your Subscription, if Bearish does not receive
          payment, (i) you shall pay all amounts due on your Account upon demand
          and/or (ii) you agree that Bearish may either terminate or suspend
          your Subscription and continue to attempt to charge your designated
          payment method until payment is received (upon receipt of payment,
          your Account will be activated and for purposes of automatic renewal,
          your new Subscription commitment period will begin as of the day
          payment was received).
        </div>
        <div className="mt_b_22">
          Cancelling Subscriptions Purchased via Bearish. If you purchased your
          Subscription directly from Bearish, you may cancel your Subscription
          by logging into and going to the “Account Settings” page. If you do
          not wish your Account to renew automatically, or if you want to change
          or terminate your Subscription, you must contact Bearish at
          billing@bearishfh.com, or log in and go to the “Account Settings”
          page. Cancelling Subscriptions Purchased via a Third-Party Application
          Store. If you wish to cancel, change, or terminate a Subscription that
          you purchased from a third-party application store, you must do so
          prior to the renewal commencement date via such third-party
          application store. Effect of Cancellation. If you cancel your
          Subscription, you may use your Subscription until the end of your
          then-current Subscription term; your Subscription will not be renewed
          after your then-current term expires. However, you will not be
          eligible for a prorated refund of any portion of the Bearish Platform
          Subscription Fee paid for the then-current Subscription period.
          Upgrades and Downgrades. If you choose to upgrade your Subscription in
          the middle of a Subscription period, such upgrade will take effect
          immediately and any incremental fees associated with such upgrade will
          be charged in accordance with this Agreement. In any future renewal
          term, the fees will reflect any such upgrades. If you choose to
          downgrade a Subscription, the downgrade will take effect as of the
          first day of the next renewal term. Downgrading a Subscription may
          cause loss of content, features, or capacity of the Bearish Platform
          as available, and Bearish does not accept any liability for such loss.
        </div>

        <div className="mt_b_22">
          Taxes. The Fees do not include any Sales Tax (defined below) that may
          be due in connection with the Bearish Platform provided under this
          Agreement. If Bearish determines it has a legal obligation to collect
          Sales Tax from you in connection with this Agreement, Bearish shall
          collect such Sales Tax in addition to the Fees. If any services, or
          payments for any services, under this Agreement are subject to any
          Sales Tax in any jurisdiction and you have not remitted the applicable
          Sales Tax to Bearish, you shall be responsible for the payment of such
          Sales Tax and any related penalties or interest to the relevant tax
          authority, and you shall indemnify Bearish for any liability or
          expense Bearish may incur in connection with such Sales Taxes. Upon
          Bearish’s request, you will provide it with official receipts issued
          by the appropriate taxing authority, or other such evidence that you
          have paid all applicable taxes. For purposes of this section, “Sales
          Tax” means any sales or use tax and any other tax measured by sales
          proceeds that is the functional equivalent of a sales tax where the
          applicable taxing jurisdiction does not otherwise impose a sales or
          use tax.
        </div>
        <div className="mt_b_22">
          Withholding Taxes. You shall make all payments of Fees to Bearish free
          and clear of, and without reduction for, any withholding taxes. Any
          such taxes imposed on payments of Fees to Bearish shall be your sole
          responsibility, and you shall provide Bearish with official receipts
          issued by the appropriate taxing authority, or such other evidence as
          we may reasonably request, to establish that such taxes have been
          paid. Free Trials and Promotional Access. Any free trial or other
          promotion that provides users access to the Bearish Platform must be
          used within the specified time of the trial. At the end of the trial
          or promotional period, your use of that Bearish Platform will
          automatically roll into a paid Subscription at our then-current
          Bearish Platform Subscription Fees and you will be charged for such
          Subscription as set forth in Section 9.3 (Subscriptions) if you do not
          cancel prior to Subscription Bearish Platform Commencement Date. If
          you are inadvertently charged for a Subscription and provide us with
          written notice of the error, Bearish will have the charges reversed.
          INDEMNIFICATION. You shall indemnify and hold Bearish, its parents,
          subsidiaries, affiliates, officers, employees, agents, partners,
          suppliers, and licensors (each, a “Bearish Party” and collectively,
          the “Bearish Parties”) harmless from any losses, costs, liabilities
          and expenses (including reasonable attorneys’ fees) relating to or
          arising out of any and all of the following: (i) Your Content; (ii)
          your use of, or inability to use, the Bearish Platform; (iii) your
          violation of this Agreement; (iv) your violation of any rights of
          another party, including any user; or (v) your violation of any
          applicable laws, rules or regulations. Bearish reserves the right, at
          its own cost, to assume the exclusive defense and control of any
          matter otherwise subject to indemnification by you, in which event you
          will fully cooperate with Bearish in asserting any available defenses.
          This provision does not require you to indemnify any of the Bearish
          Parties for any unconscionable commercial practice by such party or
          for such party’s fraud, deception, false promise, misrepresentation or
          concealment, or suppression or omission of any material fact in
          connection with the Bearish Platform provided hereunder. You agree
          that the provisions in this section will survive any termination of
          your Account, this Agreement and/or your access to the Bearish
          Platform.
        </div>
        <div>DISCLAIMER OF WARRANTIES.</div>
        <div className="mt_b_22">
          As Is. YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED
          BY APPLICABLE LAW, YOUR USE OF THE BEARISH PLATFORM IS AT YOUR SOLE
          RISK, AND THE BEARISH PLATFORM IS PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS, WITH ALL FAULTS. THE BEARISH PARTIES EXPRESSLY
          DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF THE BEARISH PLATFORM.
        </div>
        <div className="mt_b_22">
          THE BEARISH PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION
          THAT: (1) THE BEARISH PLATFORM WILL MEET YOUR REQUIREMENTS (SUCH AS
          THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF
          BEARISH PLATFORM); (2) YOUR USE OF THE BEARISH PLATFORM WILL BE
          UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR (3) THE ADVICE,
          RESULTS, OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM USE OF
          THE BEARISH PLATFORM WILL BE ACCURATE OR RELIABLE. ANY CONTENT
          DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH THE BEARISH PLATFORM IS
          ACCESSED AT YOUR OWN RISK, AND YOU ARE SOLELY RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER
          SYSTEM AND/OR ANY DEVICE YOU USE TO ACCESS THE BEARISH PLATFORM, OR
          ANY OTHER LOSS THAT RESULTS FROM ACCESSING SUCH CONTENT. FROM TIME TO
          TIME, BEARISH MAY OFFER “BETA” FEATURES OR TOOLS WITH WHICH ITS USERS
          MAY EXPERIMENT. SUCH FEATURES OR TOOLS ARE OFFERED SOLELY FOR
          EXPERIMENTAL PURPOSES AND WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE
          MODIFIED OR DISCONTINUED AT BEARISH’S SOLE DISCRETION. YOU ACKNOWLEDGE
          THAT THE BEARISH PLATFORM IS AN OPEN BETA RELEASE OF SOFTWARE CODE AND
          IS NOT AT THE LEVEL OF PERFORMANCE AND COMPATIBILITY OF A FINAL,
          GENERALLY AVAILABLE PRODUCT OFFERING. FURTHERMORE, YOU ACKNOWLEDGE
          THAT THE BEARISH PLATFORM CONTAINS BUGS, FLAWS, ERRORS, OMISSIONS AND
          OTHER PROBLEMS THAT COULD CAUSE SYSTEM OR OTHER FAILURES AND DATA LOSS
          AND MAY NOT BE AVAILABLE AT ALL TIMES. YOU AGREE AND ACKNOWLEDGE YOUR
          USE OF THE BEARISH PLATFORM, WHETHER AS PART OF THE OPEN BETA RELEASE
          OR THROUGH A SUBSCRIPTION, IS AT YOUR OWN RISK, AND YOU, NOT BEARISH,
          ARE SOLELY RESPONSIBLE FOR ANY AND ALL LIABILITIES ASSOCIATED WITH
          YOUR USE AND ANY INABILITY TO USE THE BEARISH PLATFORM. THE PROVISIONS
          OF THIS SECTION APPLY WITH FULL FORCE TO SUCH FEATURES OR TOOLS.
        </div>
        <div>
          No Liability for Conduct of Third Parties. YOU ARE SOLELY RESPONSIBLE
          FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH THIRD PARTIES ON
          THE BEARISH PLATFORM. YOU ACKNOWLEDGE AND AGREE THAT THE BEARISH
          PARTIES ARE NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD THE BEARISH
          PARTIES LIABLE, FOR THE CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS
          OF EXTERNAL SITES, AND THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES
          RESTS ENTIRELY WITH YOU. YOU UNDERSTAND THAT BEARISH DOES NOT MAKE ANY
          ATTEMPT TO VERIFY THE STATEMENTS OF USERS. BEARISH MAKES NO WARRANTY
          THAT THE GOODS OR SERVICE PROVIDED BY THIRD PARTIES WILL MEET YOUR
          REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR
          ERROR-FREE BASIS.
        </div>
        <div className="mt_b_22">LIMITATION OF LIABILITY.</div>
        <div className="mt_b_22">
          Disclaimer of Certain Damages. YOU UNDERSTAND AND AGREE THAT, TO THE
          FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT SHALL THE BEARISH PARTIES
          BE LIABLE FOR ANY LOSS OF PROFITS, REVENUE OR DATA, INDIRECT,
          INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR DAMAGES OR COSTS DUE
          TO LOSS OF PRODUCTION OR USE, BUSINESS INTERRUPTION, OR PROCUREMENT OF
          SUBSTITUTE GOODS OR SERVICES, IN EACH CASE WHETHER OR NOT ANY BEARISH
          PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT
          OF OR IN CONNECTION WITH THIS AGREEMENT, THE BEARISH PLATFORM, OR ANY
          COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF THE
          BEARISH PLATFORM OR THIRD PARTIES, ON ANY THEORY OF LIABILITY,
          INCLUDING TO THE EXTENT RESULTING FROM: (i) THE USE OR INABILITY TO
          USE THE BEARISH PLATFORM; (ii) ANY GOODS, DATA, INFORMATION OR SERVICE
          PURCHASED OR OBTAINED; OR MESSAGES RECEIVED FOR TRANSACTIONS ENTERED
          INTO THROUGH THE BEARISH PLATFORM; (iii) UNAUTHORIZED ACCESS TO OR
          ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT
          OF ANY THIRD PARTY ON THE BEARISH PLATFORM; OR (v) ANY OTHER MATTER
          RELATED TO THE BEARISH PLATFORM, WHETHER BASED ON WARRANTY, COPYRIGHT,
          CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER
          LEGAL THEORY. THE FOREGOING LIMITATION OF LIABILITY DOES NOT APPLY TO
          LIABILITY OF A BEARISH PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED
          BY A BEARISH PARTY’S NEGLIGENCE; OR FOR (A) ANY INJURY CAUSED BY A
          BEARISH PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.{" "}
        </div>
        <div className="mt_b_22">
          Cap on Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, THE BEARISH
          PARTIES SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE GREATER OF (i)
          THE TOTAL AMOUNT PAID TO BEARISH BY YOU DURING THE THREE-MONTH PERIOD
          PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH
          LIABILITY; (ii) $100; OR (iii) IF APPLICABLE, THE STATUTORY REMEDY OR
          PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE
          FOREGOING CAP ON LIABILITY DOES NOT APPLY TO LIABILITY OF A BEARISH
          PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A BEARISH PARTY’S
          NEGLIGENCE; OR (B) ANY INJURY CAUSED BY A BEARISH PARTY’S FRAUD OR
          FRAUDULENT MISREPRESENTATION.
        </div>
        <div className="mt_b_22">
          User Content. BEARISH ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS,
          DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY CONTENT (INCLUDING, BUT
          NOT LIMITED TO, YOUR CONTENT AND USER CONTENT), USER COMMUNICATIONS OR
          PERSONALIZATION SETTINGS. Exclusion of Damages. CERTAIN JURISDICTIONS
          DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE EXCLUSIONS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS. Basis of
          the Bargain. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
          FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN BEARISH AND
          YOU.
        </div>
        <div className="">
          PROCEDURE FOR MAKING CLAIMS OF INTELLECTUAL PROPERTY RIGHT
          INFRINGEMENT. It is Bearish’s policy to terminate membership
          privileges of any user who repeatedly infringes copyright, trademark,
          or other intellectual property rights upon prompt notification to
          Bearish by the respective intellectual property owner or their legal
          agent. Without limiting the foregoing, if you believe that your work
          has been copied and posted on the Bearish Platform in a way that
          constitutes intellectual property rights infringement, please provide
          our designated intellectual property agent with the following
          information: (i) an electronic or physical signature of the person
          authorized to act on behalf of the owner of the copyright, trademark,
          or other intellectual property right; (ii) a description of the
          copyrighted work, trademark, or other intellectual property right that
          you claim has been infringed; (iii) a description of the location on
          the Bearish Platform of the material that you claim is infringing;
          (iv) your address, telephone number, and email address; (v) a written
          statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright, trademark, or other
          intellectual property right owner, its agent or the law; and (vi) a
          statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright,
          trademark, or other intellectual property right owner or authorized to
          act on the copyright, trademark, or other intellectual property right
          owner’s behalf. Contact information for Bearish’s designated agent for
          notice of claims of infringement is as follows: Bearish FH INC, 95 and
          3rd St, San Francisco CA 94103
        </div>
        <div className="mt_b_22">TERM AND TERMINATION.</div>
        <div className="mt_b_22">
          Term. The term of this Agreement commences on the date when you accept
          this Agreement (as described in the preamble above), and continues in
          full force and effect while you use the Bearish Platform, unless
          terminated earlier in accordance with this Agreement (“Term”).
          Termination of Bearish Platform by Bearish. You have fifteen (15) days
          from the Subscription Bearish Platform Commencement Date, or any
          renewal commencement date, for any Bearish Platform hereunder, to
          cancel such Bearish Platform, in which case, Bearish will refund your
          Bearish Platform Subscription Fee if already paid pursuant to Section
          9.2 (Payment) or 9.3 (Subscriptions), for the applicable Bearish
          Platform. Except as set forth above, the Bearish Platform Subscription
          Fee for any Bearish Platform is non-refundable. If you have materially
          breached any provision of this Agreement, or if Bearish is required to
          do so by law (e.g., where the provision of the Bearish Platform is, or
          becomes, unlawful), Bearish has the right to, immediately and without
          notice, suspend or terminate any Bearish Platform provided to you.
          Bearish reserves the right to terminate this Agreement or your access
          to the Bearish Platform at any time without cause upon notice to you.
          In the event we exercise this termination right, we will refund you
          for any pre-paid portion of your unused Subscription. You agree that
          all terminations for cause are made in Bearish’s sole discretion and
          that Bearish shall not be liable to you or any third party for any
          termination of your Account. Termination by You. If you want to
          terminate this Agreement, you may do so by (i) notifying Bearish at
          any time and (ii) closing your Account. Your notice should be sent, in
          writing, to Bearish’s address set forth below. ANY SUCH TERMINATION
          WILL BE EFFECTIVE AT THE END OF THE THEN-CURRENT TERM OF ANY AND ALL
          OF THE SUBSCRIPTIONS, WHICH WILL CONTINUE AT THE END OF EACH
          SUBSCRIPTION PERIOD UNLESS YOU CANCEL YOUR SUBSCRIPTION IN ACCORDANCE
          WITH THE PROCEDURE SET FORTH IN SECTION 9.3(a) (AUTOMATIC RENEWAL).
          Effect of Termination. Upon termination of the Bearish Platform or the
          applicable feature or functionality thereof, your right to use the
          Bearish Platform or the applicable feature or functionality thereof
          will automatically terminate, and we may delete Your Content
          associated therewith from our live databases. If we terminate your
          Account for cause, we may also bar your further use or access to the
          Bearish Platform. Bearish will not have any liability whatsoever to
          you for any suspension or termination, including for deletion of Your
          Content. All provisions of this Agreement which by their nature should
          survive, will survive termination of Bearish Platform, including
          without limitation, ownership provisions, warranty disclaimers, and
          limitations of liability.
        </div>
        <div className="mt_b_22">
          No Subsequent Registration. If this Agreement is terminated for cause
          by Bearish or if your Account or ability to access the Bearish
          Platform is discontinued by Bearish due to your violation of any
          portion of this Agreement or for conduct otherwise deemed
          inappropriate, then you agree that you shall not attempt to
          re-register with or access the Bearish Platform through use of a
          different member name or otherwise. INTERNATIONAL USERS. The Bearish
          Platform may be accessed from countries around the world and may
          contain references to services and Content that are not available in
          your country. These references do not imply that Bearish intends to
          announce such service or Content in your country. The Bearish Platform
          is controlled and offered by Bearish from its facilities in the United
          States of America. Bearish makes no representations that the Bearish
          Platform is appropriate or available for use in other locations. Those
          who access or use the Bearish Platform from other countries do so at
          their own volition and are responsible for compliance with local law.
        </div>
        <div className="mt_b_22">
          ARBITRATION AGREEMENT. Please read this section (the “Arbitration
          Agreement”) carefully. It is part of your contract with Bearish and
          affects your rights. It contains procedures for MANDATORY BINDING
          ARBITRATION AND A CLASS ACTION WAIVER.
        </div>
        <div className="mt_b_22">
          Applicability of Arbitration Agreement. Subject to the terms of this
          Arbitration Agreement, you and Bearish agree that any dispute, claim,
          disagreements arising out of or relating in any way to your access to
          or use of the Bearish Platform, any communications you receive, any
          products sold or distributed through the Bearish Platform or this
          Agreement and prior versions of this Agreement, including claims and
          disputes that arose between you and us before the effective date of
          this Agreement (each, a “Dispute”) will be resolved by binding
          arbitration, rather than in court, except that: (i) you and Bearish
          may assert claims or seek relief in small claims court if such claims
          qualify and remain in small claims court; and (ii) you or Bearish may
          seek equitable relief in court for infringement or other misuse of
          intellectual property rights (such as trademarks, trade dress, domain
          names, trade secrets, copyrights, and patents). For purposes of this
          Arbitration Agreement, “Dispute” will also include disputes that arose
          or involve facts occurring before the existence of this or any prior
          versions of this Agreement as well as claims that may arise after the
          termination of this Agreement.
        </div>
        <div className="mt_b_22">
          Informal Dispute Resolution. There might be instances when a Dispute
          arises between you and Bearish. If that occurs, Bearish is committed
          to working with you to reach a reasonable resolution. You and Bearish
          agree that good faith informal efforts to resolve Disputes can result
          in a prompt, low‐cost and mutually beneficial outcome (“Informal
          Dispute Resolution”). You and Bearish therefore agree that before
          either party commences arbitration against the other (or initiates an
          action in small claims court if a party so elects), we will personally
          meet and confer telephonically or via videoconference, in a good faith
          effort to resolve informally any Dispute covered by this Arbitration
          Agreement (“Informal Dispute Resolution Conference”). If you are
          represented by counsel, your counsel may participate in the
          conference, but you will also participate in the conference.{" "}
        </div>
        <div className="mt_b_22">
          The party initiating a Dispute must give notice to the other party in
          writing of its intent to initiate an Informal Dispute Resolution
          Conference (“Notice”), which shall occur within forty-five (45) days
          after the other party receives such Notice, unless an extension is
          mutually agreed upon by the parties. Notice to Bearish that you intend
          to initiate an Informal Dispute Resolution Conference should be sent
          by email to support@bearishfh.com or regular mail to our offices
          located at 95 and 3rd St, San Francisco CA 94103. The Notice must
          include: (1) your name, telephone number, mailing address, e‐mail
          address associated with your Account (if you have one); (2) the name,
          telephone number, mailing address and e‐mail address of your counsel,
          if any; and (3) a description of your Dispute.
        </div>
        <div className="mt_b_22">
          The Informal Dispute Resolution Conference shall be individualized
          such that a separate conference must be held each time either party
          initiates a Dispute, even if the same law firm or group of law firms
          represents multiple users in similar cases, unless all parties agree;
          multiple individuals initiating a Dispute cannot participate in the
          same Informal Dispute Resolution Conference unless all parties agree.
          In the time between a party receiving the Notice and the Informal
          Dispute Resolution Conference, nothing in this Arbitration Agreement
          shall prohibit the parties from engaging in informal communications to
          resolve the initiating party’s Dispute. Engaging in the Informal
          Dispute Resolution Conference is a condition precedent and requirement
          that must be fulfilled before commencing arbitration. The statute of
          limitations and any filing fee deadlines shall be tolled while the
          parties engage in the Informal Dispute Resolution Conference process
          required by this section. Waiver of Jury Trial. YOU AND BEARISH HEREBY
          WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE
          A TRIAL IN FRONT OF A JUDGE OR A JURY. You and Bearish are instead
          electing that all Disputes shall be resolved by arbitration under this
          Arbitration Agreement, except as specified in Section 16.1
          (Applicability of Arbitration Agreement). There is no judge or jury in
          arbitration, and court review of an arbitration award is subject to
          very limited review.{" "}
        </div>
        <div className="mt_b_22">
          Waiver of Class and Other Non-Individualized Relief. YOU AND BEARISH
          AGREE THAT, EXCEPT AS SPECIFIED IN SECTION 16.9 (BATCH ARBITRATION),
          EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL
          BASIS AND NOT ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS, AND THE
          PARTIES HEREBY WAIVE ALL RIGHTS TO HAVE ANY DISPUTE BE BROUGHT, HEARD,
          ADMINISTERED, RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
          REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL RELIEF IS
          AVAILABLE, AND DISPUTES OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
          Subject to this Arbitration Agreement, the arbitrator may award
          declaratory or injunctive relief only in favor of the individual party
          seeking relief and only to the extent necessary to provide relief
          warranted by the party’s individual claim. Nothing in this paragraph
          is intended to, nor shall it, affect the terms and conditions under
          Section 16.9 (Batch Arbitration). Notwithstanding anything to the
          contrary in this Arbitration Agreement, if a court decides by means of
          a final decision, not subject to any further appeal or recourse, that
          the limitations of this section are invalid or unenforceable as to a
          particular claim or request for relief (such as a request for public
          injunctive relief), you and Bearish agree that that particular claim
          or request for relief (and only that particular claim or request for
          relief) shall be severed from the arbitration and may be litigated in
          the state or federal courts located in the State of California. All
          other Disputes shall be arbitrated or litigated in small claims court.
          This section does not prevent you or Bearish from participating in a
          class-wide settlement of claims.{" "}
        </div>
        <div className="mt_b_22">
          Rules and Forum. This Agreement evidences a transaction involving
          interstate commerce; and notwithstanding any other provision herein
          with respect to the applicable substantive law, the Federal
          Arbitration Act, 9 U.S.C. § 1 et seq., will govern the interpretation
          and enforcement of this Arbitration Agreement and any arbitration
          proceedings. If the Informal Dispute Resolution process described
          above does not resolve satisfactorily within sixty (60) days after
          receipt of your Notice, you and Bearish agree that either party shall
          have the right to finally resolve the Dispute through binding
          arbitration. The arbitration will be administered by the American
          Arbitration Association (“AAA”), in accordance with the Consumer
          Arbitration Rules (the “AAA Rules”) then in effect, except as modified
          by this section of this Arbitration Agreement. The AAA Rules are
          currently available at
          https://www.adr.org/sites/default/files/Consumer%20Rules.pdf.
        </div>
        <div className="mt_b_22">
          A party who wishes to initiate arbitration must provide the other
          party with a request for arbitration (the “Request”). The Request must
          include: (1) the name, telephone number, mailing address, e‐mail
          address of the party seeking arbitration and the account username (if
          applicable) as well as the email address associated with any
          applicable Account; (2) a statement of the legal claims being asserted
          and the factual bases of those claims; (3) a description of the remedy
          sought and an accurate, good‐faith calculation of the amount in
          controversy in United States dollars; (4) a statement certifying
          completion of the Informal Dispute Resolution process as described
          above; and (5) evidence that the requesting party has paid any
          necessary filing fees in connection with such arbitration.{" "}
        </div>
        <div className="mt_b_22">
          If the party requesting arbitration is represented by counsel, the
          Request shall also include counsel’s name, telephone number, mailing
          address, and email address. Such counsel must also sign the Request.
          By signing the Request, counsel certifies to the best of counsel’s
          knowledge, information, and belief, formed after an inquiry reasonable
          under the circumstances, that: (1) the Request is not being presented
          for any improper purpose, such as to harass, cause unnecessary delay,
          or needlessly increase the cost of dispute resolution; (2) the claims,
          defenses and other legal contentions are warranted by existing law or
          by a nonfrivolous argument for extending, modifying, or reversing
          existing law or for establishing new law; and (3) the factual and
          damages contentions have evidentiary support or, if specifically so
          identified, will likely have evidentiary support after a reasonable
          opportunity for further investigation or discovery.{" "}
        </div>
        <div className="mt_b_22">
          Unless you and Bearish otherwise agree, or the Batch Arbitration
          process discussed in Section 16.9 (Batch Arbitration) is triggered,
          the arbitration will be conducted in the county where you reside.
          Subject to the AAA Rules, the arbitrator may direct a limited and
          reasonable exchange of information between the parties, consistent
          with the expedited nature of the arbitration. If the AAA is not
          available to arbitrate, the parties will select an alternative
          arbitral forum. Your responsibility to pay any AAA fees and costs will
          be solely set forth in the applicable AAA Rules. You and Bearish agree
          that all materials and documents exchanged during the arbitration
          proceedings shall be kept confidential and shall not be shared with
          anyone except the parties’ attorneys, accountants, or business
          advisors, and shall be subject to the condition that they agree to
          keep all materials and documents exchanged during the arbitration
          proceedings confidential.{" "}
        </div>
        <div className="mt_b_22">
          Arbitrator. The arbitrator will be either a retired judge or an
          attorney licensed to practice law in the state of California and will
          be selected by the parties from the AAA’s roster of consumer dispute
          arbitrators. If the parties are unable to agree upon an arbitrator
          within thirty-five (35) days of delivery of the Request, then the AAA
          will appoint the arbitrator in accordance with the AAA Rules, provided
          that if the Batch Arbitration process under Section 16.9 (Batch
          Arbitration) is triggered, the AAA will appoint the arbitrator for
          each batch.
        </div>
        <div className="mt_b_22">
          Authority of Arbitrator. The arbitrator shall have exclusive authority
          to resolve any Dispute, including, without limitation, disputes
          arising out of or related to the interpretation or application of the
          Arbitration Agreement, including the enforceability, revocability,
          scope, or validity of the Arbitration Agreement or any portion of the
          Arbitration Agreement, except for the following: (1) all Disputes
          arising out of or relating to Section 16.4 (Waiver of Class and Other
          Non-Individualized Relief), including any claim that all or part of
          Section 16.4 (Waiver of Class and Other Non-Individualized Relief) is
          unenforceable, illegal, void or voidable, or that such Section 16.4
          (Waiver of Class and Other Non-Individualized Relief) has been
          breached, shall be decided by a court of competent jurisdiction and
          not by an arbitrator; (2) except as expressly contemplated in Section
          16.9 (Batch Arbitration), all Disputes about the payment of
          arbitration fees shall be decided only by a court of competent
          jurisdiction and not by an arbitrator; (3) all Disputes about whether
          either party has satisfied any condition precedent to arbitration
          shall be decided only by a court of competent jurisdiction and not by
          an arbitrator; and (4) all Disputes about which version of the
          Arbitration Agreement applies shall be decided only by a court of
          competent jurisdiction and not by an arbitrator. The arbitration
          proceeding will not be consolidated with any other matters or joined
          with any other cases or parties, except as expressly provided in
          Section 16.9 (Batch Arbitration). The arbitrator shall have the
          authority to grant motions dispositive of all or part of any Dispute.
          The arbitrator shall issue a written award and statement of decision
          describing the essential findings and conclusions on which the award
          is based, including the calculation of any damages awarded. The award
          of the arbitrator is final and binding upon you and us. Judgment on
          the arbitration award may be entered in any court having jurisdiction.
        </div>
        <div className="mt_b_22">
          Attorneys’ Fees and Costs. The parties shall bear their own attorneys’
          fees and costs in arbitration unless the arbitrator finds that either
          the substance of the Dispute or the relief sought in the Request was
          frivolous or was brought for an improper purpose (as measured by the
          standards set forth in Federal Rule of Civil Procedure 11(b)). If you
          or Bearish need to invoke the authority of a court of competent
          jurisdiction to compel arbitration, then the party that obtains an
          order compelling arbitration in such action shall have the right to
          collect from the other party its reasonable costs, necessary
          disbursements, and reasonable attorneys’ fees incurred in securing an
          order compelling arbitration. The prevailing party in any court action
          relating to whether either party has satisfied any condition precedent
          to arbitration, including the Informal Dispute Resolution process, is
          entitled to recover their reasonable costs, necessary disbursements,
          and reasonable attorneys’ fees and costs. Batch Arbitration. To
          increase the efficiency of administration and resolution of
          arbitrations, you and Bearish agree that in the event that there are
          one-hundred (100) or more individual Requests of a substantially
          similar nature filed against Bearish by or with the assistance of the
          same law firm, group of law firms, or organizations, within a thirty
          (30) day period (or as soon as possible thereafter), the AAA shall (1)
          administer the arbitration demands in batches of 100 Requests per
          batch (plus, to the extent there are less than 100 Requests left over
          after the batching described above, a final batch consisting of the
          remaining Requests); (2) appoint one arbitrator for each batch; and
          (3) provide for the resolution of each batch as a single consolidated
          arbitration with one set of filing and administrative fees due per
          side per batch, one procedural calendar, one hearing (if any) in a
          place to be determined by the arbitrator, and one final award (“Batch
          Arbitration”).{" "}
        </div>
        <div className="mt_b_22">
          All parties agree that Requests are of a “substantially similar
          nature” if they arise out of or relate to the same event or factual
          scenario and raise the same or similar legal issues and seek the same
          or similar relief. To the extent the parties disagree on the
          application of the Batch Arbitration process, the disagreeing party
          shall advise the AAA, and the AAA shall appoint a sole standing
          arbitrator to determine the applicability of the Batch Arbitration
          process (“Administrative Arbitrator”). In an effort to expedite
          resolution of any such dispute by the Administrative Arbitrator, the
          parties agree the Administrative Arbitrator may set forth such
          procedures as are necessary to resolve any disputes promptly. The
          Administrative Arbitrator’s fees shall be paid by Bearish.
        </div>
        <div className="mt_b_22">
          You and Bearish agree to cooperate in good faith with the AAA to
          implement the Batch Arbitration process including the payment of
          single filing and administrative fees for batches of Requests, as well
          as any steps to minimize the time and costs of arbitration, which may
          include: (1) the appointment of a discovery special master to assist
          the arbitrator in the resolution of discovery disputes; and (2) the
          adoption of an expedited calendar of the arbitration proceedings.{" "}
        </div>
        <div className="mt_b_22">
          This Batch Arbitration provision shall in no way be interpreted as
          authorizing a class, collective and/or mass arbitration or action of
          any kind, or arbitration involving joint or consolidated claims under
          any circumstances, except as expressly set forth in this provision.
          30-Day Right to Opt Out. You have the right to opt out of the
          provisions of this Arbitration Agreement by sending written notice of
          your decision to opt out to: 95 and 3rd St, San Francisco CA 94103,
          within thirty (30) days after first becoming subject to this
          Arbitration Agreement. Your notice must include your name and address,
          the email address associated with your Account (if you have one), and
          an unequivocal statement that you want to opt out of this Arbitration
          Agreement. If you opt out of this Arbitration Agreement, all other
          parts of this Agreement will continue to apply to you. Opting out of
          this Arbitration Agreement has no effect on any other arbitration
          agreements that you may currently have, or may enter in the future,
          with us.
        </div>
        <div className="mt_b_22">
          Invalidity, Expiration. Except as provided in Section 16.4 (Waiver of
          Class or Other Non-Individualized Relief), if any part or parts of
          this Arbitration Agreement are found under the law to be invalid or
          unenforceable, then such specific part or parts shall be of no force
          and effect and shall be severed and the remainder of the Arbitration
          Agreement shall continue in full force and effect. You further agree
          that any Dispute that you have with Bearish as detailed in this
          Arbitration Agreement must be initiated via arbitration within the
          applicable statute of limitation for that claim or controversy, or it
          will be forever time barred. Likewise, you agree that all applicable
          statutes of limitation will apply to such arbitration in the same
          manner as those statutes of limitation would apply in the applicable
          court of competent jurisdiction. Modification. Notwithstanding any
          provision in this Agreement to the contrary, we agree that if Bearish
          makes any future material change to this Arbitration Agreement, we
          will notify you. Unless you reject the change within thirty (30) days
          of such change become effective by writing to Bearish at 95 and 3rd St
          San Francisco CA 94103], your continued use of the Bearish Platform,
          including the acceptance of products and services offered on the
          Bearish Platform following the posting of changes to this Arbitration
          Agreement constitutes your acceptance of any such changes. Changes to
          this Arbitration Agreement do not provide you with a new opportunity
          to opt out of the Arbitration Agreement if you have previously agreed
          to a version of this Agreement and did not validly opt out of
          arbitration. If you reject any change or update to this Arbitration
          Agreement, and you were bound by an existing agreement to arbitrate
          Disputes arising out of or relating in any way to your access to or
          use of the Bearish Platform, any communications you receive, any
          products sold or distributed through the Bearish Platform or this
          Agreement, the provisions of this Arbitration Agreement as of the date
          you first accepted this Agreement (or accepted any subsequent changes
          to this Agreement) remain in full force and effect. Bearish will
          continue to honor any valid opt outs of the Arbitration Agreement that
          you made to a prior version of this Agreement. GENERAL PROVISIONS.
          Electronic Communications. The communications between you and Bearish
          may take place via electronic means, whether you visit the Bearish
          Platform or send Bearish emails, or whether Bearish posts notices on
          the Bearish Platform or communicates with you via email. For
          contractual purposes, you (i) consent to receive communications from
          Bearish in an electronic form; and (ii) agree that all terms and
          conditions, agreements, notices, disclosures, and other communications
          that Bearish electronically provides to you satisfy any legal
          requirement that such communications would satisfy if it were to be in
          writing. The foregoing does not affect your statutory rights,
          including but not limited to the Electronic Signatures in Global and
          National Commerce Act at 15 U.S.C. §7001 et seq. (“E-Sign”).
        </div>
        <div className="mt_b_22">
          Assignment. The Agreement, and your rights and obligations hereunder,
          may not be assigned, subcontracted, delegated or otherwise transferred
          by you without Bearish’s prior written consent. Bearish may, without
          your consent, freely assign and transfer this Agreement, including any
          of its rights, obligations, or licenses granted under this Agreement.
          Any attempted assignment, subcontract, delegation, or transfer in
          violation of the foregoing will be null and void.{" "}
        </div>
        <div className="mt_b_22">
          Force Majeure. Bearish shall not be liable for any delay or failure to
          perform resulting from causes outside its reasonable control,
          including, but not limited to, acts of God, war, terrorism, riots,
          embargos, acts of civil or military authorities, fire, floods,
          accidents, pandemics, strikes or shortages of transportation
          facilities, fuel, energy, labor or materials.
        </div>
        <div className="mt_b_22">
          Questions, Complaints, Claims. If you have any questions, complaints
          or claims with respect to the Bearish Platform, please contact us at:
          support@bearishfh.com.We will do our best to address your concerns. If
          you feel that your concerns have been addressed incompletely, we
          invite you to let us know for further investigation.
        </div>
        <div className="mt_b_22">
          Consumer Complaints. In accordance with California Civil Code §1789.3,
          you may report complaints to the Complaint Assistance Unit of the
          Division of Consumer Service of the California Department of Consumer
          Affairs by contacting them in writing at 1625 North Market Blvd.,
          Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952-5210.
        </div>
        <div className="mt_b_22">
          Agreement Updates. When changes are made, Bearish will make a new copy
          of this Terms of Use and/or Supplemental Terms, as applicable,
          available on the Bearish Platform, and we will also update the “Last
          Updated” date at the top of this Agreement. If we make any material
          changes and you have registered an Account with us, we will also send
          an email with an updated copy of this Agreement to you at the email
          address associated with your Account. Unless otherwise stated in such
          update, any changes to this Agreement will be effective immediately
          for users without an Account, or thirty (30) days after posting for
          users with an Account. Bearish may require you to provide consent to
          the updated Agreement in a specified manner before further use of the
          Bearish Platform is permitted. IF YOU DO NOT AGREE TO ANY CHANGE(S)
          AFTER RECEIVING A NOTICE OF SUCH CHANGE(S), YOU SHALL STOP USING THE
          BEARISH PLATFORM. Exclusive Venue. To the extent the parties are
          permitted under this Agreement to initiate litigation in a court, both
          you and Bearish agree that all claims and disputes arising out of or
          relating to this Agreement will be litigated exclusively in the state
          or federal courts located in Santa Clara County in the State of
          California.{" "}
        </div>
        <div className="mt_b_22">
          Governing Law. THIS AGREEMENT AND ANY ACTION RELATED THERETO WILL BE
          GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF
          CALIFORNIA,CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING
          EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW
          OF ANOTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS
          FOR THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THE AGREEMENT.{" "}
        </div>
        <div className="mt_b_22">
          Choice of Language. It is the express wish of the parties that this
          Agreement and all related documents have been drawn up in English.
          Notice. Where Bearish requires that you provide an email address, you
          are responsible for providing Bearish with a valid and current email
          address. In the event that the email address you provide to Bearish is
          not valid, or for any reason is not capable of delivering to you any
          notices required by this Agreement, Bearish’s dispatch of the email
          containing such notice will nonetheless constitute effective notice.
          You may give notice to Bearish at the following address:
          support@bearishfh.com. Such notice shall be deemed given when received
          by Bearish by letter delivered by nationally recognized overnight
          delivery service or first class postage prepaid mail at the above
          address. Waiver. Any waiver or failure to enforce any provision of
          this Agreement on one occasion will not be deemed a waiver of any
          other provision or of such provision on any other occasion.
        </div>
        <div className="mt_b_22">
          Severability. If any portion of this Agreement is held invalid or
          unenforceable, that portion must be construed in a manner to reflect,
          as nearly as possible, the original intention of the parties, and the
          remaining portions must remain in full force and effect.
        </div>
        <div className="mt_b_22">
          Export Control. You may not use, export, import, or transfer the
          Bearish Platform except as authorized by U.S. law, the laws of the
          jurisdiction in which you obtained the Bearish Platform, and any other
          applicable laws. In particular, but without limitation, the Bearish
          Platform may not be exported or re-exported (i) into any United States
          embargoed countries, or (ii) to anyone on the U.S. Treasury
          Department’s list of Specially Designated Nationals or the U.S.
          Department of Commerce’s Denied Person’s List or Entity List. By using
          the Bearish Platform, you represent and warrant that (A) you are not
          located in a country that is subject to a U.S. Government embargo, or
          that has been designated by the U.S. Government as a “terrorist
          supporting” country and (B) you are not listed on any U.S. Government
          list of prohibited or restricted parties. You also will not use the
          Bearish Platform for any purpose prohibited by U.S. law, including the
          development, design, manufacture or production of missiles, nuclear,
          chemical or biological weapons. You acknowledge and agree that
          products, services or technology provided by Bearish are subject to
          the export control laws and regulations of the United States. You
          shall comply with these laws and regulations and shall not, without
          prior U.S. government authorization, export, re-export, or transfer
          Bearish products, services or technology, either directly or
          indirectly, to any country in violation of such laws and regulations.
        </div>
        <div className="">
          Entire Agreement. The Agreement is the final, complete and exclusive
          agreement of the parties with respect to the subject matter hereof and
          supersedes and merges all prior discussions between the parties with
          respect to such subject matter.
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};

export default TermAndService;
