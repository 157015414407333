import grp4945 from "../../assets/virtualmeetingicon/Group 4945.png";
import grp4946 from "../../assets/virtualmeetingicon/Group 4946@2x.png";
import grp4947 from "../../assets/virtualmeetingicon/Group 4947@2x.png";
import grp4948 from "../../assets/virtualmeetingicon/Group 4948@2x.png";
import grp4949 from "../../assets/virtualmeetingicon/Group 4949@2x.png";
import grp4950 from "../../assets/virtualmeetingicon/Group 4950@2x.png";
import Img4_3_44 from "../../assets/BearishReplacesave/Screenshot 2024-01-30 at 4.03.44 PM@2x.png";
import Img_4_5_24 from "../../assets/BearishReplacesave/Screenshot 2024-01-30 at 4.05.24 PM@2x.png";
import Img_4_8_0 from "../../assets/BearishReplacesave/Screenshot 2024-01-30 at 4.08.00 PM@2x.png";
import Img_4 from "../../assets/BearishReplacesave/Image 4@2x.png";
import Img_4_6_37 from "../../assets/BearishReplacesave/Screenshot 2024-01-30 at 4.06.37 PM@2x.png";
import Img_5 from "../../assets/BearishReplacesave/Image 5@2x.png";

const ReplaceCardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4_3_44,
    heading: "Scheduling Built In",
    content:
      "With an AI powered calendar and schedule links built in you never need to spend on single feature apps again",
  },
  {
    backgroundImg: grp4949,
    frontImg: Img_4_5_24,
    heading: "Analytics Built In",
    content:
      "Understand Email Opens, Document Reads, Read Receipts, and so many more analytics all included",
  },
  {
    backgroundImg: grp4950,
    frontImg: Img_4_8_0,
    heading: "Stop Paying for Storage",
    content:
      "Wether it’s video call recordings, instant messages, documents, and data or anything in between never worry about storage",
  },
  {
    backgroundImg: grp4945,
    frontImg: Img_4,
    heading: "ONE AI Tool",
    content:
      "New AI apps are propping up everyday don’t get sucked into even more subscription deals, with Bearish our AI is priced in",
  },
  {
    backgroundImg: grp4948,
    frontImg: Img_4_6_37,
    heading: "End the App juggling",
    content:
      "100s of apps, each with their own UI and associated costs all just disappear with Bearish OS simple platform",
  },
  {
    backgroundImg: grp4946,
    frontImg: Img_5,
    heading: "Personalized for you",
    content:
      "No matter how you like to work do it with Bearish OS, a fully truly customizable platform for every user",
  },
];
export default ReplaceCardData;
