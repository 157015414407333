import "./marketing.css";
import img3_43 from "../../assets/BearishOsMarketing/Screenshot 2024-01-22 at 3.43.02 PM@2x.png";
import img4886 from "../../assets/BearishOsMarketing/Group 4886@2x.png";
import img4984 from "../../assets/BearishOSSales/Group 4984@2x.png";
import img5010 from "../../assets/BearishOSSales/Group 5014@2x.png";
import img4975 from "../../assets/BearishOSSales/Group 4975@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import img5017 from "../../assets/BearishOsMarketing/Group 5017@2x.png";
import img5016 from "../../assets/BearishOsMarketing/Group 5016@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import MarketingCardData from "../../components/marketing/marketingCardData";
import img5018 from "../../assets/BearishOsMarketing/Group 5018@2x.png";
import imgb5 from "../../assets/BearishOsMarketing/1b9f7af491347a4a7053fb7a6f22a5b5@2x.png";
import RatingCardSection from "../../components/common/ratingCardSection";
import MarketingRatingCardData from "../../components/marketing/marketingRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const Marketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="mark_main">
        <div className="sale_1_sec">
          <div className="mark_heading top_heading w_600">
            Amplify Your Message, Measure Your Impact: Bearish OS for Next-Level
            Marketing
          </div>
          <div className="top_content mark_content ">
            Step into the spotlight with Bearish OS’s advanced marketing suite
            designed to elevate your brand and connect with your audience like
            never before. From targeted email campaigns to insightful analytics
            and automated social media management, our 
            platform provides all the tools you need to create, distribute, and
            optimize your
            marketing strategies. Harness the power of AI-driven data
            analysis to personalize
             your outreach, engage prospects effectively, and track the
            performance of every
            campaign. With Bearish OS, your marketing is not just about
            reaching people; it’s
            about resonating with them
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="sale_1_sec_img cm_box">
          <img alt="img" className="img_sale cm_box" src={img3_43} />
        </div>
      </div>

      <div className="sale_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={img4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="mark_2_heading heading_30_41_left_blk w_600">
            Precision in Promotion: Targeted Campaign Management with Bearish OS
          </div>
          <div className="mark_2_content fs_18_h_25_left_blk letter_sp">
            Create highly targeted marketing campaigns that speak directly to
            your audience’s needs.
          </div>
          <div className="sal_2_sec">
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4984} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk sal_2_sec_1">
                <span className="w_600">A/B Testing Capabilities: </span> Test
                and optimize your messages for maximum engagement and conversion
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img5010} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk mark_2_cont">
                <span className="w_600">Automated Campaigns: </span>Set up and
                deploy campaigns automatically, saving time and resources
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk mark_2_cont ">
                <span className="w_600">Performance Tracking: </span>
                Monitor campaign success with real-time analytics and reporting
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec gap_mark_27">
        <div className="smark_3_sec_content">
          <div className="mark_3_heading heading_30_41_left_blk w_600">
            Content that Captivates: Craft and Control with Bearish OS
          </div>
          <div className="mark_3_content fs_18_h_25_left_blk letter_sp">
            Empower your content creation with Bearish OS’s comprehensive suite
            of editing tools and management systems. Develop impactful content
            that aligns with your marketing strategy and maintains brand
            consistency across all channels
          </div>
          <div className=" fs_18_h_25_left_blk">
            <span className="w_600">Intuitive Content Editor: </span>Create
            compelling content with an easy-to-use editor
          </div>
          <div className=" fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Asset Library: </span>Organize and store all
            your marketing materials in one accessible location with built in
            filters and labels
          </div>
          <div className=" fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Collaborative Workflows: </span>Facilitate
            teamwork with collaborative features for content creation and
            approval
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about our Communicating with Bearish OS
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="mar_3_sec_img">
          <img alt="img" className="fo_img" src={img5017} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={img5016} />
        </div>
        <div className="mark_2_2_sec">
          <div className="mark_4_sec_heading heading_30_41_left_blk w_600">
            Socially Savvy: Command Your Channels with Bearish OS
          </div>
          <div className="mark_4_sec_content fs_18_h_25_left_blk letter_sp">
            Take control of your social media landscape with Bearish OS’s
            integrated platform. Schedule posts, engage with your community, and
            analyze social metrics to strengthen your brand’s social presence
          </div>
          <div className="sale_4_sec_cont sal_2_sec ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Unified Social Dashboard: </span>Manage
              all your social accounts from a single interface under one simple
              solution
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Engagement Tools: </span>Interact with
              your audience and respond to feedback swiftly with built in reply
              snippets and AI generated content
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Analytics Suite: </span>Measure the impact
              of your social strategies with comprehensive insights and in depth
              understanding of every post
            </div>
          </div>
        </div>
      </div>

      <div className="mark_5_sec heading_30_41_left_blk w_600">
        Visualize the Path: Map and Enhance Every Step of the Customer Journey
        with Emails & More
      </div>

      <div className="sale_5_sec">
        {MarketingCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={img5018} className="fo_img" />
        </div>
        <div className="salmarke_6_content">
          <div className="sm_4th_header heading_30_41_left_blk w_600">
            Brand Harmony: Seamlessly Manage Your Identity Across Every Platform
          </div>
          <div className="mark_6_content fs_18_h_25_left_blk">
            Maintain a cohesive and compelling brand identity with Bearish OS’s
            unified brand management capabilities. Our platform ensures that
            your brand’s voice, visuals, and values are consistent across all
            channels, leveraging AI-powered tools for efficient asset
            organization, labeling, and filtering. With Bearish OS, you can
            easily store, access, and update your marketing materials in one
            secure location, ensuring brand integrity at every customer
            touchpoint
          </div>
          <div className="mark_6_cont">
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Centralized Asset Storage: </span>Keep all
              your brand assets in one secure place with our cloud-based storage
              system, making it easy to access and deploy your marketing
              materials whenever needed
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">AI-Powered Labeling and Filtering: </span>
              Utilize advanced AI tools for smart labeling and filtering of
              assets, ensuring you find the right material at the right time,
              without the hassle
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Dynamic Brand Guidelines: </span>Create
              and distribute dynamic brand guidelines that evolve with your
              brand, ensuring everyone stays aligned with the latest branding
              strategies and visual standards
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec mrk_back_color">
        <div className="mrk_7_heding sale_7_heading w_600 letter_sp">
          Proven Impact: Quantifying Success with Bearish OS Marketing Insights
          delivered to every user every time
        </div>

        <div className="rating_card_main sale_rating_crd">
          {MarketingRatingCardData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={imgb5} />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="mrk_8_heading heading_30_41_left_blk w_600 letter_sp">
            AI Empowered Marketing: Transform Insights into Action with Bearish
            OS
          </div>
          <div className="pas_5th_sec_content fs_18_h_25_left_blk mrk_8_content_he">
            Unleash the full potential of your marketing strategies with the
            advanced AI of Bearish OS. Our intelligent system not only collects
            and analyzes vast amounts of data but also turns those insights into
            actionable strategies. From predicting consumer behavior to
            automating personalized content delivery and optimizing campaign
            performance in real-time, Bearish OS’s AI ensures your marketing
            efforts are always one step ahead. Experience the power of AI that
            learns from patterns, adapts strategies for maximum impact, and
            drives unparalleled growth for your brand
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>


      {/* <WorkSimpler /> */}


    </div>
  );
};
export default Marketing;
