import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const SalesCardData = [
  {
    heading: "Intuitive Document Builder",
    content:
      "Utilize our user-friendly tools to create professional proposals that stand out, with templates and rich editing features",
    background: img4947,
  },
  {
    heading: "Sharing & Collaboration",
    content:
      "Share documents effortlessly within your team or with clients, enabling real-time feedback to refine proposals quickly",
    background: img4969,
  },
  {
    heading: "Engagement Insights",
    content:
      "Gain valuable insights with document analytics that track how recipients interact with your proposals",
    background: img4970,
  },
  {
    heading: "Version Control",
    content:
      "Manage document versions effectively, ensuring all team members work on the most current copy, reducing errors",
    background: img5000,
  },
  {
    heading: "Secure Storage",
    content:
      "Store all your sales documents securely within Bearish OS, with quick retrieval options to keep your workflow fluid and organized",
    background: img5002,
  },
  {
    heading: "Automated Follow-Ups",
    content:
      "Set automated reminders for follow-ups on sent proposals to keep potential deals moving forward on time",
    background: img5001,
  },
];
export default SalesCardData;