
const RatingCardSection = ({ data, i }) => {
    return (
      <div
        className="sale_rating_crd_sec"
        key={i}
        style={{ backgroundImage: `url('${data?.backgroundimg}')` }}
      >
        <div className="sale_rating_line">
          <img className="h_100" src={data?.linecolor} alt="img" />
        </div>
        <div>
          <div className="_rate">{data?.percent}</div>
          <div className="_rate_content">{data?.content}</div>
        </div>
      </div>
    );
  };
  export default RatingCardSection;