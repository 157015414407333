import line526 from "../../assets/BearishOSCentralizedCommunications/Line 526@2x.png";
import line527 from "../../assets/BearishOSCentralizedCommunications/Line 527@2x.png";
import line528 from "../../assets/BearishOSCentralizedCommunications/Line 528@2x.png";
import line529 from "../../assets/BearishOSCentralizedCommunications/Line 529@2x.png";

import back4955 from "../../assets/virtualmeetingicon/Group 4955@2x.png";
import back4959 from "../../assets/virtualmeetingicon/Group 4959@2x.png";
import back4960 from "../../assets/virtualmeetingicon/Group 4960@2x.png";
import back4961 from "../../assets/virtualmeetingicon/Group 4961@2x.png";
const CommuteRatingData = [
  {
    percent: "86%",
    linecolor: line526,
    content: "Reported an increase in team engagement",
    backgroundimg: back4955,
  },
  {
    percent: "81%",
    linecolor: line527,
    content: "Reported quicker updates due to built in summaries",
    backgroundimg: back4959,
  },
  {
    percent: "77%",
    linecolor: line528,
    content: "Reported a decrease in total time spent messaging",
    backgroundimg: back4960,
  },
  {
    percent: "60%",
    linecolor: line529,
    content: "Reported better engagements with customers",
    backgroundimg: back4961,
  },
];
export default CommuteRatingData;
