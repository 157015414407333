import grp4945 from "../../assets/virtualmeetingicon/Group 4945.png";
import grp4946 from "../../assets/virtualmeetingicon/Group 4946@2x.png";
import grp4947 from "../../assets/virtualmeetingicon/Group 4947@2x.png";
import grp4948 from "../../assets/virtualmeetingicon/Group 4948@2x.png";
import grp4949 from "../../assets/virtualmeetingicon/Group 4949@2x.png";
import grp4950 from "../../assets/virtualmeetingicon/Group 4950@2x.png";
import Img4_40 from "../../assets/BearishOSCentralizedCommunications/Screenshot 2024-01-28 at 4.40.27 PM@2x.png";
import Img_4_45 from "../../assets/BearishOSCentralizedCommunications/Screenshot 2024-01-28 at 4.45.03 PM@2x.png";
import Img_4_40_4 from "../../assets/BearishOSCentralizedCommunications/Screenshot 2024-01-28 at 4.40.27 PM-1@2x.png";
import Img4981 from "../../assets/BearishOSCentralizedCommunications/Group 4981@2x.png";
import Img_4_48 from "../../assets/BearishOSCentralizedCommunications/Screenshot 2024-01-28 at 4.48.50 PM@2x.png";
import Img_4_45_53 from "../../assets/BearishOSCentralizedCommunications/Screenshot 2024-01-28 at 4.45.53 PM@2x.png";

const CommunicationData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4_40,
    heading: "Rich Messaging",
    content:
      "Managing relationships requires building trust by creating amazing assets and content all from within Bearish",
  },
  {
    backgroundImg: grp4949,
    frontImg: Img_4_45,
    heading: "Video & Audio Messaging",
    content:
      "Visualize your deals and organize your contacts to manage customers, guests, team- members and more",
  },
  {
    backgroundImg: grp4950,
    frontImg: Img_4_40_4,
    heading: "Auto AI Responses & Updates",
    content:
      "Nurture every contact with automated actions and ensure that every contact gets emails that are personal and customized",
  },
  {
    backgroundImg: grp4945,
    frontImg: Img4981,
    heading: "Editing & Read Receipts",
    content:
      "Avoid typos and ensure your message is perfectly conveyed with our built in Open Source AI",
  },
  {
    backgroundImg: grp4948,
    frontImg: Img_4_48,
    heading: "End to End Encryption",
    content:
      "With built in forms you’ll always know what your customers, teams, and guests are thinking and what they want",
  },
  {
    backgroundImg: grp4946,
    frontImg: Img_4_45_53,
    heading: "So Much More…",
    content:
      "With AI and analytics built into every contact you’ll make every customer or team member feel taken care of",
  },
];
export default CommunicationData;
