import "./thirdSection.css";
const CardSection = ({ data, i, rt }) => {
  return (
    <>
      {rt === "rt" ? (
        <div
          className="vt_crd rt_card_h"
          key={i}
          style={{ backgroundImage: `url('${data?.backgroundImg}')` }}
        >
          <div className="vt_crd_img ">
            <img
              alt="img"
              className={
                data?.heading === "Enrich Every Contact"
                  ? "new_img_rt"
                  : data?.heading === "Access Control for All"
                  ? "new_img_rs"
                  : data?.heading === "Unlimited Guest Views"
                  ? "new_img_rs_sec"
                  : data?.heading === "Rich Real Time Comments"
                  ? "new_img_rs_thrd"
                  : data?.heading === "Analyze and Accelerate"
                  ? "md_image"
                  : data?.heading === "Never Trained on your Data"
                  ? "md_thrd_image" 
                  :data?.heading === "Multi-Factor Authentication"
                  ? "pas_crd_img"
                  : data?.heading === "Understand Every Sign-On"
                  ? "pas_crd_2ndimg"
                  :data?.heading === "Guests Controls"
                  ? "pas_crd_3rdimg"
                  : data?.heading === "Language Processing"
                  ? "be_crd_1st_img"
                  : data?.heading === "Predictive Analytic"
                  ? "be_crd_2nd_img"
                  : data?.heading === "Customized Solutions"
                  ? "be_crd_3rd_img"
                  :data?.heading === "Customized Wellness"
                  ? "tranq_1_img"
                  : data?.heading === "Mental Health Support"
                  ? "tranq_2_img"
                  :  data?.heading === "24/7 Availability"
                  ? "tranq_3_img"
                  :"rt_card_img_dif"
              }
              src={data?.frontImg}
            />
          </div>
          <p className="vt_rt_headg rt_margin">{data?.heading}</p>
          <div className={`vt_crd_content rt_margin_content ${data?.heading === "Never miss a touch-point" ? "sec2_content":""}`}>
            {data?.content}
          </div>
        </div>
      ) : rt === "nmrt" ? (
        <div
          className="vt_crd crd_detail_h"
          key={i}
          style={{ backgroundImage: `url('${data?.backgroundImg}')` }}
        >
          <div className="vt_crd_img_new">
            <img
              alt="hello"
              className={`${
                data?.heading === "Create Assets and Content"
                  ? "first_im"
                  : data?.heading === "Get the answers you need"
                  ? "fitth_im"
                  : data?.heading === "Personalized Touch-Points"
                  ? "sixth_im"  
                  : data?.heading === "End the App juggling"
                  ? "rep_crd_img"
                  :data?.heading === "Personalized for you"
                  ? "rep_crd_img"
                  :data?.heading === "Rich Messaging"
                  ? "cent_4_sec_crd_1_img"
                  : data?.heading === "Auto AI Responses & Updates"
                  ? "cent_4_sec_crd_1_img"
                  :data?.heading === "Video & Audio Messaging"
                  ? "cent_4_sec_crd_2_img"
                  :data?.heading === "Editing & Read Receipts"
                  ? "cent_4_sec_crd_4_img"
                  :data?.heading === "End to End Encryption"
                  ? "cent_4_sec_crd_5_img"
                  :data?.heading === "So Much More…"
                  ? "cent_4_sec_crd_6_img"
                  :"all_im"
                  
              }`}
              src={data?.frontImg}
            />
          </div>
          <p className="vt_rt_headg vt_rt_new_head">{data?.heading}</p>
          <div className="vt_crd_content vt_rt_new_head_margin">
            {data?.content}
          </div>
        </div>
      ) : (
        <div
          className="vt_crd"
          key={i}
          style={{ backgroundImage: `url('${data?.backgroundImg}')` }}
        >
          <div className="vt_crd_img">
            <img
              alt="hello"
              className={
                data?.heading === "Collaborate with Ease" ? "vt_im_t" : ""
              }
              src={data?.frontImg}
            />
          </div>
          <p className="vt_rt_headg">{data?.heading}</p>
          <div className={`vt_crd_content ${data?.heading === "So Much More…"? "vt_w_new":""}`}>{data?.content}</div>
        </div>
      )}
    </>
  );
};
export default CardSection;
