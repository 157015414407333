import React from "react";
import "./homepage.css";

import ServiceCardData from "../../components/serviceData/serviceCardData";
import Card from "./cards";
import ContentSlider from "../../components/slider/slider";
import contImage from "../../assets/Group 4886@2x.png";

import lg5056 from "../../assets/Group 5056@2x.png";
import lg5060 from "../../assets/Group 5060@2x.png";
import lg5061 from "../../assets/Group 5061@2x.png";
import lg5062 from "../../assets/Group 5062@2x.png";
import lgIconwitharrow from "../../assets/roundIcons/Group 4931.png";
import arrowIcon from "../../assets/smallicons/Light 1px-arrow_backward.png";
import arrowIco from "../../assets/roundIcons/Group 4931@2x.png";

import PlatformSolution from "../../components/platformSolutions/platformSolution";
import InfodataCard from "../../components/info/infoDataCard";
import InfoData from "../../components/info/infoData";
import ToolFeatures from "../../components/toolFeatures/toolFeature";
import EveryOneSupport from "../../components/everyoneSupport/everyoneSupport";
import NewContent from "../../components/newContent/newContent";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import img4_17 from "../../assets/home/Screenshot 2024-01-01 at 4.17.25 PM@2x.png";
import img12_14 from "../../assets/home/Screenshot 2024-01-09 at 12.14.26 PM@2x.png";
import img5058 from "../../assets/home/Group 5058@2x.png";
import img8 from "../../assets/home/Image 8@2x.png";
import img6 from "../../assets/home/Image 6@2x.png";
import img9 from "../../assets/home/Image 9@2x.png";
import img7 from "../../assets/home/Image 7@2x.png";
import img3_43 from "../../assets/home/Screenshot 2024-01-22 at 3.43.02 PM@2x.png";
import imgf_59 from "../../assets/home/5643da13b6127a11d795e11bce0d3f59@2x.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="container">
      <div className=" home_page">
        <div>
          <div className="new_124_top">
            <div className="main_header w_600">
              Converge your business under a single Work OS
            </div>
            <div className="medium_content">
              Scale Effortlessly with Bearish OS: All Your Work, One Platform.
              Save Big, Boost Sales, Ignite Team Collaboration
            </div>
            <div className="btn_m">
              <button className="re_btn">
                <Link
                  to="https://app.bearishos.com/signup"
                  className="re_btn footer_nav"
                  target="_blank"
                >
                  Start Free Now
                </Link>
              </button>
            </div>
          </div>
          <div className="card_data">
            {ServiceCardData?.map((data, i) => (
              <Card data={data} i={i} />
            ))}
          </div>
          <div className="po_st">
            Powering Startups to Enterprise Teams Across the World
          </div>

          <ContentSlider />
          <div className="info_solution">
            <PlatformSolution />
          </div>
          <div className="md_content">
            <div className="md_content_da">
              <div className="md_st_trial w_600">
                Start with a trial, followed by First-Class Onboarding and
                Comprehensive Support
              </div>
              <div className="md_paid_con">
                Every paid plan of Bearish OS comes with custom onboarding, a
                dedicated support team member, and white glove service.
              </div>
              <div className="md_link">
                <div className="m_d">
                  <a className="a_link_md w_600" href="">
                    A personalized onboarding guide
                  </a>{" "}
                </div>
                <div className="md_sm_back">
                  <img src={arrowIcon} alt="" className="md_arrow" />
                </div>
              </div>
            </div>
            <div className="md_img">
              <img className="img_md" src={contImage} alt="" />
            </div>
          </div>
          <div className="lg_content">
            <div className="lg_image postion_rela">
              <div>
                <img className="lg_1_img postion_abs" src={lg5056} alt="img" />
              </div>
              <div className=" img_2_q">
                <div className="bg_cd_img_4_17 postion_abs">
                  <img className="fo_img" src={img4_17} alt="img" />
                </div>
                <div className="bg_cd_img_12_14 postion_abs">
                  <img
                    // className="bg_cd_img_12_14 postion_abs"
                    className="fo_img"
                    src={img12_14}
                    alt="img"
                  />
                </div>
                <div className="bg_cd_img_50_58 postion_abs">
                  <img
                    // className="bg_cd_img_50_58 postion_abs"
                    className="fo_img"
                    src={img5058}
                    alt="img"
                  />
                </div>
              </div>
              <div className=" img_3_q">
                <div className="postion_abs bg_cd_img_8">
                  <img
                    // className="postion_abs bg_cd_img_8"
                    src={img8}
                    className="fo_img"
                    alt="img"
                  />
                </div>
                <div className="postion_abs bg_cd_img_6">
                  <img
                    // className="postion_abs bg_cd_img_6"
                    className="fo_img"
                    src={img6}
                    alt="img"
                  />
                </div>
                <div className="postion_abs bg_cd_img_9">
                  <img
                    // className="postion_abs bg_cd_img_9"
                    className="fo_img"
                    src={img9}
                    alt="img"
                  />
                </div>
                <div className="postion_abs bg_cd_img_7">
                  <img
                    // className="postion_abs bg_cd_img_7"
                    className="fo_img"
                    src={img7}
                    alt="img"
                  />
                </div>
              </div>
              <div className=" img_4_q">
                <div className="postion_abs bg_cd_img_3_43">
                  <img
                    // className="postion_abs bg_cd_img_3_43"
                    className="fo_img"
                    src={img3_43}
                    alt="img"
                  />
                </div>
                <div className="postion_abs bg_cd_img_f_59">
                  <img
                    className="fo_img"
                    // className="postion_abs bg_cd_img_f_59"
                    src={imgf_59}
                    alt="img"
                  />
                </div>
              </div>
            </div>

            <div className="lg_data">
              {InfoData?.map((data, i) => (
                <InfodataCard data={data} i={i} />
              ))}
            </div>
          </div>
          <div className="tool_header">
            Unlock the Toolbox: Every Feature, One Unified Price
          </div>
          <ToolFeatures />
          <EveryOneSupport />
          <NewContent />
          <div className="env_div postion_rela">
            <a href="https://climate.stripe.com/jhfnOr" target="_blank">
              <div className="env_lnk postion_abs">
                <div className="lg_a_div env_lnk_u">Learn more now</div>
                <img className="env_ar_icon" alt="icon" src={arrowIco} />
              </div>
            </a>
          </div>
          {/* <WorkSimpler /> */}
        </div>
      </div>
    </div>
  );
};
export default HomePage;
