import grp4973 from "../../assets/BearishReplacesave/Group 4973@2x.png";
import grp4987 from "../../assets/BearishReplacesave/Group 4987@2x.png";
import grp4975 from "../../assets/BearishReplacesave/Group 4975@2x.png";
const ReplaceIconData = [
  {
    icon: grp4973,
    content:
      "Powerful tools – from project management to analytics – all included in one straightforward price",
    boldContent: "All-Inclusive Feature Set: ",
  },
  {
    icon: grp4987,
    content:
      "Enjoy the clarity of transparent pricing with Bearish. What you see is what you get, with no surprise costs",
    boldContent: "No Hidden Fees: ",
  },
  {
    icon: grp4975,
    content:
      "Whether you’re a startup or a large enterprise, benefit from the same comprehensive features",
    boldContent: "Value for Every User: ",
  },
];
export default ReplaceIconData;
