import img4886_2 from "../../assets/BearishReplacesave/Group 4886@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
const SecondSectionOfReplace = () => {
  return (
    <div className="rep_2nd_sec">
      <div className="new_rep_img">
        <div className="heading_30_41_left_blk rep_heading_ w_600">
          Consolidate and Conquer: Streamline Your Toolset, Focus Your
          Resources, and Amplify Your Impact
        </div>
        <div className="fs_18_h_25_left_blk rep_content_">
          Embrace the power of consolidation with Bearish OS. By integrating key
          business functions into one platform, we help you streamline your
          resources and sharpen your focus. Say goodbye to redundant tools and
          hello to a more cohesive, efficient, and impactful business strategy
        </div>
        <div className="lnk_sim">
          <div className="lk w_600">
            <a href="#" className="sim_9th_lnk">
              Get started for free now
            </a>
          </div>
          <div className="con_icon">
            <img alt="icon" className="fo_img" src={rdIcons} />
          </div>
        </div>
      </div>
      <div className="rep_img">
        <img alt="img" className="fo_img" src={img4886_2} />
      </div>
    </div>
  );
};
export default SecondSectionOfReplace;
