import { Link } from "react-router-dom";
import img5_19_13 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 5.19.13 PM@2x.png";

const FirstSectionForRelaShipManager = () => {
  return (
    <div className="rs_firstsec">
      <div className="rs_left_content">
        <div className="rs_first_heading w_600">
          Transform Your Work: Unleash the Power of Project Coordination,
          Dynamic Presentation Crafting, and Secure Document & Asset Management{" "}
          – All in One Intelligent Solution
        </div>
        <div className="rs_fsec_content">
          Experience streamlined project orchestration and creative document
          generation,
           combined with robust asset management, in one cohesive
          platform. Our solution simplifies complex tasks, offering a
          unified space for managing projects, crafting
           presentations, and securely handling all your important assets
          with ease
          and efficiency
        </div>
        <button className="re_btn"><Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link></button>
      </div>
      <div className="rs_fsec_img cm_box">
        <img alt="img" className="rs_img" src={img5_19_13} />
      </div>
    </div>
  );
};
export default FirstSectionForRelaShipManager;
