import "./simplify.css";

import FirstSectionOfSimplify from "../../components/simplifyContent/firstSection";
import SecondSectionOfSimplify from "../../components/simplifyContent/secondSection";
import ThirdSectionOfSimplify from "../../components/simplifyContent/thirdSectionOfSimplfy";

import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import SimplifyCardData from "../../components/simplifyContent/simplifyCardData";
import img8fc from "../../assets/BearishSimplify/4c437ccde42378a5f3a2e9eee54dd8fc@2x.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import img4886_1 from "../../assets/BearishSimplify/Group 4886-1@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import SimplifyEighthSection from "../../components/simplifyContent/eighthSection";
const Simplify = () => {

  const [rtCards, setRtCards] = useState("rt");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <FirstSectionOfSimplify />
      <SecondSectionOfSimplify />
      <ThirdSectionOfSimplify />
      <div className="heading_30_41_left_blk w_600 sm_hr_lnk">
        Accelerate Growth and Responses with Open Source AI
      </div>
      <div className="crd_relation sm_mg_bottom">
        {SimplifyCardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>
      <div className="heading_30_41_left_blk w_600 sim_wi">
        Intelligently Connect to Ensure Seamless Access and Control
      </div>
      <div className="blnk_back"></div>
      <div className="sim_6th_sec">
        <div className="sim_6th_img">
          <img alt="img" src={img8fc} className="fo_img" />
        </div>
        <div className="new_sim_dat">
          <div className="sim_6th_cont heading_30_41_left_blk w_600">
            See the big picture with cross team data to power every
            decision
          </div>
          <div className="sim_6th_content fs_18_h_25_left_blk">
            Unlock the full potential of your team’s collective intelligence
            with Bearish OS. Our 
            platform empowers you to harness cross-team data, providing a
            comprehensive view
             that informs every business decision. By integrating data
            from various departments,
           we enable more informed strategies, foster deeper insights,
            and drive effective
            collaboration across your organization. With Bearish OS,
            you’re not just collecting
           data; you’re leveraging it to make smarter, faster decisions
            that propel your business
             forward. Experience the power of unified data at your
            fingertips, guiding you to 
            make decisions with confidence and precision
          </div>
        </div>
      </div>
      <div className="heading_30_41_left_blk w_600 sim_7th_heading">
        Guard Your Data, Shield Your Business, and Uphold Privacy: Bearish OS –
        Designed with Privacy as its Foundation
      </div>

<SimplifyEighthSection/>
      

      <div className="sm_9th_sec">
        <div className="sm_9th_img">
          <img alt="img" className="fo_img" src={img4886_1} />
        </div>
        <div className="sim_9th_cont">
          <div className="heading_30_41_left_blk sm_9th_heading w_600">
            Praised by Users: Experience the Exceptional Service Our Customers
            Celebrate
          </div>
          <div className="fs_18_h_25_left_blk sm_9th_content">
            Celebrate service that sets new standards – our support team is
            renowned for its exceptional, personalized assistance, consistently
            earning accolades from our valued customers. With Bearish OS, you’re
            not just a user; you’re part of a community where outstanding
            support is a given, not a luxury
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600"><a href="#" className="sim_9th_lnk">Get started for free now</a></div>
            <div className="con_icon"><img alt="icon" className="fo_img" src={rdIcons}/></div>
          </div>
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default Simplify;
