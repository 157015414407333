import line531 from "../../assets/BearishOSSales/Line 531@2x.png";
import line532 from "../../assets/BearishOSSales/Line 532@2x.png";
import line533 from "../../assets/BearishOSSales/Line 533@2x.png";
import line535 from "../../assets/BearishOSSales/Line 535@2x.png";

import back4993 from "../../assets/BearishOSSales/Group 4993@2x.png";
import back4994 from "../../assets/BearishOSSales/Group 4994@2x.png";
import back4995 from "../../assets/BearishOSSales/Group 4995@2x.png";
import back4996 from "../../assets/BearishOSSales/Group 4996@2x.png";

const ProductManagementRatingData = [
  {
    percent: "30%",
    linecolor: line535,
    content: "Reported a reduction in overall project overrun",
    backgroundimg: back4993,
  },
  {
    percent: "45%",
    linecolor: line531,
    content: "Reported an increase in team productivity",
    backgroundimg: back4994,
  },
  {
    percent: "39%",
    linecolor: line532,
    content: "Reported faster project delivery times",
    backgroundimg: back4995,
  },
  {
    percent: "25%",
    linecolor: line533,
    content: "Reported a decrease in overall communication lag",
    backgroundimg: back4996,
  },
];
export default ProductManagementRatingData;
