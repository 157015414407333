import line531 from "../../assets/BearishOSSales/Line 531@2x.png";
import line532 from "../../assets/BearishOSSales/Line 532@2x.png";
import line533 from "../../assets/BearishOSSales/Line 533@2x.png";
import line535 from "../../assets/BearishOSSales/Line 535@2x.png";

import back4993 from "../../assets/BearishOSSales/Group 4993@2x.png";
import back4994 from "../../assets/BearishOSSales/Group 4994@2x.png";
import back4995 from "../../assets/BearishOSSales/Group 4995@2x.png";
import back4996 from "../../assets/BearishOSSales/Group 4996@2x.png";

const OperationRatingData = [
  {
    percent: "40%",
    linecolor: line535,
    content: "Reported a increase in the speed of decisions",
    backgroundimg: back4993,
  },
  {
    percent: "33%",
    linecolor: line531,
    content: "Reported an increase in overall operational efficiency",
    backgroundimg: back4994,
  },
  {
    percent: "20%",
    linecolor: line532,
    content: "Reported reduction in total operational costs",
    backgroundimg: back4995,
  },
  {
    percent: "18%",
    linecolor: line533,
    content: "Reported a increase in overall customer satisfaction",
    backgroundimg: back4996,
  },
];
export default OperationRatingData;
