import "./platform.css";
import PlatFormCard from "./platformCard";
import PlatformData from "./platformData";
import pt_icon from "../../assets/img_log.png.png";
const PlatformSolution = () => {
  return (
    <div>
      <div className="par_div">
        <div className="pl_at w_600">1 Platform</div>
        <div className="ic_on"><img  src={pt_icon} className="fo_img" alt="icon"/></div>
        <div className="in_so w_600">Infinite Solutions</div>
      </div>
      <div className="crd_par_div">
        {PlatformData?.map((data,i)=>(

        <PlatFormCard data={data} i={i}/>
        ))}
        {/* <div className="crd">
          <div className="be_connect">Bearish Connect</div>
          <div className="be_heading">Centralize Communications</div>
          <div className="be_content">
            Bearish Connect connects email, messaging, calls, and business
            social media channels, simplifying your discussions in one efficient
            platform. Streamline client interactions and media engagement for a
            more organized, secure collaboration experience.
          </div>
          <div className="be_icon_det">
            <div className="ic_det">
              <div className="ico">icon</div>
              <div className="de">Intelligently Connected Channels</div>
            </div>
            <div className="ic_det">
              <div className="ico">icon</div>
              <div className="de">Interactions powered by AI</div>
            </div>
            <div className="ic_det">
              <div className="ico">icon</div>
              <div className="de">Robust Social Media Conversations</div>
            </div>
          </div>
          <div className="learn">
            <div>Learn More</div>
            <div><img alt="" src={arrowIcon} /></div>
          </div>
          <div className="screen_shot">screenshot</div>
        </div> */}
        {/* <div className="crd">3</div>
        <div className="crd">4</div> */}
      </div>
    </div>
  );
};

export default PlatformSolution;
