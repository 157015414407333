import "./investor.css";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
const InvestorRelations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className=" re_ai_main in_re_main">
        <div className="abt_heading re_ai_heading">
          Responsible AI, Our Promise to You and the World
        </div>
        <div className="re_ai_content ">
          <div className="avt_w ">
            Investor Relations: Join the Ethical Investment Revolution{" "}
          </div>
          <div className="mt_b_30">
            At Bearish OS, our journey to redefine the future of productivity
            and privacy is powered not only by our commitment to ethical
            practices but also by the support of visionary investors who share
            our ethos, ethics, and beliefs.
          </div>
          <div>Our Vision</div>
          <div className="mt_b_30">
            Our vision is simple yet profound: To empower individuals,
            businesses, and communities with technology that respects their
            privacy and enhances their productivity. We’re reimagining the
            digital landscape, making it more inclusive, secure, and
            user-centric.
          </div>
          <div>Investing with Purpose</div>
          <div className="mt_b_30">
            We are looking for investors who embody our core values. We believe
            in ethical, responsible investment that aligns with our mission to
            create a better, more secure digital world. Your investment won’t
            just support our platform; it will fuel a global movement.
          </div>
          <div>Privacy and Ethics</div>
          <div className="mt_b_30">
            At Bearish OS, privacy isn’t just a feature; it’s at the core of
            what we do. We never train on individual user data. We’re dedicated
            to responsible AI and the ethical use of technology. By investing in
            Bearish OS, you’re championing a future where privacy is a
            fundamental right, not an afterthought.
          </div>
          <div>Join Our Journey</div>
          <div className="mt_b_30">
            If you share our passion for a future where privacy and productivity
            coexist harmoniously, consider becoming a part of our journey.
            Together, we can redefine the digital experience for millions. Your
            investment will drive innovation, help us expand, and create a
            lasting impact on the world.
          </div>
          <div>Investing in a Vision</div>
          <div className="mt_b_30">
            Investing in Bearish OS is investing in a vision of a better, more
            connected, and secure digital future. Your support enables us to
            accelerate innovation, enhance privacy, and create a lasting impact.
            Your investment becomes a catalyst for positive change in the
            digital realm.
          </div>
          <div>Contact Us</div>
          <div>
            To explore investment opportunities, please get in touch with us at
            invest@bearishfh.com. We’d love to discuss how you can join us in
            shaping a digital world that’s better for all. Be an ethical
            investor. Be a part of Bearish OS.
          </div>
          <div className="mt_b_30">
            Learn more about our mission and vision at www.bearishos.com and
            together, let’s redefine the future.
          </div>
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default InvestorRelations;
