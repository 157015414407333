import "./sales.css";
import img3_43 from "../../assets/BearishOSSales/Screenshot 2024-01-22 at 3.43.02 PM@2x.png";
import img4886 from "../../assets/BearishOSSales/Group 4886@2x.png";
import img4984 from "../../assets/BearishOSSales/Group 4984@2x.png";
import img5010 from "../../assets/BearishOSSales/Group 5014@2x.png";
import img4975 from "../../assets/BearishOSSales/Group 4975@2x.png";
import img5_49 from "../../assets/BearishOSSales/Screenshot 2024-01-24 at 5.49.04 PM@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import grp5016 from "../../assets/BearishOSSales/Group 5016@2x.png";
import grp5012 from "../../assets/BearishOSSales/Group 5012@2x.png";
import SalesCardData from "../../components/sales/cardData";
import SmCardSection from "../../components/sales/smCardSection";
import img5018 from "../../assets/BearishOSSales/Group 5018@2x.png";
import SalesRatingCardData from "../../components/sales/salesRatingData";
import RatingCardSection from "../../components/common/ratingCardSection";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const Sales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="sale_main" id="top">
        <div className="sale_1_sec">
          <div className="sale_1_sec_heading w_600">
            Close Deals Faster, Smarter, Better: Amplify Your Sales with Bearish
            OS
          </div>
          <div className="sale_1_sec_content letter_sp">
            Welcome to the new era of sales with Bearish OS, where every feature
            is designed to sharpen your sales edge. From AI-powered lead
            analysis to seamless communication channels and advanced reporting
            tools, Bearish OS equips your sales team with everything they need
            to outperform the competition. Streamline your sales pipeline,
            engage with prospects more effectively, and close deals with
            precision and ease. With Bearish OS, transform your sales process
            into an intelligent, data-driven powerhouse
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="sale_1_sec_img cm_box">
          <img alt="img" className="img_sale" src={img3_43} />
        </div>
      </div>

      <div className="sale_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={img4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="sale_2_sec_heading heading_30_41_left_blk w_600">
            Intelligent Lead Scoring: Prioritize with Precision
          </div>
          <div className="sale_2_sec_content fs_18_h_25_left_blk letter_sp">
            Utilize Bearish OS’s AI to score leads effectively, ensuring your
            team focuses on the prospects most likely to convert*
          </div>
          <div className="sal_2_sec">
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4984} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk sal_2_sec_1">
                <span className="w_600">AI-Powered Lead Scoring: </span> Bearish
                OS uses advanced AI algorithms to score leads based on
                engagement, behavior, and demographic data.
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img5010} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk sal_2_sec_2">
                <span className="w_600">Dynamic Lead Segmentation: </span>{" "}
                Segment leads dynamically with Bearish OS to tailor your
                marketing and sales approach
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk sal_2_sec_3 ">
                <span className="w_600">Lead Nurturing Automation: </span>
                Automate your lead nurturing process with Bearish OS’s
                intelligent workflows
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec">
        <div className="sale_3_sec_content">
          <div className="sale_3_sec_cont_heading heading_30_41_left_blk w_600">
            Connect and Convince: Communication Tools that Sell
          </div>
          <div className="sale_3_sec_cont_content fs_18_h_25_left_blk letter_sp">
            Leverage integrated communication tools for seamless interactions
            that keep leads engaged and ready to commit
          </div>
          <div className="sale_3_sec_w_573 fs_18_h_25_left_blk">
            <span className="w_600">Email Integration: </span> Streamline your
            email outreach with automated sequences, tracking, and analytics,
            all within Bearish OS
          </div>
          <div className="sale_3_sec_w_573 fs_18_h_25_left_blk">
            <span className="w_600">Instant Messaging: </span> Engage in
            real-time with prospects using integrated messaging, ensuring no
            query goes unanswered and no opportunity is missed{" "}
          </div>
          <div className="sale_3_sec_w_573 fs_18_h_25_left_blk">
            <span className="w_600">Video Conferencing: </span> Connect
            face-to-face with high-quality video calls for demos and
            discussions, bringing a personal touch to remote sales
          </div>
          <div className="sim_9th_ico_cont ">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about our Communicating with Bearish OS
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="sale_3_sec_img">
          <div className="sale_3_sec_fr_img">
            <img alt="img" className="fo_img cm_box" src={img5_49} />
          </div>
        </div>
      </div>

      <div className="sale_4_sec">
        <div className="sale_4_sec_img">
          <img alt="img" className="fo_img" src={grp5016} />
        </div>
        <div className="sale_4_sec_content">
          <div className="sale_4_sec_heading heading_30_41_left_blk w_600">
            Powerful features designed to provide you with smooth path to sales
          </div>
          <div className="sale_4_sec_conte fs_18_h_25_left_blk">
            Stop jumping from single feature app to single feature app, and get
            everything done with Bearish. Save time, save money, increase your
            selling power
          </div>
          <div className="sale_4_sec_sm_content fs_18_h_25_left_blk">
            <span className="w_600">Unified Communication Dashboard: </span>{" "}
            Manage all your communications from a single dashboard, keeping
            conversations organized and accessible for the whole team
          </div>
          <div className="sale_4_sec_sm_content fs_18_h_25_left_blk">
            <span className="w_600">Smart Call Scheduling: </span> Schedule
            calls and meetings directly within Bearish OS, syncing with your
            calendar to avoid conflicts and double bookings
          </div>
          <div className="sale_4_sec_sm_content fs_18_h_25_left_blk">
            <span className="w_600">Template Customization: </span> Create and
            use customizable communication templates to maintain consistency and
            save time on outreach
          </div>
          <div className="sale_4_sec_sm_content fs_18_h_25_left_blk gap_4_sec">
            <span className="w_600">Interaction Tracking: </span> Keep track of
            all interactions with leads, from emails to calls, ensuring a
            complete view of the communication history
          </div>
          <div className="sale_4_sec_sm_content fs_18_h_25_left_blk gap_4_sec">
            <span className="w_600">Automated Responses: </span> Set up
            AI-driven automated responses for common queries, ensuring leads
            receive timely, relevant information
          </div>
        </div>
      </div>

      <div className="gap_5_sec heading_30_41_left_blk w_600">
        Proposal Power: Create, Send, and Track with Ease
      </div>

      <div className="gap_5_sec_content fs_18_h_25_left_blk letter_sp">
        Craft compelling proposals with integrated document management tools
        that track engagement and facilitate faster sign-off
      </div>

      <div className="sale_5_sec">
        {SalesCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="sale_6_sec">
        <div className="sale_6_sec_img">
          <img alt="img" className="fo_img" src={img5018} />
        </div>
        <div className="sale_6_content sale_6_heading">
          <div className="w_600 sale_6_heading heading_30_41_left_blk">
            Build Better Relationships, not just one off Conversations and
            interactions
          </div>
          <div className="sale_6_cont fs_18_h_25_left_blk letter_sp">
            Deepen customer connections with our integrated CRM, fostering
            loyalty and repeat business. With Bearish OS you understand every
            touchpoint, every interaction, and always have the most detailed
            information to act on in your sales calls
          </div>
          <div className="sale_6_sm_content">
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Comprehensive Customer Profiles: </span>
              Gather and store detailed customer information in one place for a
              360-degree view of your customers, enabling personalized
              engagement
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Interaction Tracking: </span>Keep a
              detailed log of all customer interactions, from emails to calls to
              meetings, ensuring you can provide tailored service at every step
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Predictive Analytics: </span>Use our CRM's
              predictive analytics to anticipate customer needs and
              opportunities for upselling or cross-selling
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Lead Conversion Tools: </span>Efficiently
              move leads through the sales funnel with tools that help score,
              qualify, and convert prospects into loyal customers
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Sales Forecasting: </span>Leverage CRM
              data to forecast sales trends and set realistic targets, adjusting
              strategies for maximum effectiveness
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec">
        <div className="sale_7_heading w_600">
          Supercharge Your Sales Success: Bearish OS Elevates Conversion Rates
          and Customer Engagement
        </div>
        <div className="rating_card_main sale_rating_crd">
          {SalesRatingCardData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="sale_8_sec">
        <div className="sale_8_img">
          <img alt="img" className="fo_img" src={grp5012} />
        </div>
        <div className="sales_5th_sec_cont">
          <div className=" sale_8_heading w_600 heading_30_41_left_blk">
            AI-Driven Sales Mastery: Unleash the Full Potential of Every Deal
            with Bearish OS
          </div>
          <div className="sale_8_content fs_18_h_25_left_blk ">
            Step into the future of sales with Bearish OS’s AI, where every
            interaction is optimized, and no opportunity is overlooked. Our AI
            delves deep into data, providing insights that redefine lead
            qualification, enhance customer understanding, and streamline the
            sales process. It’s not just about automation; it’s about augmenting
            your sales acumen with a digital advisor that’s always learning,
            always analyzing, and always working to multiply your success. With
            Bearish OS, you gain an intelligent partner that equips your team
            with predictive analytics, personalized recommendations, and
            real-time decision-making support, ensuring you’re always a step
            ahead
          </div>
          <div className="sim_9th_ico_cont">
          <div className="lk w_600">
            <a href="#" className="sim_9th_lnk">
              Get started for free now
            </a>
          </div>
          <div className="con_icon">
            <img alt="icon" className="fo_img" src={rdIcons} />
          </div>
        </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};

export default Sales;
