import React, { useState } from "react";
import "./header.css";
import logo from "../../assets/Bearish OS Logo_Orange@2x.png";
import salesIcon from "../../assets/bearishosproicon/sales2.png.png";
import marktingIcon from "../../assets/bearishosproicon/marketing2.png.png";
import proIcon from "../../assets/bearishosproicon/prdeve2.png.png";
import itIcon from "../../assets/bearishosproicon/it2.png.png";
import HrIcon from "../../assets/bearishosproicon/hr2.png.png";
import custoIcon from "../../assets/bearishosproicon/customersuccess2.png.png";
import opeIcon from "../../assets/bearishosproicon/operations2.png.png";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [hamBurgerMenu, setHamBurgerMenu] = useState(false);

  const handleToggle = () => {
    setOpen(false);
    setIsOpen(!isOpen);
  };
  const handleOpenBox = () => {
    setIsOpen(false);
    setOpen(!open);
  };
  const handleOpenHamBox = () => {
    setHamBurgerMenu(!hamBurgerMenu);
  };
  return (
    <div className="header ">
      <div className="containernew"></div>
      <div className="head_content">
        <div className="logo">
          <span>
            <img src={logo} alt="logo" className="fo_img" />
          </span>
        </div>
        <div className="bearish">
          <span className="be_logo cursor_p" onClick={() => navigate("/")}>
            Bearish OS
          </span>
        </div>
        <div className={`features ${isOpen ? "open" : ""}`}>
          <ul className="ul_content">
            <li onClick={handleToggle}>
              <button className="new_btn">
                Work OS
                <span className="toggle-icon">
                  <i
                    className={`fas ${
                      isOpen ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  ></i>
                </span>
              </button>
            </li>
            <li onClick={handleOpenBox}>
              <button className="new_btn">
                Solutions
                <span className="toggle-icon" onClick={handleOpenBox}>
                  <i
                    className={`fas ${
                      open ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  ></i>
                </span>
              </button>
            </li>
            <li>
              <button className="new_btn" onClick={() => navigate("/pricing")}>
                Pricing
              </button>
            </li>
          </ul>
          {isOpen && (
            // <div className="open_box">
            <div className={`open_box ${open ? "on-top" : ""}`}>
              <div className="sub-menu">
                <div className="bre-os">
                  <h2>Bearish OS</h2>
                  <p>
                    Streamlining <span className="pr_color">productivity</span>{" "}
                    with top-tier
                    <span className="se_color"> security</span> and
                    <span className="pri_color"> privacy</span>, all in an{" "}
                    <span className="ec_color">economical</span> , user-friendly
                    package. Experience the future of efficient, safe, and
                    cost-effective{" "}
                    <span className="bu_color"> business management.</span>
                  </p>{" "}
                  <a className="underline-link" href="">
                    Explore our Work OS
                  </a>
                </div>
                <div className="core-feature">
                  <div className="fs_18_h_25_left_blk fo_w_600">
                    Core Features
                  </div>
                  <ul className="ul_list">
                    <li className="li_data">
                      {" "}
                      <a href="/intelligent" className="link">
                        Intelligent Email Client
                      </a>
                    </li>
                    <li className="li_data">
                      {" "}
                      <a href="/communicate" className="link">
                        Centralize Communications
                      </a>
                    </li>
                    <li className="li_data">
                      {" "}
                      <a href="/relationmanager" className="link">
                        Relationship Manager
                      </a>
                    </li>
                    <li className="li_data">
                      {" "}
                      <a href="/virtual" className="link">
                        Virtual Meeting Hub
                      </a>
                    </li>
                    <li className="li_data">
                      {" "}
                      <a href="/projectplans" className="link">
                        Projects; Plan & Execute
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
          {open && (
            <div className="open_sol_box">
              <div className="sol-menu">
                <div className="fir_div">
                  <div className="why_b">Why Bearish?</div>
                  <ul className="ul_why">
                    <li className="li_da">
                      {" "}
                      <a href="/simplify" className="s_link">
                        Simplify
                      </a>
                    </li>
                    <li className="li_da">
                      {" "}
                      <a href="/replace" className="s_link">
                        Replace & Save
                      </a>
                    </li>
                    <li className="li_da">
                      {" "}
                      <a href="/privacy" className="s_link">
                        Privacy & Security
                      </a>
                    </li>
                    <li className="li_da">
                      {" "}
                      <a href="/bebaai" className="s_link">
                        BEBA AI
                      </a>
                    </li>
                    <li className="li_da">
                      {" "}
                      <a href="/tranquilai" className="s_link">
                        Tranquil AI
                      </a>
                    </li>
                    <li className="li_da">
                      {" "}
                      <a href="" className="s_link">
                        ROI Calculator
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="divi_der"></div>
                <div className="secon_div">
                  <div className="use_c">Use Cases</div>
                  <div className="li_ca">
                    <div
                      className="cases_li"
                      onClick={() => {
                        navigate("/sales");
                        setOpen(false);
                      }}
                    >
                      <span>
                        <img
                          alt=""
                          className="he_img_ico cursor_p"
                          src={salesIcon}
                        />
                      </span>
                      <span className="li_spn_da cursor_p">Sales</span>
                    </div>
                    <div
                      className="cases_li"
                      onClick={() => {
                        navigate("/marketing");
                        setOpen(false);
                      }}
                    >
                      <span>
                        <img
                          alt=""
                          src={marktingIcon}
                          className="he_img_ico cursor_p"
                        />
                      </span>
                      <span className="li_spn_da cursor_p">Marketing</span>
                    </div>
                    <div
                      className="cases_li"
                      onClick={() => {
                        navigate("/productdevelop");
                        setOpen(false);
                      }}
                    >
                      <span>
                        <img
                          alt=""
                          className="he_img_ico cursor_p"
                          src={proIcon}
                        />
                      </span>
                      <span className="li_spn_da cursor_p">
                        Product Development
                      </span>
                    </div>
                    <div
                      className="cases_li"
                      onClick={() => {
                        navigate("/customersuccess");
                        setOpen(false);
                      }}
                    >
                      <span>
                        <img
                          alt=""
                          className="he_img_ico cursor_p"
                          src={custoIcon}
                        />
                      </span>
                      <span className="li_spn_da cursor_p">
                        Customer Success
                      </span>
                    </div>
                  </div>
                </div>
                <div className="forth_div">
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/it");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={itIcon}
                      />
                    </span>

                    <span className="li_spn_da cursor_p">IT</span>
                  </div>
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/hr");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={HrIcon}
                      />
                    </span>

                    <span className="li_spn_da cursor_p">Human Resources</span>
                  </div>
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/operations");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={opeIcon}
                      />
                    </span>

                    <span className="li_spn_da cursor_p">Operations</span>
                  </div>
                </div>
              </div>

              {/* <div className="fir_div">
                <div className="why_b">Why Bearish?</div>
                <ul className="ul_why">
                  <li className="li_da">
                    {" "}
                    <a href="/simplify" className="s_link">
                      Simplify
                    </a>
                  </li>
                  <li className="li_da">
                    {" "}
                    <a href="/replace" className="s_link">
                      Replace & Save
                    </a>
                  </li>
                  <li className="li_da">
                    {" "}
                    <a href="/privacy" className="s_link">
                      Privacy & Security
                    </a>
                  </li>
                  <li className="li_da">
                    {" "}
                    <a href="/bebaai" className="s_link">
                      BEBA AI
                    </a>
                  </li>
                  <li className="li_da">
                    {" "}
                    <a href="/tranquilai" className="s_link">
                      Tranquil AI
                    </a>
                  </li>
                  <li className="li_da">
                    {" "}
                    <a href="" className="s_link">
                      ROI Calculator
                    </a>
                  </li>
                </ul>
              </div> */}
              {/* <div className="divi_der"></div> */}
              {/* <div className="secon_div">
                <div className="use_c">Use Cases</div>
                <div className="li_ca">
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/sales");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={salesIcon}
                      />
                    </span>
                    <span className="li_spn_da cursor_p">Sales</span>
                  </div>
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/marketing");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        src={marktingIcon}
                        className="he_img_ico cursor_p"
                      />
                    </span>
                    <span className="li_spn_da cursor_p">Marketing</span>
                  </div>
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/productdevelop");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={proIcon}
                      />
                    </span>
                    <span className="li_spn_da cursor_p">
                      Product Development
                    </span>
                  </div>
                  <div
                    className="cases_li"
                    onClick={() => {
                      navigate("/customersuccess");
                      setOpen(false);
                    }}
                  >
                    <span>
                      <img
                        alt=""
                        className="he_img_ico cursor_p"
                        src={custoIcon}
                      />
                    </span>
                    <span className="li_spn_da cursor_p">Customer Success</span>
                  </div>
                </div>
              </div>
              <div className="forth_div">
                <div
                  className="cases_li"
                  onClick={() => {
                    navigate("/it");
                    setOpen(false);
                  }}
                >
                  <span>
                    <img alt="" className="he_img_ico cursor_p" src={itIcon} />
                  </span>

                  <span className="li_spn_da cursor_p">IT</span>
                </div>
                <div
                  className="cases_li"
                  onClick={() => {
                    navigate("/hr");
                    setOpen(false);
                  }}
                >
                  <span>
                    <img alt="" className="he_img_ico cursor_p" src={HrIcon} />
                  </span>

                  <span className="li_spn_da cursor_p">Human Resources</span>
                </div>
                <div
                  className="cases_li"
                  onClick={() => {
                    navigate("/operations");
                    setOpen(false);
                  }}
                >
                  <span>
                    <img alt="" className="he_img_ico cursor_p" src={opeIcon} />
                  </span>

                  <span className="li_spn_da cursor_p">Operations</span>
                </div>
              </div> */}
            </div>
          )}
        </div>
        <div className="btn">
          <button className="re_btn_new">
            <Link
              to="https://app.bearishos.com/signup"
              className="re_btn footer_nav"
              target="_blank"
            >
              Start Free Now
            </Link>
          </button>
        </div>
        <div className="hamburger-menu" onClick={handleOpenHamBox}>
          <i className="fas fa-bars"></i>
        </div>
        {hamBurgerMenu && <div className="new_ham_box">
          <div className="new_ham_lis">Work OS</div>
          <div className="new_ham_lis">Solutions</div>
          <div className="new_ham_lis">Pricing</div>
          <div className="new_ham_lis">
            <Link
              to="https://app.bearishos.com/signup"
              className=" footer_nav hr_heding"
              target="_blank"
            >
              Start Free Now
            </Link>
          </div>
          </div>}
      </div>
    </div>
  );
};

export default Header;
