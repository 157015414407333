import brainIcon from "../../assets/virtualmeetingicon/brain2.png";
import connectionIcon from "../../assets/virtualmeetingicon/connection2.png";
import fileIcon from "../../assets/virtualmeetingicon/file2.png";
import recorderIcon from "../../assets/virtualmeetingicon/recorder2.png";
const SecondSection = () => {
  return (
    <div className="vt_second_sec">
      <div className="vt_sec_heading">Discover the Bearish Difference</div>
      <div className="vt_sec_content ">
        Bearish Comes with built in features to help you meet better,
        communicate smoothly, and never miss a beat.
      </div>

      <div className="vt_sec_row">
        <img alt="icon" className="vt_sec_fi_ico" src={brainIcon} />
        <div className="vt_sec_ro_ele">Full Call Transcriptions</div>
        <img alt="icon" className="vt_sec_fi_icon" src={fileIcon} />
        <div className="vt_sec_ro_ele">After Call Summaries</div>
      </div>
      <div className="vt_cont_sec">
        <div className="vt_cont_sec_1 line_h_34">
          Powered by our Open Source AI you get near instant transcriptions of{" "}
          <br /> every call you make
        </div>
        <div className="vt_cont_sec_2 line_h_34">
          Built in call summaries means you never forget the most important
          details from every call
        </div>
      </div>
      <div className="vt_sec_2row">
        <img alt="icon" className="vt_sec_fi_ico" src={recorderIcon} />
        <div className="vt_sec_ro_ele">Endless Call Recordings</div>
        <img alt="icon" className="vt_sec_fi_icon" src={connectionIcon} />
        <div className="vt_sec_ro_ele vt_width">
          Built in Relationship Manager
        </div>
      </div>
      <div className="vt_cont_sec">
        <div className="vt_cont_sec_1 line_h_34">
          Stop paying extra for storage with our endless video recordings
          storage options all included
        </div>
        <div className="vt_cont_sec_2 line_h_34s">
          Interconnected to your Email, Messaging, Calendar, Tasks & CRM means
          you never forget a past touchpoint
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
