import arrowIcon from "../../assets/roundIcons/Group 4931@2x.png"
import contentImage from "../../assets/Group 4928@2x.png"
import iconGdpr from "../../assets/home/Screenshot 2024-02-13 at 7.00.50 PM@2x.png";
import iconUnknown from "../../assets/home/Unknown@2x.png";
import iconpci from "../../assets/home/50cc9a1181c9bb424ade9b8da0f928d6@2x.png";
const NewContent = () => {
  return (
    <div className="new_content">
      <div className="main_con">
        <div className="con_heading heading_30_41_left_blk w_600">
          Privacy First: Secure by Design – Your Trust, Our Promise
        </div>
        <div className="con_cont fs_18_h_25_left_blk" >
          Our platform prioritizes your privacy, embedding top-tier security in
          every aspect. Trust in a system where your data is protected and
          confidentiality is paramount
        </div>
        <div className="con_link">
          <div className="_link">
            <a className="c_black w_600" href="">
              Understand our Privacy Promise
            </a>
          </div>
          <div className="con_icon"><img className="img_w100_h100"  alt="" src={arrowIcon}/></div>
        </div>
        <div className="dis_gp_55">
          <img alt="icon" className="new_icons" src={iconGdpr}/>
          <img alt="icon" className="new_icons" src={iconUnknown}/>
          <img alt="icon" className="new_icons"src={iconpci}/>
        </div>
      </div>
      <div className="con_img"><img className="img_w100_h100" src={contentImage} alt=""/></div>
    </div>
  );
};

export default NewContent;