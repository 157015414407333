import firstImg from "../../assets/debora-pilati-dOG0z4-gqp0-unsplash@2x.png";
import secondImg from "../../assets/ally-griffin-VD7cu7bKHmk-unsplash@2x.png";
import thirdImg from "../../assets/jean-philippe-delberghe-75xPHEQBmvA-unsplash@2x.png";
import fourthImg from "../../assets/andrew-kliatskyi-DQorphiX7oE-unsplash@2x.png";
import bottom1Img from "../../assets/Group 4856@2x.png";
import bottom2Img from "../../assets/Group 4857@2x.png";
import bottom3Img from "../../assets/Group 4858@2x.png";
import bottom4Img from "../../assets/Group 4859@2x.png";
const ServiceCardData = [
  {
    header: "Unify Your Digital Workspace",
    btnContent: "Learn about intelligent connections",
    imgUrl: firstImg,
    btImg: bottom1Img,
    content:
      "Experience a workspace where everything connects seamlessly. Bearish OS intelligently links your tasks and tools, transforming how you work and collaborate",
  },
  {
    header: "Maximize Savings, Minimize Spend",
    btnContent: "Learn how to switch and save",
    imgUrl: secondImg,
    btImg: bottom2Img,

    content:
      "Embrace the Bearish OS advantage and watch your costs plummet. Averaging $3,000 in monthly savings per user, our platform turns efficiency into economy",
  },
  {
    header: "Privacy at the Forefront",
    btnContent: "Understand why Privacy-First is key",
    imgUrl: thirdImg,
    btImg: bottom3Img,

    content:
      "At Bearish OS, privacy isn’t an afterthought; it’s our foundation. Our Work OS is designed with a privacy first approach, ensuring your data remains secure and confidential.",
  },
  {
    header: "AI-Driven Workday",
    btnContent: "Get to know our Open Source AI",
    imgUrl: fourthImg,
    btImg: bottom4Img,

    content:
      "Enhance your workflow with Bearish OS’s AI. Effortlessly manage emails, calendars, and create insightful text with our open-source AI, revolutionizing your daily tasks",
  },
];

export default ServiceCardData;
