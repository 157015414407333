import arrowIcon from "../../assets/roundIcons/Light 1px-arrow_backward.png";

const PlatFormCard = ({ data, i }) => {

  return (
    <div className="crd" key={i}>
      <div className="be_connect">{data?.heading}</div>
      <div className="be_heading w_600">{data?.mainHeading}</div>
      <div className="be_content">{data?.content}</div>
      <div className="be_icon_det">
        <div className="ic_det">
          <div
            className={`ico ${
              data?.heading === "Bearish Connect"
                ? "blueBackIcon"
                : data?.heading === "Bearish CRM & Task Managent"
                ? "greenBackIcon"
                : ""
            }`}
          >
            <img src={data?.icon1} alt="" className="icon_img" />
          </div>
          <div className="de">{data?.iconLable1}</div>
        </div>
        <div className="ic_det">
          <div
            className={`ico ${
              data?.heading === "Bearish Connect"
                ? "blueBackIcon"
                : data?.heading === "Bearish CRM & Task Managent"
                ? "greenBackIcon"
                : ""
            }`}
          >
            <img src={data?.icon2} alt="" className="icon_img" />
          </div>
          <div className="de">{data?.iconLable2}</div>
        </div>
        <div className="ic_det">
          <div
            className={`ico ${
              data?.heading === "Bearish Connect"
                ? "blueBackIcon"
                : data?.heading === "Bearish CRM & Task Managent"
                ? "greenBackIcon"
                : ""
            }`}
          >
            <img src={data?.icon3} alt="" className="icon_img" />
          </div>
          <div className="de">{data?.iconLable3}</div>
        </div>
      </div>
      <div className="learn">
        <div>
          {" "}
          <a href="" style={{ color: "black" }}>
            Learn More
          </a>
        </div>
        <div>
          <img alt="" src={arrowIcon} />
        </div>
      </div>
      <div className="screen_shot" style={{ border: data?.borderColor }}>
     <img src={data?.screenShot} alt="img" className="fo_img"/>
      </div>
    </div>
  );
};
export default PlatFormCard;
