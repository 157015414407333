import FirstSection from "../../components/virtualMeetingHub/firstSection";
import "./virtualMeeting.css";

import WorkSimpler from "../../components/worksimpler/workSimpler";
import SecondSection from "../../components/virtualMeetingHub/secondSection";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import VertualCardData from "../../components/virtualMeetingHub/virtualCardData";
import MeetSection from "../../components/virtualMeetingHub/meetSection";
import RatingSection from "../../components/virtualMeetingHub/RatingSection";
import RatingData from "../../components/virtualMeetingHub/ratingData";
import img4962 from "../../assets/virtualmeetingicon/Group 4962@2x.png";
import img4967 from "../../assets/virtualmeetingicon/Group 4967@2x.png";
import rtIcon from "../../assets/roundIcons/Group 4931@2x.png";
import { useEffect } from "react";
const VirtualMeetingHub = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="vt_mt_hb_main">
        <FirstSection />
        <SecondSection />
        <div className="md_remote w_600">Built for the Modern Remote Team</div>
        <div className="vt_par_crd_div">
          {VertualCardData?.map((data, i) => (
            <CardSection data={data} i={i} />
          ))}
        </div>
        <MeetSection />
        <div className="vt_ratings">
          <div className="vt_rating_content">
            Customers who use Bearish report higher productivity,
            <br /> a lower meeting time, and enhanced engagement
          </div>
          <div className="rating_card_main">
            {RatingData?.map((data, i) => (
              <RatingSection data={data} i={i} />
            ))}
          </div>
        </div>
        <div className="vt_suffling">
          <img className="vt_suff_img" src={img4962} alt="img" />
          <div className="vt_suff_main">
            <div className="vt_suff_header w_600">
              Stop shuffling between meeting and note apps, and start jotting
              faster
            </div>
            <div className="vt_stuff_content ft_18_l_ht_25">
              With Bearish OS, every meeting comes with pre and during meeting
              note <br /> pads. Never lose your train of thought again, and keep
              track of all the <br /> little things before and during your
              meetings. Easily share your <br /> notes before, during, or after
              your call
            </div>
            <div className="vt_sec_stuff ft_18_l_ht_25">
              Powered by Open Source AI you automatically have access to quick
              bullet points and key words with ease
            </div>
          </div>
        </div>
        <div className="vt_wt_board">
          <div className= "vt_new_wt">
            <div className="vt_wt_cont_header">
              Whiteboards built with collaboration and teams in mind
            </div>
            <div className="vt_wt_cont ft_18_l_ht_25">
              Every meeting comes with whiteboards pre-installed. Type, write,
              design, and build your perfect boards together
            </div>
            <div className="vt_wt_link w_600 ">
              <a className="vt_link_tag" href="#">
                Get started with whiteboards free now
              </a>
              <img className="con_icon" alt="icon" src={rtIcon} />
            </div>
          </div>
          <img className="vt_wt_img" src={img4967} alt="img" />
        </div>
        {/* <WorkSimpler /> */}
      </div>
    </div>
  );
};

export default VirtualMeetingHub;
