const SecondPriceData = [
  {
    backGround_color: "#9556CB",
    border_d: "3px solid #9556CB",
    top_heading: "Projects: Plan & Execute",
    price_a: "$3.99",
    top_content:
      "Empower your team with easy to use task management, analytics, and screen recording",
    new_contents: [
      "Unlimited Lists, Checklists, Boards",
      "Unlimited Docs, Wikis, Decks & Grids",
      "Rich Comments and Analytics",
      "Unlimited Free Guests",
      "Limited Screen Recording",
    ],
    bottom_data:
      "Can be added to any Bearish OS plan or be purchased independently for unlimited Plan, Track & Execute with limited storage",
  },
  {
    backGround_color: "#FF0000",
    border_d: "3px solid #FF0000",
    top_heading: "BEBA AI",
    price_a: "$15.00",
    top_content:
      "The first privacy first Open Source AI built to empower and supercharge your team",
    new_contents: [
      "Generate Text and Images",
      "Summarize and Consolidate Text",
      "Analyze and Understand Text",
      "Chat with AI, PDFs, Files, and Bearish OS",
      "More features added weekly…",
    ],
    bottom_data:
      "BEBA AI can be purchased as an add on to most plans or can be purchased to connect with your existing Tech stack",
  },
  {
    backGround_color: "#3977CB",
    border_d: "3px solid #3977CB",
    top_heading: "Smart Cloud & Analytics",
    price_a: "$8.99",
    top_content:
      "Empowered Document and Cloud Storage with analytics, trackable links, media management & more…",
    new_contents: [
      "100 GB of Premium Cloud Storage",
      "Link Tracking and Asset Analytics",
      "Media and Content Manager",
      "Robust Automatic AI Asset Tagging",
      "Universal Asset Search",
    ],

    bottom_data:
      "Powerful Cloud Storage, Document Analytics, Media and Content Manager with universal search and automatic tagging",
  },
];

export default SecondPriceData;
