import "./hr.css";
import img4_13 from "../../assets/BearishOSHR/Screenshot 2024-02-02 at 4.13.27 PM@2x.png";
import hrimg4886 from "../../assets/BearishOSHR/Group 4886@2x.png";
import hrimg5020 from "../../assets/BearishOSHR/Group 5020@2x.png";
import hrimg5016 from "../../assets/BearishOSHR/Group 5016@2x.png";
import hrimg5018 from "../../assets/BearishOSHR/Group 5018@2x.png";
import hrimg5012 from "../../assets/BearishOSHR/Group 5012@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import HRCardData from "../../components/hr/hrCrdData";
import RatingCardSection from "../../components/common/ratingCardSection";
import HRRatingData from "../../components/hr/hrRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const HR = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main hr_main">
        {" "}
        <div className="sale_1_sec">
          <div className="hr_heding top_heading w_600 ">
            Revolutionizing HR: Unlock Efficiency and Engagement with Bearish OS
          </div>
          <div className="top_content pr_mn_content  hr_content">
            Bearish OS brings transformational tools to your HR department,
            simplifying time tracking, document management, and employee
            engagement through built-in surveys. Our platform is designed to
            streamline HR workflows, enhance employee satisfaction, and foster a
            productive workplace culture. While we are excited about the
            upcoming features like payment processing currently in alpha,
            Bearish OS already offers comprehensive solutions to manage your HR
            tasks more efficiently. From onboarding to performance reviews,
            Bearish OS provides the tools you need to support your team’s growth
            and success
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="cs_1_img">
          <img className="hr_head_img" alt="img" src={img4_13} />
        </div>{" "}
      </div>

      <div className="sale_2_sec pr_mn_1_mrgin_bt cs_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={hrimg4886} />
        </div>
        <div className="sale_2_sec_cont hr_new_wid_50">
          <div className="hr_1_heading letter_sp heading_30_41_left_blk w_600">
            Harness Employee Insights: Drive Engagement with Targeted Surveys{" "}
          </div>
          <div className="hr_1_content fs_18_h_25_left_blk letter_sp">
            Uncover valuable insights into employee satisfaction and engagement
            with Bearish OS’s built-in survey tools. Craft, distribute, and
            analyze surveys to inform better HR strategies and workplace
            improvements
          </div>
          <div className="sal_2_sec it_2_gap hr_1_contt">
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk hr_w_607 letter_sp">
                <span className="w_600">Customizable Surveys: </span> Easily
                create surveys tailored to your organization’s specific needs
                and goals
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk  hr_w_607 letter_sp">
                <span className="w_600">Anonymous Feedback Options: </span>
                Encourage honest feedback by offering options for anonymity
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk  hr_w_607 letter_sp ">
                <span className="w_600">Actionable Analytics: </span>
                Analyze survey results with built-in analytics to make informed
                decisions on enhancing workplace culture
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec pr_mn_gap hr_3_gap">
        <div className="pas_5th_sec_cont">
          <div className=" heading_30_41_left_blk w_600 letter_sp it_2_heADING hr_4_heading">
            Precise Time Management: Simplify Tracking and Attendance
          </div>
          <div className="it_2_content fs_18_h_25_left_blk letter_sp hr_4_content">
            Optimize your workforce management with Bearish OS’s comprehensive
            time tracking and attendance system. Designed for ease and accuracy,
            our tools help you monitor work hours, manage leave, and ensure
            compliance
          </div>
          <div className=" fs_18_h_25_left_blk letter_sp">
            <span className="w_600">Automated Time Logging: </span>
            Enable employees to log hours with ease, reducing discrepancies and
            ensuring accurate payroll processing
          </div>
          <div className=" fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Leave and Absence Management: </span>
            Streamline leave requests and approvals,
       keeping track of all types of absence in one place
          </div>
          <div className=" fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Real-Time Attendance Insights: </span>
            Gain instant visibility into attendance patterns, helping to manage
            staffing needs proactively
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Understand our Time Tracking & Privacy Features
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pr_mn_3_img">
          <img alt="img" className="fo_img" src={hrimg5020} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={hrimg5016} />
        </div>
        <div className="sal_2_sec hr_new_wid_50">
          <div className="hr_5_heading letter_sp heading_30_41_left_blk w_600">
            Effortless Document Control: Secure and Organize HR Documents
          </div>
          <div className="hr_5_content fs_18_h_25_left_blk letter_sp">
            Manage all your HR documents securely and efficiently with Bearish
            OS. From contracts to policies, our document management system
            ensures easy access, organization, and compliance
          </div>
          <div className="hr_5_conten sal_2_sec it_2_gap ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Centralized Storage: </span>
              Keep HR documents in one secure, accessible location to ensure
              compliance and ease of access
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Version Control: </span>
              Ensure everyone is working with the most up-to-date documents,
              minimizing confusion and ensuring your team always has the
              documents to support them
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Access Permissions: </span>Control who can
              view, edit, or share documents, protecting sensitive information
              and creating an always on HR status for your team
            </div>
          </div>
        </div>
      </div>

      <div className="heading_30_41_left_blk w_600 letter_sp hr_6_heading">
        Future-Ready Finance: Streamlining Payments with Innovation For Full,
        Part & Independent Employees
      </div>

      <div className="sale_5_sec pr_mn_marg_bt">
        {HRCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec cs_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={hrimg5018} className="fo_img" />
        </div>
        <div className="pas_5th_sec_cont pr_mn_7_content_new">
          <div className="hr_7_heading heading_30_41_left_blk w_600 letter_sp">
            Empower with Knowledge: Centralized Hubs for Seamless Information
            Access{" "}
          </div>
          <div className="hr_7_content fs_18_h_25_left_blk letter_sp">
            Unlock the full potential of your workforce with Bearish OS’s
            Knowledge Hubs and Directories. Designed to centralize and
            democratize access to critical company information, these hubs serve
            as a one-stop resource for employees seeking knowledge, guidance,
            and connection. Coupled with comprehensive company and team
            directories,Bearish OS ensures that every team member has the
            information
            they need at their fingertips, tailored to support diverse time
            zones and work schedules
          </div>
          <div className="mark_6_cont hr_7_cont">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Interactive Knowledge Hubs: </span>
              Create and curate rich knowledge bases where employees can easily
              find policies, procedures, and best practices
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">
                Dynamic Company and Team Directories:{" "}
              </span>
              Access up-to-date directories with detailed profiles, including
              expertise, contact information, and time zone availability,
              fostering stronger connections and collaboration
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600"> Time Zone Synchronization: </span>
              Navigate global teams effortlessly with features that display
              real-time local hours for colleagues, eliminating the confusion of
              scheduling across different time zones
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec hr_7_bg">
        <div className="mrk_7_heding sale_7_heading w_600 letter_sp">
          Proven Outcomes: Elevating Customer Success with Every Interaction
          through Bearish OS
        </div>
        <div className="rating_card_main sale_rating_crd">
          {HRRatingData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={hrimg5012} />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="it_7_heading heading_30_41_left_blk w_600 letter_sp">
            AI-Enhanced Development: Crafting the Future with Intelligent
            Insights
          </div>
          <div className="it_7_content  fs_18_h_25_left_blk mrk_8_content_he">
            Embrace the cutting edge of development with Bearish OS’s AI
            capabilities. Our platform leverages artificial intelligence to
            revolutionize the way IT professionals and developers work, from
            automated code optimization to predictive project analytics. Bearish
            OS’s AI tools are designed to augment your development process,
            providing smart suggestions, enhancing code quality, and forecasting
            project timelines with unparalleled accuracy. Step into a new era of
            development where AI drives innovation, efficiency, and success
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
              Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};

export default HR;
