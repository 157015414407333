import Slider from "react-slick";
import "./slider.css";
import ImageData from "./imageData";

const ContentSlider = () => {
  const settings = {
    // className: "slider variable-width",
    // infinite: true,
    // dots: true,
    // centerPadding: "60px",
    // slidesToShow: 5,
    // autoplay: true,
    // touchThreshold:100,
    // draggable:true,
    // swipeToSlide: true,
    // afterChange: function (index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // },
    // dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 1500,
  };

  return (
    <div className="slide">
      <Slider {...settings}>
        {ImageData?.map((image, i) => (
          <div className="tab_width" key={i}>
            <img src={image.imageData} alt="" className="slide_img" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ContentSlider;
