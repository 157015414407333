import { Link } from "react-router-dom";
import img5_49 from "../../assets/BearishSimplify/Screenshot 2024-01-24 at 5.49.04 PM@2x.png";

const FirstSectionOfReplace = () => {
  return (
    <div className="rep_sa_main">
      <div className="rep_cont">
        <div className="rep_heading color_wh w_600">
          Maximize Efficiency, Minimize Expenses: Replace Multiple Tools with
          One Comprehensive Solution
         and Watch Your Savings Grow
        </div>
        <div className="color_wh rep_content">
          Revolutionize your operations with Bearish OS, merging essential
          business tools in
           one unified platform. Cut costs by replacing multiple apps with
          our comprehensive
           solution, simplifying your tech and boosting efficiency. Save
          smartly, work smarter, 
          and drive growth with Bearish OS
        </div>
        <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
        </button>
      </div>
      <div className="sim_img cm_box">
        <img alt="img" className="sim_img_w" src={img5_49} />
      </div>
    </div>
  );
};
export default FirstSectionOfReplace;
