import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4999 from "../../assets/BearishPrivacyandSecurity/Group 4999@2x.png";
import grp4998 from "../../assets/BearishPrivacyandSecurity/Group 4998@2x.png";
import Img5_59 from "../../assets/BearishPrivacyandSecurity/Screenshot 2023-05-18 at 5.59.05 PM@2x.png";
import Img5_58 from "../../assets/BearishPrivacyandSecurity/Screenshot 2023-05-18 at 5.58.49 PM@2x.png";
import Img13 from "../../assets/BearishPrivacyandSecurity/Image 13@2x.png";

const PrivacyContent = [
  {
    backgroundImg: grp4947,
    frontImg: Img5_59,
    heading: "Multi-Factor Authentication",
    content:
      "With so much sensitive data in Bearish OS we don’t take chances. Any time you login you’ll need your 2nd factor code.",
  },
  {
    backgroundImg: grp4998,
    frontImg: Img5_58,
    heading: "Understand Every Sign-On",
    content:
      "Every sign-on is important and we vigorously monitor them to ensure you know when something looks wrong",
  },
  {
    backgroundImg: grp4999,
    frontImg: Img13,
    heading: "Guests Controls",
    content:
      "Enjoy your privacy and ensure your guests have access to all the data they need to get things done without compromise",
  },
];
export default PrivacyContent;