import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4970 from "../../assets/Bearishrelationshipmanagerimg/Group 4970@2x.png";
import grp4969 from "../../assets/Bearishrelationshipmanagerimg/Group 4969@2x.png";
import Img4968 from "../../assets/RelationshipManager1/Image 12@2x.png";
import Imgf59 from "../../assets/RelationshipManager1/Image 13@2x.png";
import Img859 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 6.10.13 PM@2x.png";
const RelationShipCardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4968,
    heading: "Access Control for All",
    content:
      "Use private internal sharing and actions to have fined grained access control over all your content",
  },
  {
    backgroundImg: grp4969,
    frontImg: Imgf59,
    heading: "Unlimited Guest Views",
    content:
      "Don’t get trapped behind pay walls and seat limits, send unlimited guest access to view and comment on everything",
  },
  {
    backgroundImg: grp4970,
    frontImg: Img859,
    heading: "Rich Real Time Comments",
    content:
      "Don’t guess what people are saying with rich real time comments you can be bold and react with text, audio or video",
  },
];
export default RelationShipCardData;
