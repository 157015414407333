import { Link } from "react-router-dom";
import screenShot from "../../assets/Bearishrelationshipmanagerimg/Screenshot 2024-01-22 at 3.43.02 PM@2x.png";

const FirstSectionOfRelationManager = () => {
  return (
    <div className="rel_main">
      <div className="rel_cont">
        <div className="rel_header w_600">
          Redefine Seamless Business Interaction: 
          With Intelligent Connections, Never Miss a 
          Beat – All Your Essentials Unified,
                  Right Where You Need Them
        </div>
        <div className="rel_content">
          Discover the future of customer relationship management where every
          interaction is enhanced by smart technology. Our platform intuitively
          syncs all your tools and communications, ensuring you’re always on top
          of your game. Effortlessly manage, analyze, and grow your business
          relationships, all in one integrated, user-friendly space
        </div>
        <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
        </button>
      </div>
      <div>
        <img className="rel_img" alt="img" src={screenShot} />
      </div>
    </div>
  );
};

export default FirstSectionOfRelationManager;
