import "./privacyAndSecurity.css";
import img6_44 from "../../assets/BearishPrivacyandSecurity/Screenshot 2024-01-30 at 6.44.15 PM@2x.png";
import img4886 from "../../assets/BearishPrivacyandSecurity/Group 4886@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import PrivacyContent from "../../components/privacyAndSecurity/privacyContent";
import img4992 from "../../assets/BearishPrivacyandSecurity/Group 4992@2x.png";
import img4977 from "../../assets/BearishPrivacyandSecurity/Group 4977@2x.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import PrAndSeLastContent from "../../components/privacyAndSecurity/prAndSeLastContent";
import { Link } from "react-router-dom";
const PrivacyAndSecurity = () => {
  const [rtCards, setRtCards] = useState("rt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="pas_main">
        <div className="pas_1st_sec">
          <div className="pas_1st_heading w_600">
            Unwavering Commitment to Confidentiality:
           Your Trust, Our Privacy Promise, and a Rigorous Security
            Policy for Unmatched Protection
          </div>
          <div className="pas_1st_content">
            Bearish OS takes your privacy and security seriously. Our Privacy
            Promise ensures
             that your data remains yours – always. Coupled with a
            rigorous security policy, we
            set the standard for data protection. From advanced encryption to
            regular security
           audits, we go the extra mile to safeguard your information
            against ever-evolving 
            threats, giving you peace of mind to focus on what you do best
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="sim_img cm_box">
          <img alt="img" className="sim_img_w" src={img6_44} />
        </div>
      </div>

      <div className="pas_2nd_sec">
        <div className="pas_2nd_rt_sec">
          <div className="pas_2nd_sec_heading heading_30_41_left_blk w_600">
            Transparent Trust: Our Open-Book Privacy Practices Put You in
            Control
          </div>
          <div className="fs_18_h_25_left_blk">
            At Bearish OS, transparency forms the cornerstone of our
            relationship with
           users. Our open-book privacy practices are designed to put
            you in full control
            of your data. You’ll have clear insights into how your
            information is used,
            with the ability to manage permissions and privacy settings
            to suit your
             preferences. We believe in empowering our users with straight
            forward
            privacy options, ensuring that your data is used only in ways
            <br /> you approve of.
          </div>
          <div className="lnk_sim gap_20">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Read Our Full Privacy Policy
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pas_2nd_sec_img">
          <img alt="img" className="fo_img" src={img4886} />
        </div>
      </div>

      <div className="heading_30_41_left_blk w_600 pas_3rd_heading">
        Privacy Prioritized: Navigate, Manage, and Secure Your Data with
        Confidence
      </div>

      <div className="crd_relation pas_bottom">
        {PrivacyContent?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>

      <div className="pas_5th_sec">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={img4992} />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="pas_5th_sec_heading heading_30_41_left_blk w_600">
            Intelligent Without Intrusion: Our Open-Source AI Respects Your Data
            Privacy
          </div>
          <div className="pas_5th_sec_content fs_18_h_25_left_blk">
            Experience the cutting-edge capabilities of our Open Source AI,
            designed to deliver 
            powerful insights while upholding your privacy. Crafted with our
            proprietary data
           blends, it functions without ever learning from or retaining
            your personal information. You have the ultimate control – decide
            what our AI knows and when it forgets.
           
            Bearish OS ensures that your data is not a commodity; it’s
            protected, with your 
            confidentiality at the forefront of our innovation
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Read Our Responsible AI Paper
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      <div className="heading_30_41_left_blk pas_6th_sec_heading w_600">
        Secure by Design: Experience the Robust Defense of Our Advanced Security
        Protocols
      </div>

      <div className="pas_7th_section">
      <div className="rep_7th_ico">
          {PrAndSeLastContent?.map((data, i) => (
            <div className="rep_7th_icon" key={i}>
              <div className="con_icon">
                <img className="fo_img" alt="icon" src={data?.icon} />
              </div>
              <div className="fs_18_h_25_left_blk rep_7th_icon_cont pas_7th_sect_c">
                <span className="w_600">{data?.boldContent}</span>
                {data?.content}
              </div>
            </div>
          ))}
        </div>
        <div className="pas_7th_sect_img">
          <img alt="img" src={img4977} className="fo_img" />
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};
export default PrivacyAndSecurity;
