import "./bebaAi.css";
import img6_44_45 from "../../assets/BearishOSBEBAAI/Screenshot 2024-01-30 at 6.44.45 PM@2x.png";
import img7_14_09 from "../../assets/BearishOSBEBAAI/Screenshot 2023-05-18 at 7.14.09 PM@2x.png";
import icon4887 from "../../assets/Bearishrelationshipmanagerimg/Group 4887@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import BebaAICardData from "../../components/bebaAI/bebaAICrdData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import BebaAIThirdSection from "../../components/bebaAI/thirdSection";
import { Link } from "react-router-dom";
const BebaAI = () => {
  const [rtCards, setRtCards] = useState("rt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="beba_main">
        <div className="pas_1st_sec gap beba_w_c ">
          <div className="pas_1st_heading w_600 colo beba_w_c ">
            Intelligent Innovation with BEBA AI: Revolutionize Your Workflow
            with Smarter Coordination and Enhanced Security
          </div>
          <div className="pas_1st_content colo beba_w_con ">
            BEBA AI is at the heart of Bearish OS, a sophisticated intelligence
            designed to streamline your project management, elevate your
            presentations, and fortify
           your document and asset security. This smart system learns
            from our proprietary
        data—not yours—ensuring your private information stays
            private. BEBA AI is your silent partner, enhancing productivity
            without ever compromising your trust. With 
            its deep understanding of operational needs, BEBA AI proactively
            suggests optimizations, safeguards data with the utmost privacy, and
            integrates seamlessly
           into your daily workflow, setting a new benchmark for
            intelligent business solutions
          </div>
          <div>
            <button className="re_btn">
            <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
            </button>
          </div>
        </div>
        <div className="sim_img cm_box">
          <img alt="img" className="sim_img_w" src={img6_44_45} />
        </div>
      </div>

      <div className="beba_2nd_sect">
        <div className="new_be_width">
          <div className="heading_30_41_left_blk rep_heading_ w_600 beba_sec_c_heading">
            Smart Automation: Elevate Efficiency with BEBA AI’s Proactive Task
            Management
          </div>
          <div className="fs_18_h_25_left_blk rep_content_ beba_sec_content">
            BEBA AI elevates task management to new heights, automating routine
            activities and intelligently optimizing workflows for peak
            efficiency. It integrates seamlessly into your daily operations,
            prioritizing tasks, setting reminders, and offering actionable
            insights to boost productivity. Embrace a smarter workday with BEBA
            AI’s proactive, efficiency-driving technology, transforming your
            business operations
          </div>
          <div className="lnk_sim">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Let’s get to work now
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={icon4887} />
            </div>
          </div>
        </div>
        <div className="beba_2nd_sect_img">
          <img className=" beba_img" alt="img" src={img7_14_09} />
        </div>
      </div>

      <BebaAIThirdSection />

      <div className="beba_4rd_section_heading heading_30_41_left_blk w_600">
        Advanced Interaction with BEBA AI: Transform Your Communication and
        Workflow
      </div>

      <div className="crd_relation beba_mg_btm">
        {BebaAICardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>

      <div className="beba_5th_section_ ">
        <div className="beba_5th_section_div">
          <div className="heading_30_41_left_blk w_600 beba_5th_section_heading letter_sp ">
            Open Source, Infinite Possibilities: Explore the Transparent Power
            of BEBA AI
          </div>
          <div className="fs_18_h_25_left_blk beba_5th_section_content">
            Dive into the world of Open Source with BEBA AI, where transparency
            meets innovation. Our AI’s open-source nature not only fosters trust
            but also invites continual improvement and community collaboration.
            By leveraging a system built on collective intelligence, BEBA AI
            offers cutting-edge solutions that evolve with the latest
            technological advancements.
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Check out our Open Source AI Repo
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>

        <div className="beba_5th_section_back">
          <div className="beba_5th_section_link">
            <div>curl https://api.bearishos.com/v1/TranquilAI/</div>
            <div>{"type"} \</div>
            <div>-H 'Content-Type: application/json' </div>
            <div>-H 'Authorization: Bearer USER_API_KEY' </div>
            <div>-d '" </div>
            <div>"model": “beba-tranquil-{"name"}”,</div>
            <div>“input”: "role": "user", "content":</div>
            <div>“response”'</div>
          </div>
        </div>
      </div>

      <div className="heading_30_41_left_blk w_600 beba_6_heading">
        Future-Ready Adaptability: BEBA AI’s Commitment to Evolving with Your
        Needs
      </div>

      <div className="beba_6_content fs_18_h_25_left_blk letter_sp">
        BEBA AI is not just a solution for today but a partner for tomorrow.
        Designed with the future in mind, it continuously adapts and evolves to
        meet emerging business challenges and technological advancements. Its
        architecture is built to scale with your growth, ensuring that as your
        business evolves, so does your AI support. From adapting to new market
        trends to integrating the latest tech breakthroughs, BEBA AI is your
        ally in a rapidly changing world. Embrace an AI that grows with you,
        always keeping you a step ahead
      </div>

      <div className="beba_lst"></div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default BebaAI;
