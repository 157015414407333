import img5010 from "../../assets/BearishOSTranquilAI/Group 5010@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";

const TraquilAIThirdSection = ()=>{
    return(<div className="tranq_3_sec">
    <div className="new_tr_a">
      <div className="heading_30_41_left_blk w_600 tranq_3_sec_heading ">
        Intelligent Workload Management: Achieve Perfect Balance with
        Tranquil AI
      </div>
      <div className="fs_18_h_25_left_blk tranq_3_sec_content  ">
        Tranquil AI smartly distributes workloads to prevent burnout. It
        analyzes work patterns and suggests optimal schedules, ensuring a
        healthy balance between productivity and well-bein
      </div>
      <div className="fs_18_h_25_left_blk  new_wid_66 tranq_3_sec_w">
        <span className="w_600">Adaptive Scheduling: </span>Tailors work
        schedules to maximize efficiency and prevent fatigue.
      </div>
      <div className="fs_18_h_25_left_blk new_wid_66 tranq_2__mt  tranq_3_sec_w">
        <span className="w_600">Workload Analysis: </span>Monitors task
        distribution for equitable and manageable workloads.
      </div>
      <div className="fs_18_h_25_left_blk new_wid_66 tranq_3_sec_mg_bottom tranq_3_sec_w">
        <span className="w_600">Burnout Prevention: </span>Identifies
        potential overwork and suggests necessary adjustments.
      </div>
      <div className="sim_9th_ico_cont">
        <div className="lk w_600">
          <a href="#" className="sim_9th_lnk">
            Understand the Bearish OS Tranquil Mission Statement
          </a>
        </div>
        <div className="con_icon">
          <img alt="icon" className="fo_img" src={rdIcons} />
        </div>
      </div>
    </div>
    <div className="tranq_3_sec_img">
      <img alt="img" className="fo_img" src={img5010} />
    </div>
  </div>)
}
export default TraquilAIThirdSection;