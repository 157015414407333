import { useEffect } from "react";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import "./about.css";
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className=" abt_main">
        <div className="abt_heading ">Get to know us…Bearish OS</div>
        <div className="abt_content">
          <div className="avt_w ">
            Welcome to the Future of Productivity: Bearish OS!{" "}
          </div>
          <div className="mt_b_30">
            At Bearish OS, we’re not just a software platform; we’re a bold
            vision, a revolution in the making, and a passionate commitment to
            redefining how we work and live in a digital world.
          </div>
          <div>Our Journey</div>
          <div className="mt_b_30">
            For three years, we’ve toiled in stealth, relentlessly crafting a
            product that reflects our core mission: to empower you, the user, to
            take control of your digital world. Today, we’re excited to unveil
            Bearish OS, the result of countless hours of dedication, innovation,
            and the unwavering belief that we can build something extraordinary.
          </div>
          <div>End the Endless App Shuffling</div>
          <div className="mt_b_30">
            We’ve all been there, haven’t we? App-hopping, losing precious time
            navigating the complexities of the digital landscape. Bearish OS is
            our solution to this eternal struggle. We’ve designed it to be your
            one platform, your central hub for all things digital. Connect all
            the apps you love, replace over 2000 others, and enjoy a unified
            experience.
          </div>
          <div>Supercharge Your Work with AI</div>
          <div className="mt_b_30">
            We’ve harnessed the power of Open Source AI to take your
            productivity to new heights. Bearish OS intelligently connects your
            apps, making them work together in perfect harmony. No more
            switching back and forth, no more wasting time on repetitive tasks.
            Our AI-driven platform is here to empower you.
          </div>
          <div>Privacy First</div>
          <div className="mt_b_30">
            Privacy isn’t just a buzzword for us; it’s a core principle. Your
            data is your data, and Bearish OS is committed to ensuring it stays
            that way. We never train on your data, and we’ve built our platform
            with your privacy and security as our top priority.
          </div>
          <div>Join the Revolution</div>
          <div className="mt_b_30">
            Bearish OS isn’t just software; it’s a movement. It’s about
            empowering you to take control of your digital life. We’ve built it
            for you, with the intention of making it as customizable as
            possible. We believe in the power of community and invite you to
            share your feedback and shape the future of Bearish OS
          </div>
          <div>The Future of Customization</div>
          <div className="mt_b_30">
            In the near future, we’re taking customization to a whole new level.
            We’re thrilled to announce that you’ll be able to build features
            right into the Bearish OS experience. Imagine a digital world that
            you design to suit your unique needs.
          </div>
          <div>Join Us on This Extraordinary Journey</div>
          <div className="avt_padding">
            Bearish OS is just beginning. We’re on the cusp of something
            incredible, and we want you to be a part of it. Join us in shaping
            the future of productivity, privacy, and digital empowerment.
            Together, we’ll transform the way we work and live, one line of code
            at a time. Explore the future at www.bearishos.com and experience
            the Bearish OS revolution.
          </div>
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default AboutPage;
