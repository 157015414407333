import grp4973 from "../../assets/Bearishrelationshipmanagerimg/Group 4973@2x.png";
import grp4975 from "../../assets/Bearishrelationshipmanagerimg/Group 4975-1@2x.png";
import grp4976 from "../../assets/Bearishrelationshipmanagerimg/Group 4975@2x.png";
import firstScreenShot from "../../assets/Bearishrelationshipmanagerimg/Screenshot 2024-01-23 at 3.00.27 PM@2x.png";
import secImage from "../../assets/Bearishrelationshipmanagerimg/Image 1@2x.png";
const FourthSectionOfRelationManager = ()=>{
    return(
        <div className="content_img_rt">
        <div className="rt_left_content_d">
          <div className="rt_left_content_heading w_600">
            A living personalized Relationship Manager
          </div>
          <div className="rt_left_content_cont">
            Never static with our intelligent connections your Relationship
            Manager is a living breathing piece of software. Constantly
            updating from intelligent connections to popular social media
            apps like ‘X’ and LinkedIn’ means you’re always in the know and
            always 
            on top of everything.
          </div>
          <div className="rt_left_content_icon">
            <div className="rt_left_content_icon_data">
              <div className="con_icon">
                <img alt="ico"  className="widthh_100" src={grp4973} />
              </div>
              <div className="left_icon_content img_w">
                Make interactions personal with customer insights no matter
                where they say them
              </div>
            </div>
            <div className="rt_left_content_icon_data">
              <div className="con_icon">
              <img alt="ico"  className="widthh_100" src={grp4976} />
              </div>{" "}
              <div className="left_icon_content img_w">
                With automatic updates, easy summaries, and intelligent design
                Bearish uses AI to streamline work, saving you hours a day
              </div>
            </div>
            <div className="rt_left_content_icon_data">
              <div className="con_icon">
              <img alt="ico"  className="widthh_100" src={grp4975} />
             
              </div>{" "}
              <div className="left_icon_content img_w">
                Automatic filtering sorts through social posts to ensure you
                have the marketing and sales edge with every customer
              </div>
            </div>
          </div>
        </div>
        <div className="img_back_rt">
          <img alt="img" className="screenshot_img" src={secImage} />
          <img alt="img" className="sec_img_tag" src={firstScreenShot}/>
        </div>
      </div>
    )
}
export default FourthSectionOfRelationManager;