import ico5030 from "../../assets/Group 5030@2x.png";
import ico5262 from "../../assets/Group 5262@2x.png";
export const DashBoardList = [
  {
    title: "Customizable Dashboard",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Streamlined Notifications",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Snooze, Manage & Reply to Tasks",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
];
export const VideoCenter = [
  {
    title: "Crystal Clear Video Calls",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Pre-Call Agenda Planning",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Post-Call History",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Auto-Updating CRM",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Post-Call Automatic Task Planning",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Post-Call BEBA AI Responses",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Video Call Recordings",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Video Background Blurring",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Video Access Control",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Connected Rich Notes",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Templated Snippets",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Unlimited Guest Access",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Connected Workspaces",
    items: [
      { type: "text", value: "Standard" },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "BEBA AI Call Transcriptions",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "5+ Intelligent Connections",
    items: [
      { type: "image", value: ico5262 },
      { type: "text", value: "Enterprise Plan" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "text", value: "Enterprise Plan" },
    ],
  },
];

export const IntelligentEmailClient = [
  {
    title: "Intelligent Gmail Connection",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
    ],
  },
  {
    title: "Intelligent Outlook Connect",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Post-Email Updated CRM",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Scheduled Emails",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Email Template Snippets",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Advanced Read Receipts",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Post-Email Analytics",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "BEBA AI - Generate",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Summarize",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Analyze Tone",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Filteration",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
];

export const ProjectsPlanExecute = [
  {
    title: "Lists",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Checklists",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Boards",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Docs",
    items: [
      { type: "image", value: ico5030 },

      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Wikis",
    items: [
      { type: "image", value: ico5030 },

      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Whiteboards",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Decks",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Grids",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Workspace Analytics",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Workspace Edit Trail History",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Rich Comments",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Rich Feedback",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Generate",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Unlimited Guest Access",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
];

export const CloudStorageAnalytics = [
  {
    title: "Premium Cloud Storage",
    items: [
      { type: "text", value: "10 GB Total" },
      { type: "text", value: "250 GB Total" },
      { type: "text", value: "20 GB Total" },
      { type: "text", value: "20 GB Total" },
      { type: "text", value: "20 GB Total" },
    ],
  },
  {
    title: "Trackable Links",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "File Analytics & Trails",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Content Management System",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
      { type: "text", value: "Add On w/ Project Plan" },
    ],
  },
  {
    title: "Intelligent Connection Drive",
    items: [
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
    ],
  },
  {
    title: "Intelligent Connection Teams",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
    ],
  },
  {
    title: "Intelligent Connection Box",
    items: [
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
    ],
  },
  {
    title: "Intelligent Connection Dropbox",
    items: [
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
    ],
  },
  {
    title: "Rich File Comments",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
    ],
  },
  {
    title: "Rich Document Comments",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
    ],
  },
  {
    title: "Drop Pin Feedback Placement",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
    ],
  },
  {
    title: "BEBA AI - Generate",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
      { type: "text", value: "Add On w/ Smart Cloud" },
    ],
  },
  {
    title: "Unlimited Guest Access",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
];

export const CalendarPlan = [
  {
    title: "Intelligent Calendar",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Intelligent Connection Google",
    items: [
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
    ],
  },
  {
    title: "Intelligent Connection Teams",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
    ],
  },
  {
    title: "Intelligent Connection Outlook",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Intelligent Connection Zoom",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "List View of Upcoming Events",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Day, Week & Month Views",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Automatic Timezone Conversion",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Schedule Links",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Connected Video Call Scheduling",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Automatic Updating CRM",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "BEBA AI - Auto Scheduling",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Auto Break Scheduling",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
];

export const StreamMessaging = [
  {
    title: "Team 1:1 Chat",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Team Group Chat",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Rooms Chat",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Snooze Incoming Messages",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Read Receipts",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Edit Sent Messages",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Mark and Manage Messages",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Message Analytics",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Rich Text Messages",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Rich Audio Messages",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Intelligently Connect Slack",
    items: [
      { type: "text", value: "Beta Access" },
      { type: "text", value: "Beta Access" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "text", value: "Beta Access" },
    ],
  },
  {
    title: "Intelligently Connect Teams",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "text", value: "Alpha Access" },
    ],
  },
  {
    title: "Intelligently Connect Messenger",
    items: [
      { type: "text", value: "Alpha Access" },
      { type: "text", value: "Alpha Access" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "text", value: "Alpha Access" },
    ],
  },
];

export const RelationshipManager = [
  {
    title: "Unlimited Contacts",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Unlimited Companies",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Unlimited Deals",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Unlimited Groups",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Company Database",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "User Database",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Contact Status Monitoring",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Dynamic Updating CRM",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Contact Notes",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Automatic Contact Decoupling",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Contact Enrichment",
    items: [
      { type: "text", value: "$0.35 per contact" },
      { type: "text", value: "25 per month" },
      { type: "text", value: "50 per month" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Detailed Analytics",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "5+ Intelligent Connections",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
];

export const Trax = [
  {
    title: "Company Directory",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Employee Built Directory Pages",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Company Knowledge Base",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Employee Document Management",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Employee Time Tracking",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Employee Time Sheets",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Employee Training Analytics",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Project Based Time Monitoring",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Project Based Billing",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Unlimited Guest Project Views",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Unlimited Guest Project Comments",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Second Brain Access",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "BEBA AI - Summarized Day",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
    ],
  },
];

export const AdminSecuritySupport = [
  {
    title: "Admin Control Panel",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Advanced Admin Settings",
    items: [
      { type: "image", value: ico5262 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Two Factor Authentication",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "SSO",
    items: [
      { type: "image", value: ico5262 },
      { type: "text", value: "Enterprise Customers" },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
      { type: "image", value: ico5262 },
    ],
  },
  {
    title: "Privacy First AI",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Community Support",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Knowledge Hubs & Videos",
    items: [
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
      { type: "image", value: ico5030 },
    ],
  },
  {
    title: "Email Support",
    items: [
      { type: "text", value: "48 Hour Response Time" },
      { type: "text", value: "36 Hour Response Time" },
      { type: "text", value: "48 Hour Response Time" },
      { type: "text", value: "48 Hour Response Time" },
      { type: "text", value: "48 Hour Response Time" },
    ],
  },
];
