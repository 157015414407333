import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const ITCardData = [
  {
    heading: "Early Access Program",
    content:
      "Join our closed alpha to be among the first to experience and shape the Bearish OS IDE to streamline and supercharge development",
    background: img4947,
  },
  {
    heading: "State-of-the-Art Coding Tools",
    content:
      "Anticipate a suite of advanced tools and features that cater to modern development needs, and 2x production",
    background: img4969,
  },
  {
    heading: "Advanced Code Completion",
    content:
      "Experience unparalleled efficiency with our intelligent code completion feature, designed to predict your needs and reduce errors",
    background: img4970,
  },
  {
    heading: "Real-Time Collaboration",
    content:
      "Simultaneous editing and live chat within the coding environment, making pairing and code review sessions more productive",
    background: img5000,
  },
  {
    heading: "Integrated Debugging Tools",
    content:
      "Simplify the debugging process with integrated tools that offer clear insights into code behavior and potential issues",
    background: img5002,
  },
  {
    heading: "Customizable Environments",
    content:
      "Tailor the IDE to your personal workflow with customizable themes, layouts, and extensions",
    background: img5001,
  },
];
export default ITCardData;
