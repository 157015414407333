import img4886 from "../../assets/BearishSimplify/Group 4886@2x.png";
import icon4931 from "../../assets/roundIcons/Group 4931@2x.png";
const SecondSectionOfSimplify = ()=>{
    return(<div className="sim_second_sec">
    <div className="sec_content_sim">
      <div className="heading_30_41_left_blk sec_sim_header w_600">
        Simplify your life easily, with a solution that works and doesn’t
        break the bank
      </div>
      <div className="sec_sim_content_ left_icon_content">
        Bearish OS is the smart choice for businesses from startups to
        Fortune 500s, offering an all-in-one platform that cuts costs
        without cutting corners. Save up to $3000 monthly per user by
        replacing a multitude of single-use appswith our versatile suite
        for scheduling, presentations, communication, and more. It’s simple,
        efficient, and scales with your business<br/> – the perfect tool for
        growth-focused teams.
      </div>
      <div className="lnk_sim">
        <div className="lk w_600"><a href="#" className="a_lnk_sim">Get started Free Now</a> </div>
        <div className="con_icon">
            <img alt="icon" src={icon4931} className="fo_img" />
        </div>
      </div>
    </div>
    <div className="sim_img_rt">
      <img alt="img" className="fo_img" src={img4886} />
    </div>
  </div>)
}
export default SecondSectionOfSimplify;