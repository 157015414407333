import img6_14_26 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 6.14.26 PM@2x.png";
import img6_14_50 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 6.14.50 PM@2x.png";
import img6_18_38 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 6.18.38 PM@2x.png";
import imgactivity from "../../assets/RelationshipManager1/60a9f65e3b6c3333711e390933a17e6f@2x.png";
import img6_22_47 from "../../assets/RelationshipManager1/Screenshot 2024-01-23 at 6.22.47 PM@2x.png";
import imgad from "../../assets/RelationshipManager1/a926ce57c8d80d04f6f06d27e763b6d1@2x.png";
const FifthSectionOfRelaShip = () => {
  return (
    <div className="rs_big_crd">
      <div>
        <div className="bg_bck_1st_img">
          <img alt="img" src={img6_14_26} className="fo_img img_box" />
          <div className="fs_18_h_25_left_blk fs_first_div">
            No matter where you store documents, View Edit,Adjust, and Create
            without ever <br />
            leaving Bearish OS
          </div>
        </div>
        <div className=" bg_bck_2nd_img bg_bck_1st_img">
          <img alt="img" src={img6_14_50} className="fo_img img_box" />
          <div className="fs_18_h_25_left_blk fs_second_div">
            Built in time tracking links so you understand whats working, what
            isn’t and how to fix it
          </div>
        </div>
        <div className="bg_bck_3rd_img img_box">
          <img alt="img" src={img6_18_38} className="fo_img br_5" />
          <div className="fs_18_h_25_left_blk fs_thrd_div">
            Unified Search so every asset is always within reach across every
            team means no more wasted duplication time
          </div>
        </div>
      </div>
      <div >
        <div className="bg_bck_1st_img act_bg ">
          <img alt="img" src={imgactivity} className="fo_img img_box" />
          <div className="fs_18_h_25_left_blk fs_first_div act_content_bg">
            Know who did what, when, for how long, with who,
             and why with interconnected activity tracking so you
            can cut down the back and forth
          </div>
        </div>
        <div className="ad_sec_bg">
          <img alt="img" src={img6_22_47} className="fo_img img_box" />
          <div className="fs_18_h_25_left_blk fs_second_div ad_content_bg">
            With our Open Source AI you get auto
             filtering, labeling, and managing built 
            in so your searches extend beyond just 
            simple keywords and names
          </div>
        </div>
        <div className="bg_bck_3rd_img ad_bg img_box">
          <img alt="img" src={imgad} className="fo_img br_5" />
          <div className="fs_18_h_25_left_blk fs_thrd_div ad_sec_content_bg">
            An analytical link for everything means no matter what service your
            piece of content lives in our intelligent connections can track it
            with ease
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthSectionOfRelaShip;
