import img8 from "../../assets/RelationshipManager1/Image 8@2x.png";
import img6 from "../../assets/RelationshipManager1/Image 6@2x.png";
import img7 from "../../assets/RelationshipManager1/Image 7@2x.png";
import img9 from "../../assets/RelationshipManager1/Image 9@2x.png";
import img10 from "../../assets/RelationshipManager1/Image 10@2x.png";
import img11 from "../../assets/RelationshipManager1/Image 11@2x.png";
import icon4982 from "../../assets/RelationshipManager1/Group 4982@2x.png";
import icon4975 from "../../assets/RelationshipManager1/Group 4983@2x.png";
import icon4984 from "../../assets/RelationshipManager1/Group 4984@2x.png";
const ThirdSectionForRelaShipManager = () => {
  return (
    <div className="rs_third_section">
      <div className="rs_third_imgs">
        <img alt="img" className="fo_im bx_shadow bd_radi_5" src={img8} />
        <img alt="img" className="fo_ig bx_shadow bd_radi_5" src={img6} />
        <img alt="img" src={img7} className="img7 bx_shadow bd_radi_5" />
        <img alt="img" className="img9 bx_shadow bd_radi_5" src={img9} />
        <img alt="img" src={img10} className="img10 bx_shadow bd_radi_5" />
        <img alt="img" src={img11} className="img10 img11 bx_shadow bd_radi_5" />
      </div>
      <div className="rs_third_sec_content">
        <div className="third_sec_heading w_600">
          Work your way, everyday with Tasks, Lists, Whiteboards, Docs,
          Checklists & more
        </div>
        <div className="ft_18_l_ht_25 third_sec_content">
          Tailor your daily workflow with Bearish’s versatile suite: Tasks,
          Lists,
          Whiteboards, Docs, and Checklists. Designed for flexibility and
          efficiency,
           our platform adapts to your working style, enabling you to
          organize, prioritize, 
          and collaborate with ease. Experience a new level of productivity,
          every day
        </div>
        <div className="third_sec_ic_content">
          <div className="thrd_icon">
            <div className="con_icon">
              <img alt="icon" src={icon4982} className="new_icon_height" />
            </div>
            <div className="ft_18_l_ht_25 width_549">
              Control the features you want and don’t want with ease. Our clean
              UI means you never feel over whelmed with options
            </div>
          </div>
          <div className="thrd_icon">
            <div className="con_icon">
              <img alt="icon" src={icon4975} className="new_icon_height" />
            </div>
            <div className="ft_18_l_ht_25 width_537">
              Built for real time, secure, and private collaboration with
              enhanced privacy and security features with every account
            </div>
          </div>
          <div className="thrd_icon">
            <div className="con_icon">
              <img alt="icon" src={icon4984} className="new_icon_height" />
            </div>
            <div className="ft_18_l_ht_25 width_560">
              Power through your work without having to jump from place to place
              with Bearish replacements you save time & an average of
              $2000/month
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThirdSectionForRelaShipManager;
