import "./intelligentEmail.css";
import emailIcon from "../../assets/BearishOSinteligent/2f6067347158d857a37ac1cb84de44ac.png";
import outlookIcon from "../../assets/BearishOSinteligent/9dc5162b28ea2ebfc4616d8894b337a9-1.png";
import userIcon from "../../assets/BearishOSinteligent/f5eb75b6bc0fe531869d83045baa434c.png";
import salesfIcon from "../../assets/BearishOSinteligent/dd20eb131acf2b7a98b9e59f5b4f6c88.png";
import nexIcon from "../../assets/BearishOSinteligent/d63ff36735bebc632905c86eed5ee447.png";
import tIcon from "../../assets/BearishOSinteligent/35319e3514d790317f59e61026a4b8a7.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import screenshotImg from "../../assets/Screenshot 2024-01-01 at 4.17.25 PM.png";
import screenshotside from "../../assets/Screenshot 2023-12-30 at 2.37.29 PM.png";
import firimg from "../../assets/BearishOSinteligent/Screenshot 2023-12-30 at 2.24.50 PM@2x.png";
import f12img from "../../assets/BearishOSinteligent/Screenshot 2024-01-09 at 12.16.26 PM@2x.png";
import f14img from "../../assets/BearishOSinteligent/Screenshot 2024-01-09 at 12.14.26 PM@2x.png";
import tiimg from "../../assets/Group 4943@2x.png";
import midImg from "../../assets/Screenshot 2024-01-09 at 1.59.46 PM@2x.png";
import imgscreenshot3 from "../../assets/BearishOSinteligent/Screenshot 2024-01-09 at 2.22.14 PM@2x.png";
import imgscreenshot1 from "../../assets/BearishOSinteligent/Screenshot 2024-01-01 at 4.14.50 PM@2x.png";
import imgscreenshot2 from "../../assets/BearishOSinteligent/Screenshot 2024-01-09 at 2.22.22 PM@2x.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const IntelligentEmail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="intr">
        <div className=" int_main">
          <div className="int_head_par">
            <div className="int_heading">
              Revolutionize Your Inbox: Experience email with AI-powered speed
              and precision
            </div>
            <div className="int_contnt">
              Accelerate your email experience, doubling your productivity.
              Prioritize important correspondence, automatically filter out the
              noise, and say goodbye to email-related stress forever.
            </div>
            <div className="mt_50">
              <button className="re_btn">
                <Link
                  to="https://app.bearishos.com/signup"
                  className="re_btn footer_nav"
                  target="_blank"
                >
                  Start Free Now
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="int_img_content">
        <div className="int_cont">
          <p className="int_p_tag">
            Embark on a journey of email unification, with automatic <br />{" "}
            noise filtration, conversation summaries,
            <br /> and lightning-fast responses!
          </p>
        </div>
      </div>
      <div className="int_middle_div">
        <div className="int_back_ground">
          <div>
            <img className="int_img_email" src={emailIcon} alt="" />
          </div>
          <div>
            <img className="int_img_out" src={outlookIcon} alt="" />
          </div>
          <div>
            <img className="int_img_t" src={tIcon} alt="" />
          </div>
        </div>
        <div className="int_rt_content w_600">
          <p>
            Don’t integrate,
            <span className="int_red">
              intelligently connect <br />
            </span>{" "}
            with Gmail*, Outlook, Teams <br />{" "}
            <span className="int_blue"> and more</span>
          </p>
        </div>
      </div>
      <div className="int_sec_middle_content">
        <div className="int_mid_rt_cont w_600">
          Cut your costs w/ Read Receipts Analytics, Snippets, AI based
          filtering, Scheduling,
          <br /> and more built in
        </div>
        <div className="int_lt_back_img">
          <img className="int_img_up" src={screenshotImg} alt="helo" />
          <img className="int_img_sid" src={screenshotside} alt="helo" />
        </div>
      </div>
      <div className="int_super w_600">
        <p>
          Supercharge your email game with instant summaries, personalized{" "}
          <br />
          generated responses, and an automatically updating CRM
        </p>
      </div>
      <div>
        <div className="int_testi">
          <div className="hell">
            <div className="int_fi_div">
              <img
                src={firimg}
                alt="img"
                className="fo_img bx_shadow bd_radi_25"
              />
            </div>
            <div className="int_img_de">Instant Summaries</div>
          </div>
          <div className="hell_2">
            <div className="int_sec_div">
              <img
                src={f12img}
                alt="img"
                className="fo_img bx_shadow bd_radi_25"
              />
            </div>
            <div className="int_img_det">Every Conversation, One CRM</div>
          </div>
        </div>

        <div>
          <img className="img_int" src={f14img} alt="img" />
          <div className="img_int_de">Powerful Open Sourced AI</div>
        </div>
      </div>
      <div className="ti_div">
        <div className="ti_img">
          <img className="img_ti_div" alt="ti" src={tiimg} />
        </div>
        <div className="ti">
          <div className="ti_head ">Get it there on time, Every Time</div>
          <div className="p_content">
            Don’t miss deadlines, and never miss a beat. With automatic
            scheduling you can ensure your emails always arrive on time.
          </div>
          <div className="p_content_sec">
            With Bearish automatic send you can schedule your email to arrive at
            the optimal point of interaction during your recipients work day.
          </div>
        </div>
      </div>
      <div className="mid_sec">
        <div className="mid_content">
          <div className="mid_cont_head">
            Understand what lands and cut your response times <br /> in half
          </div>
          <div className="mid_cont">
            Don’t just get a read receipt understand your emails entire journey
            with deep analytics on open rates time spent, forwarding, and more.{" "}
          </div>
        </div>

        <img className="mid_img" alt="img" src={midImg} />
      </div>
      <div className="say_it">
        Say It Once, Save It Forever: Unleash the Magic of Snippets
      </div>
      <div className="say_cont">
        Type once, and your words become a reusable asset. Unleash instant
        efficiency across <br /> emails and documents, turning repetitive typing
        into a thing of the past.
        <br /> Snippets by Bearish OS – where every keystroke saves you time
      </div>
      <div className="img_3">
        <img alt="img" className="img_3_f" src={imgscreenshot1} />
        <img alt="img" className="img_f" src={imgscreenshot2} />
        <img alt="img" className="img_f" src={imgscreenshot3} />
      </div>
      <div className="int_email">
        <div className="new_email_dec">
          <div className="email_heading w_600">
            Emails Reimagined: Where Insights make Unforgettable Moments
          </div>
          <div className="email_content">
            Revolutionize emailing with our smart integration that infuses vital
            information into your messages. Utilize personalized data to craft
            impactful emails that truly connect with your audience, turning
            every send into an unforgettable engagement
          </div>
        </div>
        <div className="int_email_img">
          <img alt="sdfg" className="img_em_int" src={midImg} />
        </div>
      </div>
      <div className="img_wi_cont postion_rela">
        <div className="postion_abs bg_content_">
          Step into the future of business management with Bearish: A visionary
          tool designed to run your entire <br />
          operation smoothly from start to finish
        </div>

        <img alt="icon" className="out_img postion_abs " src={outlookIcon} />

        <img alt="icon" className="ico_user postion_abs" src={userIcon} />

        <img alt="icon" className="sa_icon postion_abs" src={salesfIcon} />

        <img alt="icon" className="nex_icon postion_abs" src={nexIcon} />
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default IntelligentEmail;
