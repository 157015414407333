import { useEffect } from "react";
import "./MicrosoftVerification.css";

const MicrosoftVerification = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className=" mi_ve_main">
        <div className="abt_heading">Contact Information</div>
        <div className="mi_ve_content">
          <div>Bearish F.H., INC.</div>
          <div>95 3RD St San Francisco California 94103</div>
          <div>United States</div>
          <div>415-298-3631</div>
          <div>Bearish OS c/o Saliba Faddoul</div>
          <div>saliba@bearishos.com </div>
          <div>saliba.faddoul@bearishfh.com</div>
          <div className="mt_b_30">https://www.bearishos.com</div>

          <div className="mt_b_30">
            Verification of the ownership of the domain
            https://www.bearishos.com for Microsoft Startup Hub
          </div>
        </div>
      </div>
    </div>
  );
};
export default MicrosoftVerification;
