import "./centralized.css";
import messenger from "../../assets/BearishOSCentralizedCommunications/940f48b60a3425ef088d9c5c9c3b2c4c@2x.png";
import img56 from "../../assets/BearishOSCentralizedCommunications/1bff9f7c3908f8c5802047e2dd07ba56@2x.png";
import imgt from "../../assets/BearishOSCentralizedCommunications/35319e3514d790317f59e61026a4b8a7@2x.png";
import imgchat from "../../assets/BearishOSCentralizedCommunications/05f21e9b4d4cc6f239b23e479d0f8d90@2x.png";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import CommunicationData from "../../components/communication/communicationCrdData";
import RatingSection from "../../components/virtualMeetingHub/RatingSection";
import CommuteRatingData from "../../components/communication/commuteRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { Link } from "react-router-dom";
const CentralizeCommunications = () => {
  const [rtNmCards, setRtNmCards] = useState("nmrt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="cent_main">
        <div className="cent_1_sec">
          <div className="cent_1_sec_heading w_600">
            Centralize Your Communications: Harness the Power of Unified
            Messaging with Unmatched Efficiency and Clarity
          </div>
          <div className="cent_1_sec_content ">
            Streamline your communication channels, enhancing collaboration and
          
            focus. Effortlessly manage multiple streams of information, ensuring
            key messages are prioritized while minimizing distractions.
             Embrace a new era of stress-free, centralized 
            communication efficiency
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
      </div>

      <div className="cent_2_sec">
        <div className="cent_2_sec_content w_600">
          Unify, streamline, & accelerate messaging with Bearish OS!
          Harness AI for noise-free, quick-response
          <br /> communication with teams, clients,
          <br /> and companies, all in one hub
        </div>
      </div>

      <div className="cent_3_sec">
        <div className="cent_3_sec_img">
          <img className="cent_3_sec_1_img" src={messenger} alt="icon" />
          <img
            className="cent_3_sec_1_img cent_3_sec_2_img"
            src={img56}
            alt="icon"
          />
          <img
            className="cent_3_sec_2_img cent_3_sec_6_img"
            src={imgt}
            alt="icon"
          />
          <img
            className="cent_3_sec_4_img cent_3_sec_5_img"
            src={imgchat}
            alt="icon"
          />
        </div>
        <div className="newcent_3_sec_img">
          <div className="cent_3_sec_heading w_600">
            <span className="co_bl">Every Message</span> from everyone no matter
            where they are <br />
            all in <span className="co_red">One Place </span>
          </div>
          <div className="cent_3_sec_content">
            Bearish Chat allows for revolutionary chat features that empowers
            your teams to get more done with ease. Seamlessly intertwined with
            Bearish Email, Project Management, Docs, Calendar, and More means
            you always have what you need
            <br /> when you need it
          </div>
        </div>
      </div>

      <div className="cent_4_sec">Built for the Active Remote Teams</div>

      <div className="vt_par_crd_div rt_crd_detail">
        {CommunicationData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtNmCards} />
        ))}
      </div>

      <div className="cent_5th_sec">
        <div className="cent_5th_sec_heading w_600">
          Customers who use Bearish report higher productivity, fewer missed
          messages, and better engagement
        </div>
        <div className="rating_card_main cent_rtig">
          {CommuteRatingData?.map((data, i) => (
            <RatingSection data={data} i={i} />
          ))}
        </div>
      </div>

      {/* <WorkSimpler /> */}

    </div>
  );
};

export default CentralizeCommunications;
