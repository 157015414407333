import icon4975 from "../../assets/BearishOSBEBAAI/Group 4975@2x.png";
import icon4982 from "../../assets/BearishOSBEBAAI/Group 4982@2x.png";


import icon4975_1 from "../../assets/BearishOSBEBAAI/Group 4975-1@2x.png";
const BebaAIContentIcon = [
  {
    icon: icon4982,
    content:
      "Quickly grasp the essence of complex documents with BEBA AI’s smart summarization",
    boldContent: "Effortless Summaries: ",
  },
  {
    icon: icon4975,
    content:
      "Capture every word accurately in meetings and conversations with on-the-spot transcription services",
    boldContent: "Real-Time Transcription: ",
  },
  {
    icon: icon4975_1,
    content:
      "Respond faster and more effectively with BEBA AI’s context-aware automated reply suggestions",
    boldContent: "Intelligent Responses: ",
  },
];
export default BebaAIContentIcon;
