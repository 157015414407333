import icon4973 from "../../assets/RelationshipManager1/Group 4973@2x.png";
import icon4987 from "../../assets/RelationshipManager1/Group 4987@2x.png";
import icon4975 from "../../assets/RelationshipManager1/Group 4975-1@2x.png";
import icon497 from "../../assets/RelationshipManager1/Group 4975-2@2x.png";
import React from "react";
const RelationShipData = [
  {
    icon: icon4973,
    content:
      "Eliminate app-jumping with integrated email, calendar, video calling, and analytics",
    boldContent: "Unified Efficiency: ",
  },
  {
    icon: icon4987,
    content:
      "Replace single-feature apps, saving approximately $2000 USD per month, per user",
    boldContent: "Cost-Effective: ",
  },
  {
    icon: icon4975,
    content:
      "Built-in Open Source AI that guarantees your data remains untrained and private",
    boldContent: "Privacy-First AI: ",
  },
  {
    icon: icon497,
    content: "Straightforward pricing starting at about\n$30 per user, per month",
    boldContent: "Transparent Pricing: ",
  },
];
export default RelationShipData;
