import "./tranquil.css";
import img5009 from "../../assets/BearishOSTranquilAI/Group 5009@2x.png";

import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useState } from "react";
import TranquilAICardData from "../../components/tranquilAI/tranquilCardData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import img556 from "../../assets/BearishOSTranquilAI/2845f330aa3b2a4ab54c5483f603d556@2x.png";
import imga27 from "../../assets/BearishOSTranquilAI/f811f9c258a484b94171d4ce9b25aa27@2x.png";
import imgf22 from "../../assets/BearishOSTranquilAI/f7c77164bac75482fe55d4452d347f22@2x.png";
import img5012 from "../../assets/BearishOSTranquilAI/Group 5012@2x.png";
import TraquilAIThirdSection from "../../components/tranquilAI/thirdSection";
import TraquilAISecondSection from "../../components/tranquilAI/secondSection";
import { Link } from "react-router-dom";
const TranquilAI = () => {
  const [rtCards, setRtCards] = useState("rt");

  return (
    <div className="container">
      <div className="tranq_main">
        <div className="pas_1st_sec tranq_1_sec ">
          <div className="pas_1st_heading w_600  tranq_1_sec_h  ">
            Tranquil AI: Harmonizing Work-Life Balance with Advanced AI
            Technology
          </div>
          <div className="pas_1st_content tranq_1_sec_cont">
            Introducing Tranquil AI, a groundbreaking innovation designed to
            nurture mental
            health and foster a harmonious work-life balance. This intelligent
            system understands
            the nuances of employee well-being, offering tools and
            insights to maintain healthy schedules and reduce stress. With
            features focused on relaxation and mental health, Tranquil AI is
            your partner in creating a supportive, productive work environment.
            It ensures that your team’s well-being is prioritized, paving the
            way for enhanced creativity, productivity, and overall job
            satisfaction. Embrace a future where technology doesn’t just assist
            in tasks but also cares for the well-being of your team
          </div>
          <div>
            <button className="re_btn">
            <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
            </button>
          </div>
        </div>
        <div className="sim_img tranq_1_sec_img">
          <img alt="img" className="sim_img_w tranq_1_sec_img_" src={img5009} />
        </div>
      </div>

     <TraquilAISecondSection/>

      <TraquilAIThirdSection/>

      <div className="heading_30_41_left_blk w_600 tranq_4_sec">
        Your Personal Wellness Guide: Tranquil AI’s Virtual Wellness Coach
      </div>

      <div className="crd_relation tranq_4_sec_bottom">
        {TranquilAICardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>

      <div className="heading_30_41_left_blk w_600 tranq_5_sec">
        Healthy Habit Encouragement, and Team Well being reports built right in
      </div>

      <div className="tranq_6_sec">
        <div className="">
          <img alt="img" className="tranq_6_1_img img_box" src={img556} />
          <div className="tranq_6_1_cont fs_18_h_25_left_blk">
            <span className="w_600">Habit Tracking: </span>Keeps track of your
            daily health habits and progress
          </div>
        </div>
        <div>
          <img alt="img" className="tranq_6_2_img" src={imga27} />
          <div className="tranq_6_2_cont fs_18_h_25_left_blk">
            <span className="w_600">Reminders and Notifications: </span>Sends
            timely reminders for health-related activities
          </div>
        </div>
        <div>
          <img alt="img" className="tranq_6_3_img" src={imgf22} />
          <div className="tranq_6_3_cont fs_18_h_25_left_blk">
            <span className="w_600">Progress Reports: </span>Offers insights
            into your health habit trends & improvements
          </div>
        </div>
      </div>

      <div className="tranq_7_sec">
        <div className="tranq_7_sec_img">
          <img alt="img" className="fo_img" src={img5012} />
        </div>
        <div className="new_wid_tr_52">
          <div className="tranq_7_sec_heading heading_30_41_left_blk w_600">
            Alpha Today, Tomorrow’s Standard: Pioneering Mental Wellness with
            Tranquil A
          </div>
          <div className="tranq_7_sec_content fs_18_h_25_left_blk ">
            Join us at the forefront of workplace transformation with Tranquil
            AI, currently in alpha, where we’re shaping the future of mental
            wellness and work-life harmony. As early adopters, your feedback
            fuels our innovation, turning visionary ideas into practical tools
            that elevate daily life. By participating in this stage, you play a
            pivotal role in refining a platform that promises not just to
            support but to revolutionize how we approach work, wellness, and
            productivity
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about Bearish OS and get started free{" "}
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};

export default TranquilAI;
