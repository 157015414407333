import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const CustomerSuccessCardData = [
  {
    heading: "Community Forums",
    content:
      "Enable customers to connect, share, and support each other through easy to access knowledge hubs built on Bearish",
    background: img4947,
  },
  {
    heading: "Brand Advocacy Programs",
    content:
      "Encourage and reward customers for promoting your brand and engaging with your products online",
    background: img4969,
  },
  {
    heading: "Event Management",
    content:
      "Organize and promote customer events to enhance community engagement through scheduling links and more",
    background: img4970,
  },
  {
    heading: "Knowledge Base Integration",
    content:
      "Empower your customers with self-service tools by integrating a comprehensive knowledge base into the community platform",
    background: img5000,
  },
  {
    heading: "Build Rich FAQs",
    content:
      "Stop answering the same questions over and over again with the ability to build easy to understand FAQs",
    background: img5002,
  },
  {
    heading: "Customer Success Stories",
    content:
      "Showcase customer success stories and testimonials directly within the community and beyond",
    background: img5001,
  },
];
export default CustomerSuccessCardData;