import lgIconwitharrow from "../../assets/roundIcons/Group 4931.png";

const InfodataCard = ({ data, i }) => {
  return (
    <div className="lg_card" key={i}>
      <div
        className={`lg_save_t w_600 ${
          data?.header ===
          "Send, Track, and Schedule Emails, Messages, Posts, Comments, and Events with Ease"
            ? "lg_save_t_w"
            : ""
        }`}
      >
        {data?.header}
      </div>
      <div className="lg_be_deliver">{data?.content}</div>

      <div className="lg_link">
        <div className="lg_a_div">
          <a className="lg_a_tag w_600" href="">
            {data?.link}
          </a>
        </div>
        <div>
          <img src={lgIconwitharrow} alt="" />
        </div>
      </div>
    </div>
  );
};
export default InfodataCard;
