import line531 from "../../assets/BearishOSSales/Line 531@2x.png";
import line532 from "../../assets/BearishOSSales/Line 532@2x.png";
import line533 from "../../assets/BearishOSSales/Line 533@2x.png";
import line535 from "../../assets/BearishOSSales/Line 535@2x.png";

import back4993 from "../../assets/BearishOSSales/Group 4993@2x.png";
import back4994 from "../../assets/BearishOSSales/Group 4994@2x.png";
import back4995 from "../../assets/BearishOSSales/Group 4995@2x.png";
import back4996 from "../../assets/BearishOSSales/Group 4996@2x.png";

const HRRatingData = [
  {
    percent: "68%",
    linecolor: line535,
    content: "Reported a increase in (team) employee engagement",
    backgroundimg: back4993,
  },
  {
    percent: "52%",
    linecolor: line531,
    content: "Reported an increase in overall onboarding efficiency",
    backgroundimg: back4994,
  },
  {
    percent: "30%",
    linecolor: line532,
    content: "Reported reduction in time on HR documents",
    backgroundimg: back4995,
  },
  {
    percent: "22%",
    linecolor: line533,
    content: "Reported a decrease in overall scheduling conflicts",
    backgroundimg: back4996,
  },
];
export default HRRatingData;
