import screenshotfirst from "../../assets/home/Screenshot 2023-05-17 at 10.10.20 PM@2x.png";
import screenshotsecond from "../../assets/home/Screenshot 2023-05-17 at 10.23.09 PM@2x.png";
import screenshotthird from "../../assets/Group 4924@2x.png";
import screenshot4th from "../../assets/home/Screenshot 2023-12-28 at 6.10.48 PM@2x.png";
const WorkSimpler = () => {
  return (
    <div className="foo_ter_container">

    <div className="m_w_simpler postion_rela">
     
        <div className="mw_img postion_abs">
          <img
            className="img_bd_r img_w100_h100"
            src={screenshotfirst}
            alt="img"
          />
        </div>
      
          <div className="mw_top w_600 postion_abs">Make work Simplier</div>
          <div className="mw_botom postion_abs">
            Start Free Today. No Credit Card Required
          </div>
       
        <div className="mw_right_img postion_abs">
          <img
            className=" img_w100_h100"
            src={screenshotsecond}
            alt="img"
          />
        </div>
      
      <div className="bt_img postion_abs">
        <img className="img_w100_h100 bd_rad_19" src={screenshot4th} alt="img" />
      </div>
      <div className="btn_ postion_abs">
        <button className="re_btn work_btn_n">Get Started</button>
      </div>
      <div className="regt_img postion_abs">
        <img
          className="img_w100_h100 img_bd_r_l"
          src={screenshotthird}
          alt="img"
        />
      </div>
    </div>
    </div>
  );
};
export default WorkSimpler;
