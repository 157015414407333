import "./privacy.css";
import img941 from "../../assets/BearishOSIT/60379004e8674ea64c52995623c9f941@2x.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main bs_pr_main">
        <div className="sale_1_sec bs_pr_1">
          <div className="bs_pr_1_heading top_heading w_600 letter_sp">
            Privacy at the core of everything Bearish OS, It’s not an
            after-thought
          </div>
          <div className="bs_pr_1_content">
            <div>
              {" "}
              🛡️ We Respect Your Privacy: We collect and use your personal info
              only to make our Platform work better for you, following our
              agreements with your employer or the organization you're part of.
            </div>
            <div>
              🔄 Sharing Is Caring, But With Limits: We share your info with
              your employer, necessary third parties, and as law requires,
              always aiming to protect your privacy and enhance your experience.
            </div>
            <div>
              🍪 Cookies & Tech: We use cookies and similar tech to understand
              how you use our Platform, helping us make your experience smoother
              and more personalized.
            </div>
            <div>
              🔐 Security First: Your info's safety is top-notch with us, but
              remember, no system is invincible. We're committed to keeping your
              data secure through rigorous safeguards.
            </div>
            <div>
              {" "}
              🚫 Under 18? This Isn't For You: Our Platform is for adults only.
              If you're younger, please don't use our services without guardian
              consent.
            </div>
          </div>
        </div>
        <div className="cs_1_img">
          <img className="cs_1_img_" alt="img" src={img941} />
        </div>{" "}
      </div>

      <div className="heading_30_41_left_blk w_600 bs_pr_2">
        Privacy Policy
        <br /> Last Updated February 7th 2024
      </div>

      <div className="bs_pr_3_sec">
        <div className="w_700">
          Bearish FH, INC.
          <br /> Privacy Statement
        </div>
        <div className="mt_22">
          Bearish FH, INC. (“Bearish,” “we”, “us” or “our”) provides a
          centralized workplace platform that connects to and streamlines a
          user’s third-party workplace applications (“Platform”).
        </div>
        <div className="mt_22">
          Bearish offers and operates the Platform for customers (such as
          businesses and other organizations), who interact with Bearish in a
          professional business capacity, rather than in an individual or
          personal capacity (“Customers”). Customers typically own the data
          provided via the Platform and act as “controllers” with respect to
          personal information processed through the Platform. Accordingly,
          Bearish handles such personal information in accordance with our
          agreements with each relevant Customer. This Privacy Statement
          (“Privacy Statement”) explains how we collect, use, disclose personal
          information on our Customers’ behalf in connection with the Platform.
        </div>
        <div className="mt_22">
          This Privacy Statement does not govern: (a) any websites, mobile
          applications, or other online services that do not link to this
          Privacy Statement; (b) the processing of personal information by the
          relevant Customer outside of the Platform; or © our processing of
          personal information in connection with any other service we offer.
          When we handle personal information on our own behalf, please refer to
          our privacy policy for more information.
        </div>
        <div className="mt_22">
          Because Bearish does not control the privacy practices of the relevant
          Customer, you should review the relevant Customer’s privacy
          policy(ies). Our Privacy Statement is not a substitute for any privacy
          notice that a Customer may provide or be required to provide to you.
        </div>
        <div className="w_700 mt_22">PERSONAL INFORMATION WE COLLECT</div>
        <div className="mt_10">
          Information collected about users of the Platform. The information we
          collect from or about users of the Platform may include: Information
          provided to us by a Customer. This may include business and personal
          contact information such as name, email and mailing address, age,
          phone number, employment information, and professional affiliation.
        </div>
        <div>
          Information provided to us by authorized users. This may include
          personal information that you provide when registering for an account,
          corresponding with us, and any information, content, or files sent
          through, uploaded to, or connected via the Platform. Information about
          your use of the Platform. This may include information about your use
          of the Platform that we may collect directly or through third-party
          service providers, including computer or mobile device operating
          system type and version number, manufacturer and model, device
          identifier, browser type, screen resolution, IP address, general
          location information such as city, state or geographic area; and
          information about users’ use of and actions on the Platform. This
          information may be collected using cookies and similar technologies.
        </div>
        <div className="mt_22 w_700">HOW WE USE YOUR INFORMATION</div>
        <div className="mt_10">
          We use personal information to provide the Platform and related
          services, in accordance with the relevant Customer agreement, and for
          <br />
          related internal purposes, such as to:
          <br /> create and maintain a user’s account;
          <br /> facilitate communications between users and the relevant
          Customer;
          <br /> facilitate communications between users and other third parties
          at your direction;
          <br /> enable users to access and use the Platform and our services;
          <br /> maintain, improve, and develop our services and business;
          <br /> support the relevant Customer’s operations;
          <br /> enforce our Terms of Service, rights, safety or property and/or
          that of you or others;
          <br /> provide information about the Platform such as updates or
          changes to the Platform and security alerts;
          <br /> comply with law;
          <br /> protect, investigate, and deter against fraudulent, harmful,
          unauthorized, unethical or illegal activity; and
          <br /> create anonymous, aggregated or de identified data by removing
          from the records any identifiers (e.g., ID numbers, account numbers,
          names,
          <br /> etc.) that make the information personally identifiable to you.
          We may use and share this non-personal information for our business
          purposes.
        </div>
        <div className="mt_22 w_700">
          HOW BEARISH SHARES YOUR PERSONAL INFORMATION
        </div>
        <div className="mt_10">
          With Customers or at their direction. Bearish may share your personal
          information with one or more Customers (or with other third parties as
          directed by the relevant Customer), including where a Customer acts as
          the administrator of your account (e.g., your employer).
          Administrators may require you to reset your account password;
          restrict, suspend, or terminate your access to the Platform; access
          and retain information in and about your account; change the email
          address associated with your account; change your personal
          information; and restrict your ability to add, post, edit, restrict,
          modify or delete information.
          <br />
          With other users and partners related to the Platform. Bearish may
          share your information with other users or with other third parties to
          facilitate the Platform and our services. For example, we may share
          users’ information to enable correspondence between users on or
          through the Platform.
        </div>
        <div className="mt_22">
          With service providers. Bearish may share your information with
          service providers that perform services for or on behalf of Bearish
          for the purposes described in this Privacy Statement, such as to
          provide the Platform and our services, optimize the performance of the
          Platform and our services, provide technical support, and/or provide
          other services to Bearish.
          <br />
          In connection with corporate transactions. Bearish may share some or
          all of your information in connection with or during negotiation of
          any actual or prospective merger, financing, acquisition, dissolution,
          or other transaction involving the sale, transfer, divestiture or
          disclosure of all or a portion of Bearish’s business or assets. In the
          event of an insolvency, bankruptcy or receivership, your information
          may also be transferred as a business asset.
        </div>
        <div className="mt_22">
          Other disclosures. Regardless of any choices you may make regarding
          your information, Bearish may disclose your information if Bearish
          believes that such disclosure is necessary to (a) comply with
          applicable laws, legal requests, or to respond to legal process; (b)
          enforce, protect, or defend our, Customers’, your, or others’ rights,
          privacy, safety, or property; (c) comply with our policies or a legal
          or contractual
          <br />
          requirement; and/or (d) to investigate or assist in preventing any
          violation or potential violation of law, this Privacy Statement or
          Bearish’s <br />
          terms of use.
          <br />
          The parties described above may, to the extent permitted by law, use
          personal information as described in their own privacy policies.
        </div>
        <div className="mt_22 w_700">AGE LIMITATIONS</div>
        <div className="mt_10">
          The Platform is not intended for use by anyone under 18 years of age.
          If a parent or guardian becomes aware that his or her child has
          provided us with information without their consent, he or she should
          contact the relevant Customer.
        </div>
        <div className="mt_22 w_700">SECURITY</div>
        <div className="mt_10">
          Bearish maintains physical, electronic, and procedural safeguards
          designed to safeguard the security of personal information. Bearish
          cannot, however, guarantee that any safeguards or security measures
          will be sufficient to prevent an information security issue.
        </div>
        <div className="mt_22 w_700">INFORMATION RETENTION</div>
        <div className="mt_10">
          We generally retain personal information to fulfill the purposes for
          which we collected it, including for the purposes of satisfying any
          legal, accounting, or reporting requirements, to establish or defend
          legal claims, or for fraud prevention purposes. To determine the
          appropriate retention period for personal information, we may consider
          factors such as the amount, nature, and sensitivity of the personal
          information, the potential risk of harm from unauthorized use or
          disclosure of your personal information, the purposes for which we
          process your personal information and whether we can achieve those
          purposes through other means, and the applicable legal requirements.
          <br />
          When we no longer require the personal information, we have collected
          about you, we may either delete it, anonymize it, or isolate it from
          further processing.
        </div>
        <div className="mt_22 w_700">OTHER SITES OR APPLICATIONS</div>
        <div className="mt_10">
          The Platform may contain links to third-party websites or
          applications, for example, third parties who provide services for our
          Customers such as restaurants, fitness centers, and other amenity
          providers. When you click on a link to any other website or location,
          you will leave the Platform and go to another site or application
          operated by a third party. The third party may collect personal
          information about you. Bearish’s provision of a link to any other
          website or location is for your convenience and does not signify
          Bearish’s endorsement of such other website or location or its
          contents. Bearish has no control over, does not review, and is not
          responsible for these third-party websites or their content. Please be
          aware that this Privacy Statement does not apply to these third-party
          websites or applications.
        </div>
        <div className="mt_22 w_700">
          YOUR CHOICES REGARDING YOUR PERSONAL INFORMATION
        </div>
        <div className="mt_10">
          Customers are typically the data owners and controllers of users’
          personal information. As the data controller, the relevant Customer
          (such as your employer) is responsible for receiving and responding to
          users’ requests to exercise any rights afforded to them under
          applicable privacy and security laws. Please direct your privacy and
          security requests and questions, including account deletion requests,
          to the relevant Customer.
          <br /> Where Bearish is required by law to collect your information or
          where Bearish needs your information in order to provide the Platform,
          if you do not provide this information when requested (or the
          information is removed), Bearish may not be able to provide you with
          the Platform and its associated services
        </div>
        <div className="mt_22 w_700">CHANGES TO THIS PRIVACY STATEMENT</div>
        <div className="mt_10">
          Bearish reserves the right to modify this Privacy Statement at any
          time. The Platform, laws, regulations and industry standards as well
          as our business operations may evolve which may make changes to this
          Privacy Statement appropriate. Bearish will post the changes to this
          page. Bearish encourages you to review the Privacy Statement to stay
          informed. Continued use of the Platform following the effective date
          of any changes to this Privacy Statement indicates your agreement to
          such changes.
        </div>
        <div className="mt_22 w_700">HOW TO CONTACT US</div>
        <div>
          Please direct your privacy and security requests and questions to the
          relevant Customer, such as your employer. If you have any questions
          about this Privacy Statement, you can contact us at
          help@bearishfh.com.
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};

export default Privacy;
