import li_fb_icons from "../../assets/Bearish OS link icon/icons8-facebook-48@2x.png"
import li_insta_icons from "../../assets/Bearish OS link icon/icons8-instagram-48@2x.png"
import li_tktk_icon from "../../assets/Bearish OS link icon/icons8-tiktok-48@2x.png"
import li_md_icon from "../../assets/Bearish OS link icon/icons8-medium-48@2x.png";
import li_twt_icon from "../../assets/Bearish OS link icon/icons8-twitter-48@2x.png";
import li_in_icon from "../../assets/Bearish OS link icon/icons8-linkedin-48@2x.png";
const LinkIcons = [
    {icon:li_in_icon},
    {icon:li_md_icon},
    {icon:li_twt_icon},
    {icon:li_tktk_icon},
    {icon:li_insta_icons},
    {icon:li_fb_icons},
]
export default LinkIcons;