import "./operations.css";
import img4_41 from "../../assets/BearishOperations/Screenshot 2024-02-02 at 4.41.24 PM@2x.png";
import opImg4886 from "../../assets/BearishOperations/Group 4886@2x.png";
import opImg5020 from "../../assets/BearishOperations/Group 5020@2x.png";
import opImg5018 from "../../assets/BearishOperations/Group 5018@2x.png";
import opImg5016 from "../../assets/BearishOperations/Group 5016@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import OperationsCardData from "../../components/operations/operationCardData";
import RatingCardSection from "../../components/common/ratingCardSection";
import hrimg5012 from "../../assets/BearishOSHR/Group 5012@2x.png";

import OperationRatingData from "../../components/operations/operationRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Operations = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main op_main">
        {" "}
        <div className="sale_1_sec">
          <div className="hr_heding top_heading w_600 op_heading">
            Operational Mastery Unleashed: Transform Your Business with Bearish
            OS
          </div>
          <div className="top_content pr_mn_content op_content">
            Redefine the efficiency and effectiveness of your business
            operations with Bearish OS. Our platform is engineered to streamline
            every aspect of your operations, from resource management and
            logistics to communication and project tracking. Bearish OS equips
            your team with powerful tools designed for the modern operational
            landscape, integrating seamless workflows, real-time analytics, and
            smart automation to drive unparalleled productivity. Embrace a
            solution that not only optimizes your day-to-day tasks but also
            provides strategic insights that fuel growth and innovation.
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="cs_1_img">
          <img className="w_129" alt="img" src={img4_41} />
        </div>{" "}
      </div>

      <div className="sale_2_sec pr_mn_1_mrgin_bt cs_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={opImg4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="hr_1_heading letter_sp heading_30_41_left_blk w_600 op_1_heading">
            Optimize Your Assets: Advanced Resource Management
          </div>
          <div className="hr_1_content fs_18_h_25_left_blk letter_sp op_1_content">
            Maximize the utilization of your resources with Bearish OS’s
            comprehensive management tools. Plan, allocate, and track your
            resources to ensure projects are well-supported and efficiently
            executed
          </div>
          <div className="sal_2_sec it_2_gap hr_1_contt op_1_cont">
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk  letter_sp">
                <span className="w_600">Resource Allocation: </span>Dynamically
                assign resources based on project needs and availability to
                ensure your team is up to date, on time, and under budget with
                all their tools in one place
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk   letter_sp">
                <span className="w_600">Utilization Tracking: </span>
                Monitor how resources are being used to identify opportunities
                for optimization to improve overall performance across cross
                functional teams
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="fs_18_h_25_left_blk   letter_sp ">
                <span className="w_600">Forecasting and Planning: </span>
                Predict future resource needs with data-driven insights and
                better understand what needs to be addressed and the best way to
                address them
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec pr_mn_gap op_3_sec">
        <div className="pas_5th_sec_cont">
          <div className=" heading_30_41_left_blk w_600 letter_sp it_2_heADING op_3_heading">
            Unify Your Team: Enhance Communication and Collaboration
          </div>
          <div className="it_2_content fs_18_h_25_left_blk letter_sp op_3_content">
            Foster a culture of transparency and collaboration with Bearish OS’s
            communication tools. Keep your team connected and informed, whether
            they’re in the office or working remotely
          </div>
          <div className="op_3_cont_w fs_18_h_25_left_blk letter_sp">
            <span className="w_600">Integrated Messaging: </span>
            Streamline internal communications with built-in messaging and
            forums
          </div>
          <div className="op_3_cont_w fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Collaborative Workspaces: </span>
            Create shared spaces for teams to collaborate on documents and
            projects
          </div>
          <div className="op_3_cont_w fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Video Conferencing: </span>
            Conduct meetings and discussions via integrated video calls to
            maintain personal connections
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Understand all our Plan & Track Options
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pr_mn_3_img">
          <img alt="img" className="fo_img" src={opImg5020} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={opImg5016} />
        </div>
        <div className="sal_2_sec op_3_cont_w_new">
          <div className="op_4_heading letter_sp heading_30_41_left_blk w_600">
            Project Insight: Track and Report with Confidence
          </div>
          <div className="pr_mn_7_content fs_18_h_25_left_blk letter_sp">
            Keep your projects on track and stakeholders informed with Bearish
            OS’s project tracking and reporting tools. Access detailed insights
            and customizable reports to monitor progress and performance
          </div>
          <div className="op_4_cont sal_2_sec it_2_gap ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Customizable Dashboards: </span>
              Create personalized dashboards to highlight key project metrics
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Progress Tracking: </span>
              Monitor project milestones, tasks, and deadlines with ease and
              better respond to potential risks and delays
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Reporting Tools: </span>Generate detailed
              reports for internal review or stakeholder updates to never miss a
              beat
            </div>
          </div>
        </div>
      </div>

      <div className="letter_sp heading_30_41_left_blk w_600 op_5_heading">
        Cultivate Customer Loyalty: Integrated CRM for Operations and
        Advancements
      </div>

      <div className="sale_5_sec ">
        {OperationsCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec cs_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={opImg5018} className="fo_img" />
        </div>
        <div className="pas_5th_sec_cont pr_mn_7_content_new">
          <div className="hr_7_heading heading_30_41_left_blk w_600 letter_sp">
            Data-Driven Decisions: Unleash the Power of Operational Analytics
          </div>
          <div className="op_6_content fs_18_h_25_left_blk ">
            Elevate your operational strategy with Bearish OS’s advanced
            analytics capabilities. Dive into comprehensive data analyses that
            illuminate efficiencies, highlight areas for improvement, and
            predict future operational needs. By leveraging real-time data and
            historical trends, Bearish OS empowers your team to make informed,
            strategic decisions that drive productivity and growth.
            Transform raw data into actionable insights, ensuring your
            operations are optimized for success at every turn
          </div>
          <div className="mark_6_cont op_6_cont ">
            <div className=" fs_18_h_25_left_blk  ">
              <span className="w_600">Customizable Analytics Dashboards: </span>
              Tailor your analytics view with customizable dashboards that focus
              on key operational metrics, offering immediate insight into
              performance and areas for action
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Trend Analysis and Forecasting: </span>
              Employ advanced trend analysis to understand historical
              operational patterns and use predictive modeling to forecast
              future needs, ensuring your operations are always ahead of
              the curve
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">
                Efficiency and Productivity Insights:{" "}
              </span>
              Identify operational bottlenecks and uncover opportunities for
              efficiency gains with detailed productivity reports, enabling
              targeted improvements that boost overall performance
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec op_7_bg">
        <div className=" op_8_heding mrk_7_heding sale_7_heading w_600 letter_sp">
          Operational Excellence Achieved: Bearish OS Elevates Performance and
          Profitability
        </div>
        <div className="rating_card_main sale_rating_crd">
          {OperationRatingData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={hrimg5012} />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="it_7_heading op_8_heading heading_30_41_left_blk w_600 letter_sp">
            Smart Operations: Leverage AI for Strategic Insights
          </div>
          <div className="op_8_content  fs_18_h_25_left_blk mrk_8_content_he">
            Unlock the next level of operational excellence with Bearish OS’s
            AI-driven insights. Our advanced artificial intelligence goes beyond
            traditional data analysis, employing machine learning algorithms to
            sift through vast amounts of operational data, identifying patterns,
            and predicting future trends with remarkable accuracy. This not just
            optimizes your current processes but also strategically prepares
            your operations for future challenges and opportunities.
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
              Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};
export default Operations;
