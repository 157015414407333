import React, { useEffect, useState } from "react";
import "./relationManager.css";
import FirstSectionOfRelationManager from "../../components/relationManager/firstSection";
import SecondSectionOfRelationManager from "../../components/relationManager/secondSection";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import RelationCardData from "../../components/relationManager/relationCardData";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import RelationRatingCardData from "../../components/relationManager/relationRatingCardData";
import RatingSection from "../../components/virtualMeetingHub/RatingSection";
import FourthSectionOfRelationManager from "../../components/relationManager/fourthSection";
import RelationManagerToolsData from "../../components/relationManager/toolsCardData";
import relaE41 from "../../assets/Bearishrelationshipmanagerimg/3f67986e2e4fe0d6d3cfce1102089a41@2x.png";
const RelationManager = () => {
  const [rtCards, setRtCards] = useState("rt");
  const [rtNmCards, setRtNmCards] = useState("nmrt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="rel_manager">
        <FirstSectionOfRelationManager />
      </div>
      <SecondSectionOfRelationManager />
      <div className="fr_heading w_600">
        Manage relationships with Ease, Driven by our Open Source AI
      </div>
      <div className="crd_relation">
        {RelationCardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>
      <div className="rt_rating_card">
        <div className="rt_rating_heading w_600">
          Enterprises and valuable small businesses alike find success with
          Bearish <br /> – saving time, cutting costs, and boosting sales.
        </div>
        <div className="rating_card_main rt_crd_main_m">
          {RelationRatingCardData?.map((data, i) => (
            <RatingSection data={data} i={i} />
          ))}
        </div>
      </div>
      <FourthSectionOfRelationManager />
      <div className="bold_content postion_rela">
        <img className="bld_up_content" src={relaE41} />
        <div className="postion_abs bld_up_content_bd">
          An interconnected Workflow — Create, Edit, Build, Market, Sell, &
          Deliver all with Bearish. With interconnected work tools you <br/>get
          everything you need to build the best relationships<br/> without having to
          leave Bearish OS
        </div>
        {/* <div className="rt_fifth_content w_600">
          An interconnected Workflow — Create, Edit, Build, Market, Sell, & <br/>
          Deliver all with Bearish. With interconnected work tools <br/> you get
          everything you need to build the best relationships <br/> without having to
          leave Bearish OS
        </div> */}
      </div>
      <div className="rt_cd_heading w_600">
        Get it all done with Bearish’s Relationship Manager & Tools
      </div>
      <div className="vt_par_crd_div rt_crd_detail">
        {RelationManagerToolsData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtNmCards} />
        ))}
      </div>
      <div className="lt_headding">
        All the extras, Nothing Extra. One Platform. One Simple Price.
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default RelationManager;
