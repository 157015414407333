const Card = ({ data, i }) => {
  return (
    <div
      key={i}
      className="card"
      style={{
        backgroundImage: `url(${data?.imgUrl})`,
        backgroundSize: "100% 100%",
      }}
    >
      <div
        className={
          data?.header === "AI-Driven Workday"
            ? "w_ite"
            : data?.header === "Privacy at the Forefront"
            ? "card_header tp_h"
            : "card_header"
        }
      >
        {data?.header}
      </div>
      <div
        className={
          data?.header === "AI-Driven Workday"
            ? "fourthcard_footer"
            : "card_footer"
        }
        style={{
          backgroundImage: `url('${data?.btImg}')`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="footer_c">
          <div
            className={
              data?.header === "Maximize Savings, Minimize Spend"
                ? "hm_footer"
                : data?.header === "Privacy at the Forefront"
                ? "hm_2_footer"
                :data?.header === "AI-Driven Workday"
                ? "hm_4_footer"
                : ""
            }
          >
            {data?.content}
          </div>
        </div>
        <div
          className={`footer_b ${
            data?.header === "AI-Driven Workday" ? "footer_b_w_b" : ""
          }`}
        >
          <button
            className={`bt_btn ${
              data?.header === "AI-Driven Workday" ? "bt_w_e" : ""
            }`}
          >
            {data?.btnContent}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Card;
