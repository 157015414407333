import line534 from "../../assets/BearishReplacesave/Line 534@2x.png";
import line531 from "../../assets/BearishReplacesave/Line 531@2x.png";
import line532 from "../../assets/BearishReplacesave/Line 532@2x.png";
import line533 from "../../assets/BearishReplacesave/Line 533@2x.png";
import back4955 from "../../assets/virtualmeetingicon/Group 4955@2x.png";
import back4959 from "../../assets/virtualmeetingicon/Group 4959@2x.png";
import back4960 from "../../assets/virtualmeetingicon/Group 4960@2x.png";
import back4961 from "../../assets/virtualmeetingicon/Group 4961@2x.png";
const ReplaceRatingCardData = [
  {
    percent: "$3000",
    linecolor: line534,
    content: "The average amount you could save per user a month",
    backgroundimg: back4955,
  },
  {
    percent: "68%",
    linecolor: line531,
    content: "Reported an increase in overall operations productivity",
    backgroundimg: back4959,
  },
  {
    percent: "60%",
    linecolor: line532,
    content: "Reported a reduction in SaaS expenditures",
    backgroundimg: back4960,
  },
  {
    percent: "44%",
    linecolor: line533,
    content: "Reported an increase in their Customer Satisfaction rate",
    backgroundimg: back4961,
  },
];
export default ReplaceRatingCardData;
