import firstImg1 from "../../assets/e5b4f2e89d206a92dff040b93e8a8f23@2x.png";
import secondImg2 from "../../assets/096df722d84a4e7594aeab6b3e7ab6f1@2x.png";
import thirdImg3 from "../../assets/a70534a7e8c39d52b16c51e9d00de25f@2x.png";

const ThirdPriceNewData = [
  {
    backGround_color: "#249170",
    border_d: "3px solid #249170",
    top_heading: "Knowledge Hub",
    img_image: firstImg1,
    top_content:
      "Get the answers you need, and learn all about Bearish OS 24/7/365 with our Knowledge Hub.",
    bottom_data: "Go Now",
  },
  {
    backGround_color: "#EBA6B3",
    border_d: "3px solid #EBA6B3",
    top_heading: "Submit a Ticket",
    img_image: secondImg2,
    top_content:
      "Can’t find what your looking for in Knowledge Hub or Community Support? Submit a Ticket and our support team will get back to you",
    bottom_data: "Submit a Ticket",
  },
  {
    backGround_color: "#A6B3EB",
    border_d: "3px solid #A6B3EB",
    top_heading: "Community Support",
    img_image: thirdImg3,
    top_content:
      "Chat with users, developers, and team members and get the answers you need when you need them.",
    bottom_data: "Go Now",
  },
];

export default ThirdPriceNewData;
