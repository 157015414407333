import "./pricing.css";
import img941 from "../../assets/BearishOSIT/60379004e8674ea64c52995623c9f941@2x.png";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import imgcrosssign from "../../assets/Path 5893@2x.png";

import ico5030 from "../../assets/Group 5030@2x.png";
import Pricing1CardData from "../../components/pricingData/pricing1stCardData";
import Pricing2ndCardData from "../../components/pricingData/pricing2ndCrdData";
import Pricing3rdCardData from "../../components/pricingData/pricing3rdData";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Pricing1Card from "../../components/pricingData/newPricingCard";
import SecondPriceData from "../../components/pricingData/secondPriceData";
import ThirdPriceNewData from "../../components/pricingData/thirdPricingNewData";
import { AdminSecuritySupport, CalendarPlan, CloudStorageAnalytics, DashBoardList, IntelligentEmailClient, ProjectsPlanExecute, RelationshipManager, StreamMessaging, Trax, VideoCenter } from "../../components/pricingData/pricingNewListData";
const Pricing = () => {
  const theadData = [
    "Dashboard",
    "Bearish Cub 365",
    "Bearish OS 365",
    "Relationship 365",
    "Bearish HR 365",
    "Communicate 365",
  ];


  useEffect(() => {
    window.scrollTo(0,  0);
  }, []);
  return (
    <div className="container">
      <div className="new_pricing_page_main">
        <div className="new_pricing_">Choose the plan that works for you…</div>
      </div>
      <div className="best_val">Best Value</div>
      <div className="new_pricing_crd">
        {Pricing1Card?.map((data, i) => (
          <div className="pricing_card" key={i}>
            <div
              className="best_heading"
              style={{
                background: data?.backGround_color,
                border: data?.border_d,
              }}
            >
              {data?.top_heading}
            </div>
            <div className="best_pric_content">{data?.top_content}</div>
            <div className="best_pric_">{data?.price_a}</div>
            <div className="best_pric_per_m">Per User Per Month</div>
            {data?.new_contents?.map((ele, i) => (
              <div className="best_pric_per_">
                <div className="best_pricing_sig">
                  <img className="ico_pr" alt="ico" src={ico5030} />
                </div>
                <div className="font_pricing_c">{ele}</div>
              </div>
            ))}

            <div className="best_pr_btn">Start FREE Now</div>
          </div>
        ))}
      </div>

      <div className="p_r_sec">
        <div className="p_r_sec_head">
          <div className="p_r_sec_cont">Save big with Bearish Replacements</div>
        </div>
        <div className="p_r_cont_sec">
          Replace more than <span className="p_r_spn">$3000</span>{" "}
          <span className="w_600">a user a year </span>worth of software with
          Bearish OS’s powerful and feature packed system
        </div>
      </div>
      <div className="light_pri">
        Light Features or Enrich existing plans w/ Add Ons
      </div>
      <div className="light_pricing_crd">
        {SecondPriceData?.map((content, i) => (
          <div className="pricing_lig_data" key={i}>
            <div
              className="pricing_sec_card_top"
              style={{
                background: content?.backGround_color,
                border: content?.border_d,
              }}
            >
              {content?.top_heading}
            </div>
            <div className="werty_price">{content?.top_content}</div>
            <div className="werty_price_num">{content?.price_a}</div>
            <div className="per_per_pri">Per User Per Month</div>
            {content?.new_contents?.map((element, i) => (
              <div className="best_pric_per_sign" key={i}>
                <div className="best_pricing_sig">
                  <img className="ico_pr" alt="ico" src={ico5030} />
                </div>
                <div className="font_pricing_c">{element}</div>
              </div>
            ))}
            <div className="add_btn_prc cursor_p">Add On</div>
            <div className="sm_prc_content">{content?.bottom_data}</div>
          </div>
        ))}
      </div>
      <div className="light_pri new_bk_color_gr">
        24/7 365 Support for Every Plan
      </div>

      <div className="light_pricing_crd mb_25px_crd">
        {ThirdPriceNewData?.map((content, i) => (
          <div className="pricing_lig_data" key={i}>
            <div
              className="pricing_sec_card_top"
              style={{
                background: content?.backGround_color,
                border: content?.border_d,
              }}
            >
              {content?.top_heading}
            </div>
            <div className="werty_price">{content?.top_content}</div>
            <div
              className={`new_img_pricing ${
                content?.top_heading === "Submit a Ticket" ? "mt_32px_top" : ""
              }`}
            >
              <img alt="img" className="fo_img" src={content?.img_image} />
            </div>

            <div className="add_btn_prc cursor_p">{content?.bottom_data}</div>
          </div>
        ))}
      </div>
      <div className="light_pri mb_50px_crd">Complete Feature List</div>
      {/* 
      <div className="new_list_sec">
        <div className="thead">
          <div className="col">Dashboard</div>
          <div className="col">Bearish Cub 365</div>
          <div className="col">Bearish OS 365</div>
          <div className="col">Relationship 365</div>
          <div className="col">Bearish HR 365</div>
          <div className="col">Communicate 365</div>
        </div>
        <div className="row">
          <div className="col1">Customizable Dashboard</div>
          <div className="col1">Standard</div>
          <div className="col1">
            {" "}
            <div className="">
              <img className="new_sign_ic" alt="ico" src={ico5030} />
            </div>
          </div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
        </div>
        <div className="row">
          <div className="col1">Streamlined Notifications</div>
          <div className="col1">Standard</div>
          <div className="col1">
            {" "}
            <div className="">
              <img className="new_sign_ic" alt="ico" src={ico5030} />
            </div>
          </div>
          <div className="col1">Relationship 365</div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
        </div>
        <div className="row">
          <div className="col1">Snooze, Manage & Reply to Tasks</div>
          <div className="col1">Standard</div>
          <div className="col1">
            {" "}
            <div className="">
              <img className="new_sign_ic" alt="ico" src={ico5030} />
            </div>
          </div>
          <div className="col1">Relationship 365</div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
          <div className="col1">
            <img className="new_sign_ic" alt="ico" src={ico5030} />
          </div>
        </div>
      </div> */}

      <div className="new_list_sec">
        <div className="thead">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {item}
            </div>
          ))}
        </div>
        {DashBoardList.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_sec_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Video Center": item}
            </div>
          ))}
        </div>
        {VideoCenter.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_third_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Intelligent Email Client": item}
            </div>
          ))}
        </div>
        {IntelligentEmailClient.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_4th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Projects; Plan & Execute": item}
            </div>
          ))}
        </div>
        {ProjectsPlanExecute.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_5th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Cloud Storage & Analytics": item}
            </div>
          ))}
        </div>
        {CloudStorageAnalytics.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_6th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Calendar: Plan": item}
            </div>
          ))}
        </div>
        {CalendarPlan.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>



      <div className="new_list_sec ">
        <div className="thead thead_7th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Stream Messaging": item}
            </div>
          ))}
        </div>
        {StreamMessaging.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>


      <div className="new_list_sec ">
        <div className="thead thead_8th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Relationship Manager": item}
            </div>
          ))}
        </div>
        {RelationshipManager.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="new_list_sec ">
        <div className="thead thead_9th_color">
          {theadData.map((item, index) => (
            <div className="col" key={index}>
              {index === 0 ? "Trax": item}
            </div>
          ))}
        </div>
        {Trax.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="new_list_sec ">
        <div className="thead thead_10th_color">
          {theadData.map((item, index) => (
            <div className="col new_font_size" key={index}>
              {index === 0 ? "Admin, Security & Support": item}
            </div>
          ))}
        </div>
        {AdminSecuritySupport.map((data, index) => (
          <div className="row" key={index}>
            <div className="col1">{data.title}</div>
            {data.items.map((item, idx) => (
              <div className="col1" key={idx}>
                {item.type === "text" ? (
                  item.value
                ) : (
                  <img className="new_sign_ic" alt="ico" src={item.value} />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* <div className="p_r_2_cont">An all-in-one Work OS</div>
      <div className="position_">
        <div className="p_r_2_head">
          How much moola will you save with Bearish OS?
        </div>
        <div className="p_r_3_head">
          Bearish: Revolutionizing productivity with a visionary all-in-one
          solution, minimizing burnout and maximizing efficiency.
        </div>
      </div> */}

      {/* <div className="p_r_sec_4_div">
        <div className="p_r_sec_4_ mt_top_35">
          <div className="p_s_4_crd ">
            <div className="p_r_5_content">Polar Solo</div>
          </div>
          <div className="p_r_in_div">
            <div className="p_r_1_n">
              59.00 <img alt="icon" className=" p_r_2_s_" src={imgcrosssign} />{" "}
            </div>
            <div className="p_r_2_n">21.99</div>
          </div>
          <div className="p_r_4">per user, per month</div>
          <div className="p_r_5">
            Replaces more than $3000 worth of software costs per year
          </div>
          <div className="p_r_ic_content">
            {Pricing1CardData?.map((content, i) => (
              <div className="p_r_ic_con" key={i}>
                <div className="p_r_ico_">
                  <img className="ico_pr" alt="ico" src={ico5030} />
                </div>
                <div className="p_r_ic_cntent">{content}</div>
              </div>
            ))}
          </div>
          <div className="p_r_se">See detailed feature list</div>
        </div>
        <div>
          <div className="p_r_tp">Most Popular</div>
          <div className="p_r_sec_4_ ">
            <div className="p_s_4_crd p_r_2_crd">
              <div className="p_r_5_content color_b">Grizzly Pro</div>
            </div>

            <div className="p_r_in_div p_r_2_crd_content">
              <div className="p_r_1_n">
                99.00{" "}
                <img alt="icon" className=" p_r_2_s_" src={imgcrosssign} />{" "}
              </div>
              <div className="p_r_2_n">32.99</div>
            </div>
            <div className="p_r_4 mt_bt_29">per user, per month</div>
            <div className="p_r_5 mt_bt_48">
              Replaces more than $4000 worth of software and administrative
              costs
            </div>
            <div className="p_r_ic_content">
              {Pricing2ndCardData?.map((content, i) => (
                <div className="p_r_ic_con" key={i}>
                  <div className="p_r_ico_">
                    <img className="ico_pr" alt="ico" src={ico5030} />
                  </div>
                  <div className="p_r_ic_cntent">{content}</div>
                </div>
              ))}
            </div>
            <div className="p_r_se mt_pr_se">See detailed feature list</div>
          </div>
        </div>
        <div>
          <div className="p_r_sec_4_ p_r_ht_625 mt_top_35 ">
            <div className="p_s_4_crd p_r_3_crd">
              <div className="p_r_5_content">Bear Packs</div>
            </div>
            <div className="p_r_in_div p_r_2_crd_content">
              <div className="p_r_1_n width_84">
                109.00{" "}
                <img
                  alt="icon"
                  className=" p_r_2_s_ left_68"
                  src={imgcrosssign}
                />{" "}
              </div>
              <div className="p_r_2_n">29.00</div>
            </div>
            <div className="p_r_4 mt_bt_29 m_pr_">per user, per month</div>
            <div className="m_b_minimum">minimum 3 users</div>
            <div className="p_r_5 mt_bt_48">
              Replaces more than $4000 worth of software and administrative
              costs
            </div>
            <div className="p_r_ic_content">
              {Pricing3rdCardData?.map((content, i) => (
                <div className="p_r_ic_con" key={i}>
                  <div className="p_r_ico_">
                    <img className="ico_pr" alt="ico" src={ico5030} />
                  </div>
                  <div className="p_r_ic_cntent">{content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="p_r_it">
        While in Open Beta some features may be limited, while in open beta we
        offer a free 14 day trial of Bearish OS and a 30 days money back
        guarantee
      </div> */}
      {/* <WorkSimpler /> */}
    </div>
  );
};

export default Pricing;
