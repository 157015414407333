import grp4945 from "../../assets/virtualmeetingicon/Group 4945.png";
import grp4946 from "../../assets/virtualmeetingicon/Group 4946@2x.png";
import grp4947 from "../../assets/virtualmeetingicon/Group 4947@2x.png";
import grp4948 from "../../assets/virtualmeetingicon/Group 4948@2x.png";
import grp4949 from "../../assets/virtualmeetingicon/Group 4949@2x.png";
import grp4950 from "../../assets/virtualmeetingicon/Group 4950@2x.png";
import Img4_18 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.18.30 PM.png";
import Img_4_17 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.17.37 PM@2x.png";
import Img_4_19 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.19.42 PM@2x.png";
import Img_4_21 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.21.13 PM@2x.png";
import Img_4_21_53 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.21.53 PM@2x.png";
import Img_4_23 from "../../assets/virtualmeetingicon/Screenshot 2024-01-20 at 4.23.27 PM@2x.png";

const VertualCardData = [
  {
    backgroundImg:grp4945 ,
    frontImg: Img4_18,
    heading: "Crystal Clear Video and Audio",
    content:
      "No more “blurry faces,” or “come agains,” with next level video and audio quality built in",
  },
  {
    backgroundImg:grp4946,
    frontImg: Img_4_17,
    heading: "Built in Agendas & Workspace",
    content:
      "Don’t just talk about getting things done, create, edit, manage, stay on task and finish your work all while in your call",
  },
  {
    backgroundImg: grp4947,
    frontImg: Img_4_19,
    heading: "Collaborate with Ease",
    content:
      "Share your screen, message your team, request an update, approve a task, and take notes all Mid-call",
  },
  {
    backgroundImg: grp4948,
    frontImg: Img_4_21,
    heading: "Auto Updating CRM",
    content:
      "Impress every guest with your CRM built in so you always know the details of past conversations and close more deals",
  },
  {
    backgroundImg:grp4949,
    frontImg: Img_4_21_53,
    heading: "Schedule Links Galore",
    content:
      "Interconnected to your calendar means cutting the back and forth for meeting times and AI that schedules your calls",
  },
  {
    backgroundImg: grp4950,
    frontImg: Img_4_23,
    heading: "So Much More…",
    content:
      "With built in polls, backgrounds, intro cards and more you’ll always look professional without having to break the bank",
  },
];

export default VertualCardData;