import line531 from "../../assets/BearishOSSales/Line 531@2x.png";
import line532 from "../../assets/BearishOSSales/Line 532@2x.png";
import line533 from "../../assets/BearishOSSales/Line 533@2x.png";
import line535 from "../../assets/BearishOSSales/Line 535@2x.png";

import back4993 from "../../assets/BearishOSSales/Group 4993@2x.png";
import back4994 from "../../assets/BearishOSSales/Group 4994@2x.png";
import back4995 from "../../assets/BearishOSSales/Group 4995@2x.png";
import back4996 from "../../assets/BearishOSSales/Group 4996@2x.png";

const MarketingRatingCardData = [
  {
    percent: "58%",
    linecolor: line535,
    content: "Reported an increase in (users) Customers Engagement",
    backgroundimg: back4993,
  },
  {
    percent: "49%",
    linecolor: line531,
    content: "Reported an increase in email open rates",
    backgroundimg: back4994,
  },
  {
    percent: "41%",
    linecolor: line532,
    content: "Reported an increase in Social Media interactions",
    backgroundimg: back4995,
  },
  {
    percent: "37%",
    linecolor: line533,
    content: "Reported a reduction in their Marketing Costs",
    backgroundimg: back4996,
  },
];
export default MarketingRatingCardData;
