import DALLE from "../../assets/DALL·E 2023-12-26 17.46.58 - A greyscale, abstract, and wordless logo for a business, marketing, development, and UI_UX firm named Qirmizi &Co. The de.png";
import sliderImg from "../../assets/Screenshot 2023-12-27 at 4.02.26 PM.png";
import firstImage from "../../assets/Bearish OS slidericon/19da475a297ba2e066f74d279b651997@2x.png"
import secondImage from "../../assets/Bearish OS slidericon/8e5ce0ddd926b31521ce3c8fb507486b@2x.png"
import thirdImage from "../../assets/Bearish OS slidericon/DALL·E 2023-12-26 17.46.58 - A greyscale, abstract, and wordless logo for a business, marketing, development, and UI_UX firm named Qirmizi &Co. The de@2x.png"
import fourthImage from "../../assets/Bearish OS slidericon/Screenshot 2023-12-26 at 5.59.55 PM@2x.png"
import fifthImage from "../../assets/Bearish OS slidericon/Screenshot 2023-12-27 at 4.02.26 PM@2x.png"
import sixthImage from "../../assets/Bearish OS slidericon/a11abb092bd94c7160e59d8330a02eb4@2x.png";
import seventhImage from "../../assets/Bearish OS slidericon/fe35dec125526bda93f42bdbb6b61b76@2x.png";

const ImageData = [
  { imageData: sixthImage },
  { imageData: seventhImage },
  { imageData: fourthImage },

  { imageData: firstImage },
  { imageData: thirdImage },
  { imageData: secondImage },
  { imageData: fifthImage },
  
];

export default ImageData;
