
const RatingSection = ({ data, i }) => {
  return (
    <div
      className="rating_crd"
      key={i}
      style={{ backgroundImage: `url('${data?.backgroundimg}')` }}
    >
      <div className="rating_line">
        <img className="h_100" src={data?.linecolor} alt="ic" />
      </div>
      <div>
        <div className="rat_percent">{data?.percent}</div>
        <div className="rt_text">{data?.content}</div>
      </div>
    </div>
  );
};
export default RatingSection;
