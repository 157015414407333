import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const ProductMangementCardData = [
  {
    heading: "Intelligent Search Functionality",
    content:
      "Quickly locate files, conversations, and tasks with our AI-powered search",
    background: img4947,
  },
  {
    heading: "Automated Summaries",
    content:
      "Get concise summaries of past meetings, discussions, and task progress, enabling easy catch-up and review",
    background: img4969,
  },
  {
    heading: "Collaborative Knowledge Base",
    content:
      "Build a centralized repository of project knowledge that grows with your team’s contributions",
    background: img4970,
  },
  {
    heading: "Task and Progress Tracking",
    content:
      "Easily revisit past tasks and their outcomes to inform current project strategies and decisions",
    background: img5000,
  },
  {
    heading: "Contextual Reminders",
    content:
      "Stay ahead of deadlines and milestones with AI-driven reminders that contextually link back to relevant project tasks",
    background: img5002,
  },
  {
    heading: "Decision Tracking and Rationale",
    content:
      "Document and track the reasoning behind key project decisions, allowing for transparent review and understanding",
    background: img5001,
  },
];
export default ProductMangementCardData;