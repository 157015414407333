import img4947 from "../../assets/BearishOSSales/Group 4947@2x.png";
import img4969 from "../../assets/BearishOSSales/Group 4969.png";
import img4970 from "../../assets/BearishOSSales/Group 4970@2x.png";
import img5000 from "../../assets/BearishOSSales/Group new5000@2x.png";
import img5002 from "../../assets/BearishOSSales/Group 5002@2x.png";
import img5001 from "../../assets/BearishOSSales/Group 5001@2x.png";
const MarketingCardData = [
  {
    heading: "Touchpoint Identification",
    content:
      "Identify key customer interactions across multiple channels to tailor more impactful marketing initiatives",
    background: img4947,
  },
  {
    heading: "Engagement Opportunities",
    content:
      "Spot critical moments to engage with content, driving deeper connections and guiding customers along the purchasing path",
    background: img4969,
  },
  {
    heading: "Conversion Optimization",
    content:
      "Adjust and refine each stage of the journey using feedback and analytics to enhance conversion rates and foster brand loyalty",
    background: img4970,
  },
  {
    heading: "Personalization at Scale",
    content:
      "Craft emails that speak directly to the recipient, with dynamic content tailored to individual behaviors and preferences",
    background: img5000,
  },
  {
    heading: "A/B Testing for Success",
    content:
      "Continuously improve your email campaigns with A/B testing to determine the most effective subject lines, content, and calls to action",
    background: img5002,
  },
  {
    heading: "Analytics and Insights",
    content:
      "Dive into comprehensive analytics to understand how your emails perform, refining strategies to up engagement & conversions",
    background: img5001,
  },
];
export default MarketingCardData;