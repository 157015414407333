import "./it.css";
import img4_13 from "../../assets/BearishOSIT/Screenshot 2024-02-02 at 4.13.03 PM@2x.png";
import itimg4886 from "../../assets/BearishOSIT/Group 4886@2x.png";
import itimg4975 from "../../assets/BearishOSIT/Group 4975@2x.png";
import itimg4975_1 from "../../assets/BearishOSIT/Group 4975-1@2x.png";
import itimg5014 from "../../assets/BearishOSIT/Group 5014@2x.png";
import itimg5016 from "../../assets/BearishOSIT/Group 5016@2x.png";
import itimg5018 from "../../assets/BearishOSIT/Group 5018@2x.png";
import img5012 from "../../assets/BearishOSCustomerSuccess/Group 5012@2x.png";
import itimg5020 from "../../assets/BearishOSIT/Group 5020@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import ITCardData from "../../components/IT/itCrdData";
import RatingCardSection from "../../components/common/ratingCardSection";
import ITRatingData from "../../components/IT/itRatingData";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const IT = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main it_main">
        <div className="sale_1_sec it_new_w">
          <div className="it_heading top_heading w_600 ">
            Streamline Development, Power Innovation: Bearish OS for IT and
            Developers{" "}
          </div>
          <div className="top_content pr_mn_content it_content">
            Bearish OS redefines the workspace for IT professionals and
            developers, offering a suite of tools designed to streamline
            development processes, enhance collaboration, and foster innovation.
            From seamless project management to sophisticated version control
            and real-time communication, our platform ensures that technical
            teams can work more efficiently and effectively. Leverage Bearish
            OS’s integrated development environment (IDE), direct access to APIs
            for customization, and robust security features to build, test, and
            deploy with confidence. With Bearish OS, empower your IT and
            development teams to achieve more, faster
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="cs_1_img">
          <img className="fo_img" alt="img" src={img4_13} />
        </div>{" "}
      </div>

      <div className="sale_2_sec pr_mn_1_mrgin_bt cs_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={itimg4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="cs_2_sec_heading letter_sp heading_30_41_left_blk w_600">
            Agile Project Management: Accelerate Development with Precision{" "}
          </div>
          <div className="it_1_content fs_18_h_25_left_blk letter_sp">
            Adopt agile project management tailored for development teams with
            Bearish OS. Coordinate sprints, track progress, and adjust in
            real-time to keep your projects agile, efficient, and on track
          </div>
          <div className="sal_2_sec">
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={itimg5014} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont letter_sp">
                <span className="w_600">Sprint Planning Tools: </span>{" "}
                Streamline your sprint planning with customizable boards and
                timelines
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={itimg4975_1} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont  letter_sp">
                <span className="w_600">Real-Time Progress Tracking: </span>
                Monitor development progress with up-to-date dashboards and
                reporting
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={itimg4975} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk mark_2_cont letter_sp ">
                <span className="w_600">Collaborative Review Processes: </span>
                Facilitate code reviews and feedback within your team to
                maintain high-quality standards
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec pr_mn_gap it_2_gap">
        <div className="pas_5th_sec_cont new_sim_dat">
          <div className=" heading_30_41_left_blk w_600 letter_sp it_2_heADING">
            Enhance Teamwork: Real-Time Communication for Developers
          </div>
          <div className="it_2_content fs_18_h_25_left_blk letter_sp">
            Foster a culture of collaboration and transparency within your
            development team with Bearish OS’s real-time communication tools.
            Discuss, decide, and develop together, no matter where your team
            members are located
          </div>
          <div className="it_2_content_w_612 fs_18_h_25_left_blk letter_sp">
            <span className="w_600">Instant Messaging and Chat Rooms: </span>
            Communicate instantly with individual team members or groups
          </div>
          <div className="it_2_content_w_612 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Video Conferencing for Remote Teams: </span>
            Hold remote meetings, pair programming sessions, or code
            reviews
          </div>
          <div className="it_2_content_w_612 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Integrated Task and Issue Tracking: </span>
            Assign and track tasks or issues directly within your communication
            channels
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Get to know our Communication Hubs
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pr_mn_3_img">
          <img alt="img" className="fo_img" src={itimg5020} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={itimg5016} />
        </div>
        <div className="sal_2_sec pr_new_w">
          <div className="it_3_heading letter_sp heading_30_41_left_blk w_600">
            Data at Your Fingertips: Secure Storage and Insightful Analytics
          </div>
          <div className="it_3_content fs_18_h_25_left_blk letter_sp">
            Store, manage, and analyze your development data with Bearish OS’s
            secure cloud storage and comprehensive analytics. Gain insights into
            your work, optimize your strategies, and secure your data all in one
            place
          </div>
          <div className="pr_mn_4_cont sal_2_sec it_3_cont ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Robust Data Storage: </span>
              Keep your files and project data secure and accessible with
              encrypted cloud storage, and ease of access to all your coding
              documentation and knowledge basis
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Development Analytics: </span>
              Track and analyze your development processes to continuously
              improve efficiency, and understand what is working and delivering
              and what isn’t
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Customizable Dashboards: </span>Create
              personalized dashboards to monitor the metrics that matter most to
              your projects, and understand why development is on time or not
            </div>
          </div>
        </div>
      </div>

      <div className="letter_sp heading_30_41_left_blk w_600 it_4_heading">
        Next-Gen Coding Environment: The Bearish OS IDE Experience and Evolution
      </div>

      <div className="sale_5_sec pr_mn_marg_bt">
        {ITCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec cs_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={itimg5018} className="fo_img" />
        </div>
        <div className="pas_5th_sec_cont pr_mn_7_content_new">
          <div className="it_5_heading heading_30_41_left_blk w_600 letter_sp">
            Collaborate Without Limits: Virtual Calls and Integrated Workspace
          </div>
          <div className="it_5_content fs_18_h_25_left_blk letter_sp">
            Transform how your team collaborates with Bearish OS’s virtual
            collaboration tools. Engage in high-quality video calls, share ideas
            on digital whiteboards, and work together in real-time, regardless
            of location
          </div>
          <div className=" it_5_content_w">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">High-Quality Video Conferencing: </span>
              Connect with your team through seamless video calls integrated
              directly into your workflow, and ensure that everyone has
              the same information to build with and on
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Interactive Whiteboards: </span>
              Brainstorm and visualize ideas with interactive digital
              whiteboards, and move together as a team
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Shared Workspaces: </span>
              Collaborate on documents and projects in shared  workspaces,
              ensuring everyone is aligned and up-to-date, and always
               be on the right side with automatic updates, real time
              changes, and a powerful unified search
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec it_6_crd_back">
        <div className="mrk_7_heding sale_7_heading w_600 letter_sp">
          Proven Outcomes: Elevating Customer Success with Every Interaction
          through Bearish OS
        </div>
        <div className="rating_card_main sale_rating_crd">
          {ITRatingData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={img5012} />
        </div>
        <div className="pas_5th_sec_cont it_new_wid">
          <div className="it_7_heading heading_30_41_left_blk w_600 letter_sp">
            AI-Enhanced Development: Crafting the Future with Intelligent
            Insights
          </div>
          <div className="it_7_content  fs_18_h_25_left_blk mrk_8_content_he">
            Embrace the cutting edge of development with Bearish OS’s AI
            capabilities. Our platform leverages artificial intelligence to
            revolutionize the way IT professionals and developers work, from
            automated code optimization to predictive project analytics. Bearish
            OS’s AI tools are designed to augment your development process,
            providing smart suggestions, enhancing code quality, and forecasting
            project timelines with unparalleled accuracy.<br/> Step into a new era of
            development where AI drives innovation, efficiency,<br/> and success
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}


    </div>
  );
};
export default IT;
