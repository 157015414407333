import "./App.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import HomePage from "./pages/homepage/homePage";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import IntelligentEmail from "./pages/intelligentEmailClient/intelligentEmailClient";
import VirtualMeetingHub from "./pages/virtualMeeting/virtualMeeting";
import RelationManager from "./pages/relationManager/relationManager";
import RelationShipManager from "./pages/relationShipManager/relationShipManager";
import Simplify from "./pages/simplify/simplify";
import ReplaceAndSave from "./pages/replace_save/replaceAndSave";
import PrivacyAndSecurity from "./pages/privacyAndPolicy/privacyAndSecurity";
import BebaAI from "./pages/bebaAi/bebaAI";
import TranquilAI from "./pages/tranquilAI/tranquilAI";
import CentralizeCommunications from "./pages/centralizedCommunications/centralizedCommunication";
import Sales from "./pages/sales/sales";
import Marketing from "./pages/marketing/marketing";
import ProductManagement from "./pages/productManagement/productManagement";
import CustomerSuccess from "./pages/customerSuccess/customerSuccess";
import IT from "./pages/IT/IT";
import HR from "./pages/hr/hr";
import Operations from "./pages/operations/operations";
import Privacy from "./pages/privacy/privacy";
import TermAndService from "./pages/termAndService/termAndService";
import AboutPage from "./pages/aboutPage/about";
import ResponsibleAI from "./pages/responsibleAi/responsibleAi";
import InvestorRelations from "./pages/investorRelations/investorRelations";
import Pricing from "./pages/pricing/pricing";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import WorkSimpler from "./components/worksimpler/workSimpler";
import MicrosoftVerification from "./pages/MicrosoftVerification/MicrosoftVerification";
function App() {
  // useEffect(() => {
  //   hotjar.initialize('3864137', '6');
  // }, []);
  return (
    // <div className="container">
    <div className="">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/intelligent" element={<IntelligentEmail />} />
          <Route path="/virtual" element={<VirtualMeetingHub />} />
          <Route path="/relationmanager" element={<RelationManager />} />
          <Route path="/projectplans" element={<RelationShipManager />} />
          <Route path="/simplify" element={<Simplify />} />
          <Route path="/replace" element={<ReplaceAndSave />} />
          <Route path="/privacy" element={<PrivacyAndSecurity />} />
          <Route path="/bebaai" element={<BebaAI />} />
          <Route path="/tranquilai" element={<TranquilAI />} />
          <Route path="/communicate" element={<CentralizeCommunications />} />
          

          <Route path="/sales" element={<Sales />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/productdevelop" element={<ProductManagement />} />
          <Route path="/customersuccess" element={<CustomerSuccess />} />
          <Route path="/it" element={<IT />} />
          <Route path="/hr" element={<HR />} />
          <Route path="/operations" element={<Operations />} />
          <Route path="/bsprivacy" element={<Privacy />} />
          <Route path="/termofservice" element={<TermAndService />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/responsibleai" element={<ResponsibleAI />} />
          <Route path="/MicrosoftVerification" element={< MicrosoftVerification />} />
          <Route path="/investors" element={<InvestorRelations />} />
          <Route path="/pricing" element={<Pricing />} />
        </Routes>
        <WorkSimpler />
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
