import screenShot from "../../assets/Bearishrelationshipmanagerimg/Group 4886@2x.png";
import icon4887 from "../../assets/Bearishrelationshipmanagerimg/Group 4887@2x.png"
const SecondSectionOfRelationManager = ()=>{
    return(
        <div className="fr_hello">
        <div className="fr_content_div">
          <div className="fr_cont_header w_600">
            From “Hello” to “Sold” and everything in between do it with Bearish
          </div>
          <div className="ft_18_l_ht_25">
            With Bearish, effortlessly navigate from first contact to final
            deal. Our platform enhances every interaction, ensuring seamless
            progression and impactful results. Turn every ‘hello’ into a
            successful  ‘sold’ with ease and precision.
          </div>
          <div className="vt_wt_link w_600">
            <a className="vt_link_tag fr_lnk" href="#">
              Most loved feature below{" "}
            </a>
            <img className="con_icon" alt="icon" src={icon4887} />
          </div>
        </div>
        <img className="fr_img" alt="img" src={screenShot} />
      </div>
    )
}
export default SecondSectionOfRelationManager;