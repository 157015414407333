import { useEffect, useState } from "react";

import ToolFeaturesData from "./toolFeaturesData";
const ToolFeatures = () => {
  const [selectedData, setSelectedData] = useState();
  useEffect(() => {
    if (ToolFeaturesData && ToolFeaturesData.length > 0) {
      setSelectedData(ToolFeaturesData[0]);
    }
  }, []);
  return (
    <div className="toolbox_list_icon">
      <div className="tool_list">
        <div className="tool_combined">
          <div className="inside_tool_div">
            {ToolFeaturesData?.map((ele, i) => (
              <div
                className={`tool_margin_top ${
                  selectedData === ele ? "active_tool" :""
                }`}
                key={i}
                onClick={() => setSelectedData(ele)}
              >
                <div className="tool_icon">
                  <img className="img_w_100" src={ele?.icon} alt="" />
                </div>
                <div className="tool_name">{ele?.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="active_tool_data">
        <div className="tool_detail">
          <div className="tool_de_icon">
            {" "}
            <img className="img_w_10" src={selectedData?.icon} alt="" />
          </div>
          <div className="tool_de">{selectedData?.name}</div>
        </div>
        <div className="tool_dec">{selectedData?.description}</div>
      </div>
    </div>
  );
};

export default ToolFeatures;
