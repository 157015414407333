import icon4975 from "../../assets/BearishOSTranquilAI/Group 4975@2x.png";
import icon4982 from "../../assets/BearishOSTranquilAI/Group 4982@2x.png";

import icon4984 from "../../assets/BearishOSTranquilAI/Group 4984@2x.png";
const TranquilContentIcon = [
  {
    icon: icon4982,
    content:
      "Adapts to your emotional state, offering support and recommendations when you need them most",
    boldContent: "Responsive Mood Analysis: ",
  },
  {
    icon: icon4975,
    content:
      "Identifies stress triggers and suggests effective relaxation techniques and mindfulness exercises",
    boldContent: "Proactive Stress Relief: ",
  },
  {
    icon: icon4984,
    content:
      "Tracks your emotional trends over time, providing insights for long-term mental health and well-being",
    boldContent: "Personalized Wellness Journey: ",
  },
];
export default TranquilContentIcon;
