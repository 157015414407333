import screenShot from "../../assets/Screenshot 2023-02-23 at 11.08.12 PM@2x.png";
const EveryOneSupport = () => {
  return (
    <div className="tools_for_everyone w_600">
      <div className="new_evre_cont">
        <div className="evre_heading">
          Tools for Everyone; All Fairly Priced
        </div >
        <div className="brd_div">
          <div className="evre_sup">Support</div>
          <div className="evre_sup">Customer Success</div>
          <div className="evre_sup">HR</div>
          <div className="evre_sup">Product</div>
          <div className="evre_sup">R&D</div>
          <div className="evre_sup">Operations</div>
          <div className="evre_sup">IT</div>
          <div className="evre_sup">Sales</div>
        </div>
      </div>
      <div className="evre_img">
        <img className="img_w100_h100_2" alt="" src={screenShot} />
      </div>
    </div>
  );
};

export default EveryOneSupport;
