import grp4947 from "../../assets/Bearishrelationshipmanagerimg/Group 4947@2x.png";
import grp4970 from "../../assets/Bearishrelationshipmanagerimg/Group 4970@2x.png";
import grp4969 from "../../assets/Bearishrelationshipmanagerimg/Group 4969@2x.png";
import Img4968 from "../../assets/Bearishrelationshipmanagerimg/Group 4968@2x.png";
import Imgf59 from "../../assets/Bearishrelationshipmanagerimg/5643da13b6127a11d795e11bce0d3f59@2x.png";
import Img859 from "../../assets/Bearishrelationshipmanagerimg/fcc878441a8bbb7196419354d7154859@2x.png";
const RelationCardData = [
  {
    backgroundImg: grp4947,
    frontImg: Img4968,
    heading: "Enrich Every Contact",
    content:
      "No more unknown contacts or incomplete profile. Our AI automatically fills in as many customer fields for you",
  },
  {
    backgroundImg: grp4969,
    frontImg: Imgf59,
    heading: "Never miss a touch-point",
    content:
      "Email viewed, document sent, call hosted, no matter where you do it Bearish keeps a record of every customer touchpoint",
  },
  {
    backgroundImg: grp4970,
    frontImg: Img859,
    heading: "End Deduplicate Contacts",
    content:
      "Don’t double your workload, our AI detects duplicate contacts and merges them so you never miss a beat",
  },
];

export default RelationCardData;
