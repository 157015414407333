const SmCardSection = ({ data, i }) => {
  return (
    <div
      className="sale_5_crd"
      style={{ backgroundImage: `url('${data?.background}')` }}
      key={i}
    >
      <div className="sale_5_crd_heading">{data?.heading}</div>
      <div className="sale_5_crd_content">{data?.content}</div>
    </div>
  );
};
export default SmCardSection;
