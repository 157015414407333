import SimplifyIconContent from "./simplifyIconContent"
import img4977 from "../../assets/BearishSimplify/Group 4977@2x.png";

const SimplifyEighthSection = ()=>{
    return(<div className="sim_8th_sec">
    <div className="sm_8th_content">
      {SimplifyIconContent?.map((data, i) => (
        <div className="sm_8th_content_section" key={i}>
          <div className="con_icon">
            <img alt="ico" className="fo_img" src={data?.icon} />
          </div>
          <div className="fs_18_h_25_left_blk wi_524">
            <span className="w_600">{data?.boldContent}</span>
            {data?.content}
          </div>
        </div>
      ))}
    </div>
    <div className="sm_8th_sec_img">
      <img alt="img" className="fo_img" src={img4977} />
    </div>
  </div>)
}

export default SimplifyEighthSection;