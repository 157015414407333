import "./customerSuccess.css";
import img5_34 from "../../assets/BearishOSCustomerSuccess/Screenshot 2024-02-01 at 5.34.05 PM@2x.png";
import img4886 from "../../assets/BearishOSCustomerSuccess/Group 4886@2x.png";
import img4975_1 from "../../assets/BearishOSProductManagement/Group 4975-1@2x.png";
import img5014 from "../../assets/BearishOSProductManagement/Group 5014@2x.png";
import img4975 from "../../assets/BearishOSProductManagement/Group 4975@2x.png";
import rdIcons from "../../assets/roundIcons/Group 4931@2x.png";
import img5020 from "../../assets/BearishOSCustomerSuccess/Group 5020@2x.png";
import img5016 from "../../assets/BearishOSCustomerSuccess/Group 5016@2x.png";
import img5018 from "../../assets/BearishOSCustomerSuccess/Group 5018@2x.png";
import img5012 from "../../assets/BearishOSCustomerSuccess/Group 5012@2x.png";
import SmCardSection from "../../components/sales/smCardSection";
import CustomerSuccessCardData from "../../components/customerSuccessData/customerSucessCardData";
import RatingCardSection from "../../components/common/ratingCardSection";
import CustomerSuccessRatingData from "../../components/customerSuccessData/customerSuccessRating";
import WorkSimpler from "../../components/worksimpler/workSimpler";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const CustomerSuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="main cs_1_sec">
        <div className="sale_1_sec">
          <div className="cs_1_heding top_heading w_600 ">
            Empowering Success: Elevate Your Customer Journey with Bearish OS
          </div>
          <div className="top_content pr_mn_content cs_1_content">
            Transform every customer interaction into an opportunity for success
            with Bearish OS. Our comprehensive suite is designed to enhance
            customer experiences, foster long-term relationships, and drive
            customer loyalty. From personalized discussion tools to in-depth
            analytics and feedback mechanisms, Bearish OS provides everything
            your team needs to understand, engage, and delight your customers at
            every touchpoint. By putting customer success at the heart of your
            operations, Bearish OS helps you build a loyal customer base and
            achieve sustainable growth
          </div>
          <button className="re_btn">
          <Link
            to="https://app.bearishos.com/signup"
            className="re_btn footer_nav"
            target="_blank"
          >
            Start Free Now
          </Link>
          </button>
        </div>
        <div className="cs_1_img">
          <img className="fo_img" alt="img" src={img5_34} />
        </div>{" "}
      </div>

      <div className="sale_2_sec pr_mn_1_mrgin_bt cs_2_sec">
        <div className="sale_2_sec_img">
          <img alt="img" className="fo_img" src={img4886} />
        </div>
        <div className="sale_2_sec_cont">
          <div className="cs_2_sec_heading letter_sp heading_30_41_left_blk w_600">
            Tailor Every Touchpoint: Personalized Interactions Made Easy
          </div>
          <div className="cs_2_sec_content fs_18_h_25_left_blk letter_sp">
            Deliver highly personalized customer experiences with Bearish OS.
            Utilize our advanced segmentation and communication tools to craft
            messages that resonate personally with each customer, enhancing
            satisfaction and loyalty
          </div>
          <div className="sal_2_sec">
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975_1} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont letter_sp">
                <span className="w_600">Segmentation and Targeting: </span>{" "}
                Efficiently categorize customers for tailored communication
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img5014} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk pr_mn_1_cont letter_sp">
                <span className="w_600">Customized Messaging: </span>Use
                customer data to personalize every message and interaction
              </div>
            </div>
            <div className="rs_last_icon_">
              <div className="con_icon">
                <img alt="icon" src={img4975} className="img_w" />
              </div>
              <div className="fs_18_h_25_left_blk mark_2_cont letter_sp">
                <span className="w_600">Automated Personalization: </span>
                Implement AI-driven recommendations for individualized customer
                engagement strategies
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sale_3_sec pr_mn_gap cs_gap_50">
        <div className="pas_5th_sec_cont">
          <div className=" heading_30_41_left_blk w_600 letter_sp cs_3_heading">
            Understand to Serve: Leverage Deep Insights for Customer Success{" "}
          </div>
          <div className="cs_3_content fs_18_h_25_left_blk letter_sp">
            Gain actionable insights into customer behavior, preferences, and
            feedback with Bearish OS’s comprehensive analytics. Make informed
            decisions to continuously improve the customer journey
          </div>
          <div className="cs_3_content_w_650 fs_18_h_25_left_blk letter_sp">
            <span className="w_600">Behavioral Analytics: </span>
            Track and analyze customer interactions across all touch points no
            matter where they occur{" "}
          </div>
          <div className="cs_3_content_w_650 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600">Satisfaction Measurement: </span>Use surveys
            and feedback tools to gauge and improve customer satisfaction right
            from your CRM
          </div>
          <div className="cs_3_content_w_650 fs_18_h_25_left_blk mark_2_mr_17">
            <span className="w_600"> Churn Prediction: </span>Identify at-risk
            customers early with predictive analytics to proactively address
            concerns
          </div>
          <div className="sim_9th_ico_cont mark_2_mr_17">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Understand all our Analytics Capabilities{" "}
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
        <div className="pr_mn_3_img">
          <img alt="img" className="fo_img" src={img5020} />
        </div>
      </div>

      <div className="mark_4_sec">
        <div className="sale_4_sec_img">
          {" "}
          <img alt="img" className="fo_img" src={img5016} />
        </div>
        <div className="sal_2_sec pr_mn_7_content_new">
          <div className="cs_4_heading letter_sp heading_30_41_left_blk w_600">
            Listen, Learn, Lead: Transform Feedback into Actionable Insights{" "}
          </div>
          <div className="cs_4_content fs_18_h_25_left_blk letter_sp">
            Collect, manage, and act on customer feedback seamlessly with
            Bearish OS. Our tools facilitate easy feedback collection and
            analysis, empowering you to make customer- driven improvements
          </div>
          <div className="pr_mn_4_cont sal_2_sec cs_4_cont ">
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Integrated Feedback Tools: </span>
              Simplify feedback collection across multiple channels with our
              intelligent connections and built in tools that work to deliver
              the answers you need when you need or want them
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Real-Time Response Capabilities: </span>
              Quickly address customer feedback and resolve issues based on all
              messages and chat across every channel you communicate with them
              with. Never miss a beat with built in notification systems and
              responses
            </div>
            <div className=" fs_18_h_25_left_blk letter_sp">
              <span className="w_600">Feedback Analysis: </span>Turn feedback
              into insights to drive product and service enhancements with data
              surfacing based on cross team data and insights from project
              management to HR to Marketing and everything in between
            </div>
          </div>
        </div>
      </div>

      <div className="letter_sp heading_30_41_left_blk w_600 cs_5_sec">
        Cultivate Community: Strengthen Relationships Through Engagement
      </div>

      <div className="sale_5_sec pr_mn_marg_bt">
        {CustomerSuccessCardData?.map((data, i) => (
          <SmCardSection data={data} i={i} />
        ))}
      </div>

      <div className="mark_6_sec cs_6_sec">
        <div className="mark_6_img">
          <img alt="img" src={img5018} className="fo_img" />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="cs_7_heading heading_30_41_left_blk w_600 ">
            Streamline Success: Intelligently Connected Tools for powerful
            Customer Interaction
          </div>
          <div className="cs_7_content fs_18_h_25_left_blk ">
            With everything from secure storage for customer data to advanced
            video calling for personal interactions, our platform ensures you
            have the necessary tools at your fingertips to manage customer
            relationships effectively. Simplify scheduling, enhance
            communication, and keep all your customer-related information
            organized and accessible to deliver unparalleled customer support
          </div>
          <div className="mark_6_cont">
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Unified Communication Platform: </span>
              Leverage integrated messaging, email, and video calling to
              maintain clear and consistent communication with customers
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600"> Secure Data Storage: </span>
              Utilize Bearish OS’s secure cloud storage to keep all customer
              information safe, organized, and easily accessible for the team
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">Advanced Calendar Management: </span>
              Efficiently manage appointments, follow-ups, and team schedules
              with our comprehensive calendar tool, ensuring timely engagements
              and meetings
            </div>
            <div className=" fs_18_h_25_left_blk ">
              <span className="w_600">
                Document Sharing and Collaboration:{" "}
              </span>
              Share documents and collaborate in real-time with customers and
              team members to streamline support and project management
              processes
            </div>
          </div>
        </div>
      </div>

      <div className="sale_7_sec cs_7_color_bg">
        <div className="mrk_7_heding sale_7_heading w_600 letter_sp">
          Proven Outcomes: Elevating Customer Success with Every Interaction
          through Bearish OS
        </div>

        <div className="rating_card_main sale_rating_crd">
          {CustomerSuccessRatingData?.map((data, i) => (
            <RatingCardSection data={data} i={i} />
          ))}
        </div>
      </div>

      <div className="mrk_8_sec ">
        <div className="pas_5th_sec_img">
          <img alt="img" className="fo_img" src={img5012} />
        </div>
        <div className="pas_5th_sec_cont">
          <div className="cs_8_heading heading_30_41_left_blk w_600 letter_sp">
            AI-Powered Excellence: Revolutionize Customer Success with
            Intelligent Insights
          </div>
          <div className="cs_8_content  fs_18_h_25_left_blk mrk_8_content_he">
            Unlock the future of customer success with Bearish OS’s AI-powered
            tools. Our platform harnesses the power of machine learning and
            artificial intelligence to provide deep insights into customer
            behavior, predict future trends, and automate personalized
            engagement strategies.
          </div>
          <div className="sim_9th_ico_cont">
            <div className="lk w_600">
              <a href="#" className="sim_9th_lnk">
                Learn more about BEBA AI
              </a>
            </div>
            <div className="con_icon">
              <img alt="icon" className="fo_img" src={rdIcons} />
            </div>
          </div>
        </div>
      </div>

      {/* <WorkSimpler /> */}
    </div>
  );
};

export default CustomerSuccess;
