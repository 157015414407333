import "./relationShipManager.css";

import FirstSectionForRelaShipManager from "../../components/relationShipManager/firstSection";
import SecondSctionforRelsShipManager from "../../components/relationShipManager/secondSection";

import WorkSimpler from "../../components/worksimpler/workSimpler";
import ThirdSectionForRelaShipManager from "../../components/relationShipManager/thirdSectionForRela";
import CardSection from "../../components/virtualMeetingHub/thirdSection";
import { useEffect, useState } from "react";
import RelationShipCardData from "../../components/relationShipManager/relationShipCardData";
import FifthSectionOfRelaShip from "../../components/relationShipManager/fifthSection";
import img4967 from "../../assets/RelationshipManager1/Group 4967@2x.png";
import RelationShipData from "../../components/relationShipManager/rsLastSectionData";

const RelationShipManager = () => {
  const [rtCards, setRtCards] = useState("rt");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="rsm_main">
        <FirstSectionForRelaShipManager />
      </div>
      <SecondSctionforRelsShipManager />
      <ThirdSectionForRelaShipManager />
      <div className="coll_heading w_600 heading_30_41_left_blk">
        Collaborate Internally, Externally and Easily with All
      </div>
      <div className="crd_relation rs_mg_bottom">
        {RelationShipCardData?.map((data, i) => (
          <CardSection data={data} i={i} rt={rtCards} />
        ))}
      </div>
      <div className=" rs_sec_heading_ heading_30_41_left_blk w_600">
        Endless Storage, Endless Documents, All Summarized, Searchable, and
        Actionable
      </div>
      <FifthSectionOfRelaShip />
      <div className="heading_30_41_left_blk w_600 ad_bg_cont">
        Advance Your Work Dynamics: Excel in Project Management,
        Enhance Presentation Creation, and Master Document & Asset Safety
        – All Through One Sophisticated Solution
      </div>

      <div className="rs_last_sec">
        <div className="rs_last_icon_cont">
          {RelationShipData?.map((data, i) => (
            <div className="rs_last_icon_" key={i}>
              <div className="con_icon">
                <img className="img_w" alt="icon" src={data?.icon} />
              </div>
              <div
                className={`fs_18_h_25_left_blk ${
                  data?.boldContent === "Transparent Pricing: "
                    ? "rs_last_conte"
                    : data?.boldContent === "Privacy-First AI: "
                    ? "rs_last_conte"
                    : "rs_last_content"
                } `}
              >
                <span className={`w_600`}>{data?.boldContent}</span>
                {data?.content}
              </div>
            </div>
          ))}
        </div>
        <div className="rs_last_s_img">
          <img alt="img" className="fo_img" src={img4967} />
        </div>
      </div>
      {/* <WorkSimpler /> */}
    </div>
  );
};
export default RelationShipManager;
